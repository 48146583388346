import {useState} from "react"
import {useDispatch} from "react-redux"
import {useLocation, useNavigate, useParams} from "react-router-dom"
import {toast} from "react-toastify"
import {DEFAULTFILTERS, DEFAULT_PAGE_COUNT, TEMP_DEFAULTFILTERS} from "../../../constants/app.constants"
import useHttpHandler from "../../../hooks/httphandler.hook"
//import useHttpHandler from "../hooks/httphandler.hook"

const useVideosCommonHooks = () => {

    const [membersList, setMemberData] = useState(null)
    const {post} = useHttpHandler();
    const [checkLevel, setLevel] = useState(true);
    const [loading, setLoading] = useState(false)
    const [dropdownsData, setDropdownsData] = useState(null)
    const [tableData, setTableData] = useState([])
    const [serviceData, setCustomData] = useState(null)
    const [addServiceData, setAddServiceData] = useState(null)
    const [isSucess, setSucess] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()
    const {userId} = useParams();
    const navigate = useNavigate();


    const getCurrentMonth=()=>{
        const current = new Date();
        return current.getMonth()+1;
    }
    const getCurrentYear=()=>{
        const current = new Date();
        return current.getFullYear();
    }
   

    const getMemberList = (usertype) => {
        setLoading(true)
        let postData = {};
        postData.usertypeid = usertype;

        post('admin_get_members_ids', postData).then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                let memberOptions = [];
                data?.map((item, index) => {
                    memberOptions.push({value: item.userid, label: item.id})
                })
                setMemberData({
                    memberOptions
                })
            }
        }).catch(() => setLoading(false))
    }

    const setkeys_params = (type) => {
        Object.keys(DEFAULTFILTERS).forEach(key => {
            delete DEFAULTFILTERS[key];
        })
        let obj = Object.keys(TEMP_DEFAULTFILTERS);
        obj.forEach((element, index) => {

            DEFAULTFILTERS[element] = TEMP_DEFAULTFILTERS[obj[index]];
        });
        if (type == "userId") {
            DEFAULTFILTERS.userid = userId;
        }
    }


    const getDropDowns = async () => {
        setLoading(true)
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let studyLevelOptions = []
                let categoriesOptions = []
                let categories = []
                let subCategories = data?.subCategories;
                let subCategoriesOptions = [];
                let moduleOptions = data?.moduleLevel;
                let mettingTypes = data?.mettingtypes;

                data?.categories?.map((item, index)=>{
                    categories.push(item);
                    categoriesOptions.push({value:item.catId, label: item.catName,text: item.catName})
                })

                data?.subCategories.map((item, index)=>{
                     subCategoriesOptions.push({value:item.subcatId, label: item.subCatName,text:item.subCatName})
                    
                })

                
                setDropdownsData({
                    subCategories,
                    categoriesOptions,
                    subCategoriesOptions,
                    categories,
                    moduleOptions,
                    mettingTypes
                })
                
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }

    let addDefaultVals = (values) => {
        values.sortby = 'asc';
        values.page = 1;
        values.perpage = DEFAULT_PAGE_COUNT;
        return values
    }

    let defaultFilters = {
        sortby: 'asc',
        fromdate: '',
        todate: '',
        page: 1,
        perpage: DEFAULT_PAGE_COUNT,
    }

    const [filterKeys, setFilterKeys] = useState(DEFAULTFILTERS)

    const sortBy = (sortBy, sortType) => {
        DEFAULTFILTERS.sortby = sortBy;
        DEFAULTFILTERS.sortkey = sortType;
        getList(DEFAULTFILTERS);
    }

    const getByYear = (year) => {
        DEFAULTFILTERS.year = year;
        getList(DEFAULTFILTERS);
    }

    const nextPage = (currentPage) => {
        console.log("filerKeys", DEFAULTFILTERS);
        DEFAULTFILTERS.page = currentPage + 1;
        getList(DEFAULTFILTERS);
    }

    const previousPage = (currentPage) => {
        DEFAULTFILTERS.page = currentPage - 1;
        getList(DEFAULTFILTERS);
    }


    const setkeys = () => {
        Object.keys(DEFAULTFILTERS).forEach(key => {
            delete DEFAULTFILTERS[key];
        })
        let obj = Object.keys(TEMP_DEFAULTFILTERS);
        obj.forEach((element, index) => {

            DEFAULTFILTERS[element] = TEMP_DEFAULTFILTERS[obj[index]];
        });
    }

    const getList = (keys) => {
        setLoading(true)
        post(keys?.uri, keys).then((res) => {
            setLoading(false)
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setTableData(data)
                var obj = Object.keys(keys);
                obj.forEach((element, index) => {
                    DEFAULTFILTERS[element] = keys[obj[index]];
                });
                DEFAULTFILTERS.testkey = "test";
                console.log("filerKeys 1", DEFAULTFILTERS);
            }
        }).catch(() => setLoading(false))
    }

    const addService = async (postData) => {
        setLoading(true)
        await post(postData.uri, postData).then((res) => {
            console.log(res);
            const status = res.data.status
            const data = res.data.data
            setAddServiceData(res);
        }).catch(() => setLoading(false)
        )
    }

    const redirectTo = (path) => {
        navigate(path);
    }

    const getCustomData = (keys) => {
        setLoading(true)
        post(keys?.uri, keys).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setCustomData(data)
                setTimeout(()=>{
                    setLoading(false)
                },500)
            }
        }).catch(() => setLoading(false))
    }


    const promiseService = (postData) => {
       
        return new Promise((resolve, reject) => {
            setLoading(true)
        
             post(postData.uri, postData).then((res) => {
                console.log(res);
                const status = res.data.status
                const data = res.data.data
                setLoading(false)
                resolve(res);
             
            }).catch(() => {
                setLoading(false)
                reject();
            }
            )

        });
      };

      const promiseServiceNonLoading = (postData) => {
       
        return new Promise((resolve, reject) => {
       
        
             post(postData.uri, postData).then((res) => {
                console.log(res);
                const status = res.data.status
                const data = res.data.data
              //  setLoading(false)
                resolve(res);
             
            }).catch(() => {
               // setLoading(false)
                reject();
            }
            )

        });
      };

    return {
        addService,
        membersList,
        getMemberList,
        setMemberData,
        getDropDowns,
        dropdownsData,
        filterKeys,
        setFilterKeys,
        getList,
        loading,
        setLoading,
        sortBy,
        tableData,
        nextPage,
        previousPage,
        defaultFilters,
        setkeys,
        addDefaultVals,
        setkeys_params,
        getByYear,
        getCustomData,
        setCustomData,
        serviceData,
        redirectTo,
        setAddServiceData,
        addServiceData,
        getCurrentMonth,
        getCurrentYear,
        checkLevel,
        setLevel,
        isSucess,
        setSucess,
        promiseService,
        promiseServiceNonLoading
    }
}

export default useVideosCommonHooks;
