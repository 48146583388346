import React,{useEffect, useState} from 'react'
import { DEFAULT_FILTERS, TEMP_DEFAULT_FILTERS } from '../../../constants/app.constants';
import Loader from '../../../components/loader';
import useGuruUploadVideo from '../uploadVideo/hooks/guruUploadVideo.hook';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import LayoutGuru from '../../../components/layoutGuru';
import TotalMinutesPageView from '../chat/dashboard/views/totalMinutesPageView';
import EarningThisMonthPageView from '../chat/dashboard/views/earningThisMonthPageView';
import TotalEarningPageView from '../chat/dashboard/views/totalEarningPageView';
import MyMeetingList from './views/MyMeetingList';


const MeetingList =(props)=>{
  const guruUploadVideoHook = useGuruUploadVideo();
  const commonHook = useGetTableList();

  const [count, setCount] = useState(0);
 
    useEffect(() => {
        //Implementing the setInterval method
        const interval = setInterval(() => {
            setCount(count + 1);
            commonHook.setKeys();
            TEMP_DEFAULT_FILTERS.uri = 'getInstructorAllEvents';
            
            commonHook.getList(TEMP_DEFAULT_FILTERS)
        }, 60000);
 
        //Clearing the interval
        return () => clearInterval(interval);
    }, [count]);
  
  useEffect(()=>{
    setCount(count + 1);
    commonHook.setKeys();
    TEMP_DEFAULT_FILTERS.uri = 'getInstructorAllEvents';
    
    commonHook.getList(TEMP_DEFAULT_FILTERS);
    setCount(1)
  },[])



/*

  if (commonHook?.loading) {
    return (
        <>
           <LayoutGuru {...props}>
            <Loader></Loader>
            </LayoutGuru>
        </>
    )
} else {*/
  return (
    <>
    <LayoutGuru {...props}>
    <MyMeetingList  {...props}  {...commonHook}  />
        
    </LayoutGuru>
    </>
)
/*}*/
  
}

export default MeetingList;
