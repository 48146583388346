import React from "react";
import './modal.scss'
import Icon from '../icon'
import CloseIcon from '../svg/closeIcon'
import Button from '../button'

const Modal = props => {
    const onClickOuterClose = () => {
        if (props.canClose) {
            props.onOuterClose()
        }
    }
    return (
        <>
            <div
                className={`modal-wrapper ${props.light ? 'modal-light' : ''} ${props.className ? props.className : 'modal_width'}`} >
                <div className="backdrop" onClick={onClickOuterClose}></div>
                <div
                    className={`modal-content ${props.modalLarge ? 'modal-content-large' : props.modalMediumExtra ? 'modal-content-medium-extra' : props.modalMedium ? 'modal-content-medium' : props.modalSmall ? 'modal-content-small' : ''}`}  style={{margin:"10px"}}>
                    <div className={`modal-body x-vis ${props.noscroll ? 'no-scroll' : ''}`} >
                        {props.onClose && props.canClose &&
                        <Button
                            className="modal-close"
                            onClick={props.onClose}
                            linkSecondary
                             
                            text={
                                <Icon
                                Yellow
                                    icon={<CloseIcon/>}
                                />
                            }
                        />
                        }
                        {props.modalContent}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Modal
;

Modal.defaultProps = {
    canClose: true,
    noScroll: false
}
