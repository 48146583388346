import React, { useState } from 'react';
import Section from '../../../../components/section';
import Text from '../../../../components/text';
import CourseImg from '../../../../assets/img/logo.svg';
import CourseImg1 from '../../../../assets/img/logo.svg';
import CourseImg2 from '../../../../assets/img/logo.svg';
import { HiOutlineUsers } from "react-icons/hi";
import { MdOutlinePriceChange } from "react-icons/md";
import { NavLink, useSearchParams } from 'react-router-dom';
import Flex from '../../../../components/flex';
import Select from '../../../../components/input/select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Pagination from '../../../../components/pagination';
import Button from '../../../../components/button';
import useLearnByTopics from '../../../../components/learnByTopics/hooks/learnByTopics.hook';
import Input from '../../../../components/input';

const CategeoryPurchaseView = (props) => {
    const formik = useFormik({
        initialValues: {
            category: '',
            
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Please enter a valid study level'),
           
        }),
        onSubmit: (values, actions) => {

        },
    });
    const tableData = props?.tableData?.listData
    
    
    const getFilterTopic = (e) => {
    
        props?._props.setcheckFilter(true);
        props?._props.setFilterId(e.target.value);
        formik.setFieldValue('category', e.target.value)
        getSubCategoriesById(e.target.value)
    }
    const [subCategories, setSubCategories] = useState(null)
    const getSubCategories = (e) => {
        formik.setFieldValue('category', e.target.value)
        getSubCategoriesById(e.target.value)
    }
    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (dropdownsData?.subCategoriesOptions) {
            dropdownsData?.subCategoriesOptions.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatid, label: item.subCatName})
                }
            })
        }
        setSubCategories(subCategoryOptions)
    }

    const {
        dropdownsData,
    } = props?.guruUploadVideoHook;

    

{/**
    const [searchParams] = useSearchParams();
    const categoryId = searchParams.get('catId')
    const categoryName = searchParams.get('catName')

    const [active, setActive] = useState({catId: 0, catName: 'All topics'});
    const showLoadMore = learnByTopicsHook?.videosList && Object.keys(learnByTopicsHook?.videosList).length >= 8 && learnByTopicsHook?.videosApiData?.totalRecords > Object.keys(learnByTopicsHook?.videosList).length
    
    const learnByTopicsHook = useLearnByTopics(categoryId, categoryName)
    if(props?.extraParams?.type == "get_today_added_course"){
     //console.log("date->",useHelper.formatDateYYYYMMDD(new Date()));
     
        learnByTopicsHook.filterKeys.fromdate = props?.extraParams?.from_date
    }else{

    } */}
   

  return (
    <div>
      <Section>
        <div className='row mb-lg-4'>
            <div className='col-md-7'>
            <Text type="H2" text="Subscribe Any One Category" className="mb-2 text-start"/>
      <Text type="PARAGRAPH" text="please Purchase any one Category to enjoy Learning and Earn Affiliate Income." className="mb-3 text-start"/>
            </div>
            <div className='col-md-5'>
            <div className='row'>
            <div className='col-md-8'>
        <form onSubmit={formik.handleSubmit}>
        <div className="">
                    <input
                    type="text"
                    label={props.search}
                    placeholder="Search for any topics..."
                    name="searchValue"
                    className="input-box px-3"
                    value={formik.values.searchValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    errormessage={formik.touched.searchValue && formik.errors.searchValue ? (
                        formik.errors.searchValue
                    ) : null}
                />
                
                    </div>
</form>
</div>
<div className='col-md-4'>
<Button
type="submit"
text="search"
onChange={getFilterTopic}
className="btn-categeory-search mt-32"
isSubmitting={formik.isSubmitting}
disabled={formik.isSubmitting}

/>

</div>
        
        </div>
            </div>
        </div>
      
         <div className='row gy-4'>

             {
                dropdownsData?.categories.map(function (item, index) {
                 
                    console.log(item)
                    return(
                        <>
                        
                        <div className='col-md-4'>
                <div class="card box-shadow-4 p-3 h-100">
                    <div class="card-img-top position-relative">
                        <img src={process.env.REACT_APP_IMG_URL + item.catPic} class="w-20" alt="Card Image" />
                    </div>
                    <div class="card-body align-items-center">
                        <h4 class="font-weight-bold text-4 text-dark line-height-3 mt-1 pl-0">{item.catName}</h4>
                       <p className='text-lightdark'>{item.catDesc}</p>
                        <div class="row">
                            <div class="col-md-6">
                            <HiOutlineUsers color='#FFD109'/>&nbsp; <span className='text-lightdark'>users</span> : {item.userCount} 
                            </div>
                            <div class="col-md-6">
                            <span class="text-lightdark">Price : <span className='text-dark'>£ {item.amount}</span> </span>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <NavLink to={'/trainee/purchase-sub-categeory/'+item.catId}>
                                <button class="btn  btn-primary btn-outline "> View More</button></NavLink>
                            </div>
                            <div class="col-md-6">
                               <NavLink to={'/trainee/categeory-payment/'+item.catId}>
                               <button class="btn btn-primary">Purchase</button> </NavLink> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                        
                        
                        
                        
                        </>    



                    )
            
                
                })
             }           

         
            
         </div>
        {/** {showLoadMore && (
                    <Flex justifyContent="center">
                        <Button type="button" outline large text={props?.text?.LOAD_MORE} onClick={() => {
                            learnByTopicsHook.setFilters(active.catId, learnByTopicsHook.filterKeys.page + 1)
                        }}/>
                    </Flex>
                )} */}
        <div className="mt-4">
        <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props?.nextPage}
                previousPage={props?.previousPage}
                perPage={props?.filterKeys?.perpage}
                
            />
        </div>

      </Section>
    </div>
  )
}

export default CategeoryPurchaseView
