import { useFormik } from 'formik';
import React, { useState } from 'react';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import GoBackButton from '../../../../components/goBackButton';
import Input from '../../../../components/input';
import Radio from '../../../../components/radio';
import Text from '../../../../components/text';
import { setSurveyQuestions } from '../../../../store/reducers/app.reducer';
import useSurveyQuestionThree from '../hooks/surveyQuestionThree.hook';
import * as Yup from 'yup';
import {toast} from "react-toastify";
import { useNavigate } from 'react-router-dom';
import useLogin from '../../../authentication/login/hooks/login.hook';
import useHttpHandler from '../../../../hooks/httphandler.hook';
import Checkbox from '../../../../components/checkbox';


const SubscribeDetailInformationView = (props) => {
      const navigate =useNavigate();
      const {numbersOnly} = useLogin()
      const {post} = useHttpHandler()
      const { goToComplete, profileDetails} = props
    const {text, surveyQuestions, updateSurveyQuestions, isSubmitting} = props;
    const setValue = (e)=> {
        setEarningAffiliate(e.target.value)
    }
    const {
        earningAffiliate,
        setEarningAffiliate
    } = useSurveyQuestionThree()

    const formik = useFormik({
        initialValues: {
            bankName: profileDetails?.bankname ? profileDetails?.bankname : '',
            accountHolderName: profileDetails?.payeename ? profileDetails?.payeename : '',
            accountNumber: profileDetails?.accountnumber ? profileDetails?.accountnumber : '',
        },
        validationSchema: Yup.object({
            bankName: Yup.string().required('Valid Bank'),
            accountHolderName: Yup.string().required('please enter a valid account Holder Name'),
            accountNumber: Yup.string().required('please enter a valid account number').matches(numbersOnly, 'please enter a valid account number'),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)
            post('user_bankdetailsentry', values).then((res)=>{
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if(status.result == '200') {
                    goToComplete()
                } else {
                    toast.error(status.msg)
                }
            }).catch(err => {actions.setSubmitting(false);})
        },
    });


    const [showSurveyForm, setSurveyForm] = useState(true)

    const [showBankForm, setBankForm] = useState(false)
    const onClick = () => {
        setBankForm(true);
        setSurveyForm(false);

    }

    const goPayment =()=>{
        navigate('/trainee/categeory-payment')
    }

     const goToRadio =()=>{
        setSurveyForm(true);
        setBankForm(false);
     }
        

  return (
    <div>
        <div className='container mt-2'>
            
        {showSurveyForm ?<div className='survey_card'>
                
                <Text
                type="H3"
                text="Are You Want to Continue With Affiliate Income ?"
                className="mb-5 font-weight-400 text-center col-11 mx-auto"
            />
            
            <Text
                type="H3"
                text=""
                className="font-weight-500 text-center mb-48"
            />
            <div>
                
                <div className='row'>
                 <div className='offset-lg-3 col-md-6'>

                <Radio
                    radioSecondary
                    active={earningAffiliate === 'yes'}
                    id="yes"
                    text="Yes"
                    name="earningAffiliateIncome"
                    checked={earningAffiliate === 'yes'}
                    value="yes"
                    onChange={(e)=>{setEarningAffiliate(e.target.value)}}
                />
                </div>
                </div>
                <div className='row'>
                <div className='offset-lg-3 col-md-6'>
                <Radio
                    radioSecondary
                    active={earningAffiliate === 'no'}
                    id="no"
                    text="No"
                    name="earningAffiliateIncome"
                    checked={earningAffiliate === 'no'}
                    value="no"
                    onChange={(e)=>{setEarningAffiliate(e.target.value)}}
                />
                </div>
                </div>
                      
            </div>
            <Flex justifyContent="center" className="mb-48">
                <div className='row'>
                    <div className='col-md-7 mt-2'>
                    <GoBackButton onClick={goPayment} text="Go Back"/>
                    </div>
                    <div className='col-md-5'>
                    <button class="btn-purchase_1 mt-1" onClick={onClick}>Submit</button>  
                    </div>
                </div>
               
             
            </Flex>
                </div> : null}

             {showBankForm ?  <div className='row'>
                <Text
                type="H4"
                text="Bank Details"
                className="page-title"
            />
            
            <form onSubmit={formik.handleSubmit}>
               
                <div className="row">
                    <div className='col-6'>
                    <div className="formBox">
                    <Input
                        label="Bank Name"
                        type="text"
                        name="bankName"
                        placeholder="Your Bank Name"

                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.bankName && formik.errors.bankName ? (
                            formik.errors.bankName
                        ) : null}
                    />
                </div>
                    </div>
                <div className="col-6">
                        <div className="formBox">
                            <Input
                                label="Date of Birth"
                                type="date"
                                name="dob"
                                placeholder="Full Name"

                                value={formik.values.dob}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.dob && formik.errors.dob ? (
                                    formik.errors.dob
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label="Account Holder Name"
                                type="text"
                                name="accountHolderName"
                                placeholder="Account Holder"

                                value={formik.values.accountHolderName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountHolderName && formik.errors.accountHolderName ? (
                                    formik.errors.accountHolderName
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="formBox">
                            <Input
                                label="Account Number"
                                type="number"
                                name="accountNumber"
                                placeholder="Account Number"

                                value={formik.values.accountNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.accountNumber && formik.errors.accountNumber ? (
                                    formik.errors.accountNumber
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className='col-12'>
                    <div className="formBox">
                    <Checkbox
                        name="acceptTerms"
                        value={formik.values.acceptTerms}
                        label={<>I have agree the  <Button type="button" className="ml-1" linkSecondary
                                                                              text="TERMS &amp; CONDITIONS"/></>}
                        id="acceptTerms"
                        isChecked={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        errormessage={formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            formik.errors.acceptTerms
                        ) : null}
                    />

                </div>
                    </div>
                </div>
                <Flex justifyContent="center" className="mb-28 mt-10">
                    <div className='row'>
                        <div className='col-md-7 mt-1'>
                        <Button
                        type="button"
                        text="Go Back"
                        large
                        light
                        outline
                        onClick={goToRadio}

                    />
                        </div>
                        <div className='col-md-5'>
                        <button class="btn-purchase_1">Submit</button>  
                        </div>
                    </div>
                </Flex>
            </form>
                </div> : null}
            </div>
        </div>
        
    
  )
}

export default SubscribeDetailInformationView;
