import React from "react";
import './navbarGuru.scss'
import DashboardIcon from '../svg/dashboardIcon'
import Nav from '../nav'
import LockIcon from "../svg/lockIcon";
import WalletIcon from "../svg/walletIcon";
import IDCardIcon from "../svg/idCardIcon";
import BoxCheckIcon from "../svg/boxCheckIcon";
import ReportIcon from "../svg/reportIcon";
import SmartDisplayIcon from "../svg/smartDisplayIcon";
import { useMediaQuery } from "react-responsive";
import MenuIcon from "../svg/menuIcon";
import Button from "../button";
import { GrDocumentVideo } from "react-icons/gr";
import { SiDiscourse } from "react-icons/si";

const Navbar = props => {
    const {text} = props
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1279 })
    return (
        <>
            <nav className={`navbar-guru ${isTablet ? props.sideMenu ? 'navbar-side-expand' : 'navbar-side-minimize' : ''}`}>
                {isTablet && (
                    <Button
                        text={<MenuIcon />}
                        link
                        className="nav-side-toggle"
                        onClick={props.SideMenuToggle}
                    />
                )}
                <Nav
                    options={[
                        {
                            text:"Dashboard",
                            link:'/instructor/dashboard',
                            icon:<DashboardIcon />,
                        },
                    ]}
                /><hr className="solid-hr"/>
                <Nav
                    title={isTablet ? '' : 'INSTRUCTOR  VIDEO'}
                    options={[
                        {
                            text:"Courses",
                            link:'/instructor/course',
                            icon:<SiDiscourse />,
                        },
                       /* 
                        {
                            text:"Total Topic Videos",
                            link:'/instructor/my-video',
                            icon:<SmartDisplayIcon />,
                        },
                    */
                        {
                            text:"My Commission",
                            link:'/instructor/dashboard',
                            icon:<SmartDisplayIcon />,
                        },
                        
                        
                    ]}
                />
               
            </nav>
        </>
    )
}

export default Navbar;
