import React from 'react';
import './input.scss'

const AccountExistingPswd = props => {
    return (
        <div className= {props.className ? props.className : ''}>
            {props.label && <label className={`form-label`}>{props.label} {props.mandatory && <span className="text-danger">*</span>}</label>}
            <input
                className={`form-control ${props.value ? 'form-control-value' : ''} ${props.formControlClassName ? props.formControlClassName : ''} ${props.errormessage ? 'error' : ''}`}
                type={props.type}
                name={props.name}
                value={props.value}
                id={props.id}
                placeholder={props.placeholder}
                onChange={props.onChange}
                readOnly={props.readonly}
                disabled={props.disabled}
                required={props.required}
                autoFocus={props.autoFocus}
                maxLength={props.maxlength}
            />
            {props.icon && <div className="pswd-icon">{props.icon}</div>}
            {props.errormessage && props.type != 'checkbox' && <div className="message-error"><small>{props.errormessage}</small></div>}
        </div>
    );
};

export default AccountExistingPswd;
