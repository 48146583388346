import React from 'react'
import Breadcrumbs from '../../../../components/breadcrumbs';
import Section from '../../../../components/section';
import Text from '../../../../components/text';
import TodayAddedCourses from '../../../../components/todayAddedCourse';
import TopicsList from '../../../../components/topicsList';
import TotalTopicSearch from '../../../../components/total_topic_search';

const TodayAddedCoursesView = (props) => {
  console.log("test_value-->",props?.extraParams);

  const breadcrumbList = [
    {
        text: 'Dashboard',
        link: '/trainee/dashboard',
    },
    {
        text:"Courses",
        link:"/trainee/courses",
    },
    {
      text:"Today Added Courses",
      link:"/trainee/today-added-courses",
  },
]

return (
  <div>
        <Breadcrumbs
      breadcrumbList={breadcrumbList}
    />
 <Section className="pt-0 lbt-section rml-24 overflow-outer">
              <div className="section-head mt-2">
                  <Text
                      type="H3"
                      text="Courses"
                      className="section-title text-uppercase"
                  />
              </div>
            
      <TotalTopicSearch  search="Courses Search" {...props}  dropdownsData={props.traineeDropdownsData} />
      <TopicsList {...props}/>

  </Section>
       
  </div>
)
}

export default TodayAddedCoursesView
