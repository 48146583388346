import React, {useState} from "react";
import '../heroslider/heroslider.scss'
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import HeroImage from '../../assets/img/logo.svg'
import Text from "../text";
import Button from "../button";
import {NavLink, useNavigate} from "react-router-dom";
import StaticVideoPlayer from "../videoPlayers/staticVideoPlayer";
import { BiBook } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { useSelector } from "react-redux";

const FreemiumVideoPanel = props => {
    const navigate = useNavigate();
    const {text} = props
    const userInfo = useSelector(state => state.app.userInfo)
    
    const [currentVideoDetails, setCurrentVideoDetails] = useState(null)
    return (
        <>
         <div class="point_block mb-1 mt-3 ">
        <div class="container container-xl-custom">
            <div class="row justify-content-between align-items-center ">
                <div class="col-lg-10">
                <Text type="H2" text={"WELCOME BACK " + userInfo.uname} className="mb-1 text-dark text-uppercase"/> 
                <Text type="PARAGRAPH" className="mb-0 mt-0 W-50 text-darklight" text="Please Purchase any one Categeory to enjoy learning and earn Affiliate Income."/>
                </div>
                <div className='col-md-2'>
                <NavLink to={'/trainee/purchase-categeory'}><button class="btn-subscribe">Subscribe Now</button></NavLink>  
                </div>
            </div>
            
            </div>
        </div>
            <div className="heroslider mt-2">
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    modules={[Navigation]}
                    navigation
                    onSlideChange={(swiper) => {
                        console.log('onSlideChange', swiper.activeIndex);
                        setCurrentVideoDetails(props?.options[swiper.activeIndex])
                    }}
                    onSwiper={(swiper) => {
                        let activeIndex = swiper.activeIndex;
                        let activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                        let activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                        console.log('onSwiper', activeSlideVideo)
                        activeSlideVideo.play();
                    }}
                    onTransitionEnd={(swiper) => {
                        let activeIndex = swiper.activeIndex;
                        let activeSlide = document.getElementsByClassName('swiper-slide')[activeIndex];
                        let activeSlideVideo = activeSlide.getElementsByTagName('video')[0];
                        console.log('activeSlide', activeSlide)
                        activeSlideVideo.play();
                    }
                    }
                    onTransitionStart={() => {
                        let videos = document.querySelectorAll('video');
                        Array.prototype.forEach.call(videos, function (video) {
                            video.pause();
                        });
                    }}
                >
                    {props?.options?.map(function (d, idx) {
                        return (
                            <SwiperSlide key={idx}>
                                <div className="hero-item">
                                    <video
                                        controls
                                        autoPlay
                                        muted
                                        src={process.env.REACT_APP_IMG_URL + d?.videofilename}
                                        preload="auto"
                                    ></video>
                                   
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

            </div>

            <div class="bg-light">
					<div class="container p-4">
						<div class="row pb-2 mb-1">
							<div class="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
										<em>1.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
										<div class="animated-icon animated fadeIn svg-fill-color-primary">
										<BiBook size={'30px'} className='icon-fill-color-primary' />
										</div>
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="150">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 1</p>
										<h4 class="mb-0 text-color-secondary">Learn</h4>
									</div>
								</div>

							</div>
							<div class="col-lg-4 col-md-6 col-sm-12 mb-4 mb-md-0">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
										<em>2.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
										<div class="animated-icon animated fadeIn svg-fill-color-primary ms-2">
										 <GiMoneyStack  size={'30px'} className='icon-fill-color-primary' />
										</div>
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="300">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 2</p>
										<h4 class="mb-0 text-color-secondary">Earn</h4>
									</div>
								</div>

							</div>
							<div class="col-lg-4 col-md-6 col-sm-12">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
										<em>3.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
										<div class="animated-icon animated fadeIn svg-fill-color-primary">
                                          <AiOutlineShareAlt size={'30px'} className='icon-fill-color-primary' />
                                     </div> 
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="450">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 3</p>
										<h4 class="mb-0 text-color-secondary">Share</h4>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
        </>
    )
}
export default FreemiumVideoPanel;

FreemiumVideoPanel.defaultProps = {
    options: [
        {
            text: "LEARN AND GET REWARDS",
            src: 'asdsd',
            alt: "Hero Image"
        },
        
        
    ]
};
