import React from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import AccountView from "../../../instructor/settings/account/view/AccountView";
import LearnerAccountView from "./view/AccountView";

const Account = props => {
    return (
        <>
            <LayoutPanel {...props}>
                <LearnerAccountView {...props} />
            </LayoutPanel>
        </>
    )
}

export default Account;
