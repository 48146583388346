import Text from '../../../../components/text';
import Flex from '../../../../components/flex';
import Button from '../../../../components/button';
import useOTPVerification from "../hooks/otpVerification.hook";
import OtpInput from 'react-otp-input';
import {useSelector} from "react-redux";
import logoImg from '../../../../assets/userimg/login-bg2.png';

const OTPVerificationView = (props) => {
    const {text} = props
    const userInfo = useSelector(state => state.app.userInfo)
    const {mobile, isdcode} = userInfo
    const mobileNumber = "+44" + mobile

    const {
        oTPInput,
        handleChangeOTP,
        counter,
        goBackToRegister,
        verifyOtp,
        resendOtp,
        submittingOtp
    } = useOTPVerification();

    return (
        <>
        <div class="register">

<main>
  <div class="container-fluid">

    <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
      <div class="container">
        <div class="row justify-content-center">
        
        
          <div class="col-lg-7 col-md-6"> 
          <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' />
          </div>
          </div>
        
        
          <div class="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">

            

            <div class="card mb-3">

              <div class="card-body">

                <div class="pt-2">
                  <h5 class="card-title text-center pb-0 fs-4">OTP verification</h5>
                  <p class="text-center small">We will send you One Time Password for verification to

</p>
                </div>

              
           
            <Text
                type="H2"
                text={mobileNumber}
                className="text-center mb-40"
            />
            <Flex justifyContent="center" className="mb-40">
                <OtpInput
                    value={oTPInput}
                    onChange={handleChangeOTP}
                    numInputs={4}
                    containerStyle="otp-wrapper"
                    shouldAutoFocus={true}
                    isInputNum={true}
                />
            </Flex>
            {counter > 0 ?
                <>
                    <Text
                        type="PARAGRAPH"
                        text={ + ' ' + '00:' + counter}
                        className="text-center"
                    />
                </>
                :
                <>
                    <Text
                        type="PARAGRAPH"
                        text="Didn’t receive OTP code?"
                        className="text-center"
                    />
                    <Flex justifyContent="center">
                        <Button
                            linkSecondary
                            text="Resend Code"
                            onClick={resendOtp}
                        />
                    </Flex>
                </>
            }
            <Flex justifyContent="center" className="mt-40">
                <Button type="button" light large outline text={text?.Go_BACK} onClick={goBackToRegister}
                        className="me-32"/>
                <Button
                    isSubmitting={submittingOtp}
                    type="button"
                    className="btn-verification"
                    large
                    text={text?.SUBMIT}
                    onClick={verifyOtp}
                    disabled={oTPInput.length < 4}/>
            </Flex>

              </div>
            </div>


          </div>
        </div>
      </div>

    </section>

  </div>
</main>
</div>
           
        </>
    );
};

export default OTPVerificationView;

