import useHttpHandler from "../../../../hooks/httphandler.hook";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useHelper from "../../../../hooks/helpers.hook";

const useGuruDashboard = () => {
    const {post} = useHttpHandler()
    const {formatGraphData} = useHelper()
    const [loading, setLoading] = useState(true)
    const [dashboardDetails, setDashboardDetails] = useState(null)
    const [deletedCourse, setDeletedCourse] = useState(false)


    const getDashboardDetails = () => {
        setLoading(true)
        post('fetchInstructorDashboard').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setDashboardDetails(data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    const getChatDashboardDetails = () => {
        setLoading(true)
        post('fetchInstructorChatDashboard').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setDashboardDetails(data)
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }


    const instrutorDeleteCourse = (params) => {
        setLoading(true)
        post(params.uri,params).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setDeletedCourse(true);
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }

    
    





    return {
        loading,
        dashboardDetails,
        getDashboardDetails,
        instrutorDeleteCourse,
        deletedCourse, 
        setDeletedCourse,
        getChatDashboardDetails
    }

}
export default useGuruDashboard


