import React, {useState} from 'react';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Pagination from '../../../../../components/pagination';
import Flex from '../../../../../components/flex';
import DropdownNew from '../../../../../components/dropdown/dropdownNew';
import Button from '../../../../../components/button';
import Table from '../../../../../components/table';
import useHelper from "../../../../../hooks/helpers.hook";

const MyReportView = (props) => {
    const {text} = props
    const {formatDateMMMDDYYYY} = useHelper()
    const tableData = props?.tableData?.listData
    const [yearSelected, setYearSelected] = useState('Year 2022');
    const [monthSelected, setMonthSelected] = useState('Select Month');
    const monthOption = [
        {
            value:'Select payment date',
        },
        {
            value: '01/04/2022 -  31/04/2022 ',
        },
        {
            value: '01/05/2022 -  30/05/2022 ',
        },
        {
            value: '01/06/2022 -  31/06/2022 ',
        },
        {
            value: '01/07/2022 - 30/07/2022',
        },
        {
            value: '01/08/2022 - 31/08/2022 ',
        },
    ];
    const yearsOption = [
        {
            value: 'Select payment year',
        },
        {
            value: '2022',
        },
        {
            value: '2021',
        },
        {
            value: '2020',
        },
        {
            value: '2019',
        },
        {
            value: '2018',
        },
    ];
    return (
        <>
           <Text
                type="H1"
                text="Report"
            />
            {/*<NavLearnerMember/>*/}
            <Flex justifyContent="between" className="mb-24 flex-column flex-md-row">
                <div>
                    {props?.tableData?.customdata?.total_amount > 0 && (
                        <>
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-black font-weight-400"
                                text="Current Income"
                            />
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-white-half mt-2"
                                text={`RM ${props?.tableData?.customdata?.total_amount}`}
                            />
                        </>
                    )}

                </div>
               {/* <div className="filterby">
                    <Text type="PARAGRAPH" text="Filter by:" className="text-dark mb-0 mr-10"/>
                    <div className="filterby-lists">
                        <DropdownNew
                            secondaryDropdown
                            right
                            selected={monthSelected}
                            setSelected={setMonthSelected}
                            options={monthOption}
                            placeholder="payment date"
                        />
                        <DropdownNew
                            secondaryDropdown
                            right
                            selected={yearSelected}
                            setSelected={setYearSelected}
                            options={yearsOption}
                            placeholder="payment year"
                        />
                        <Button
                            type="button"
                            text="Generate Report"
                        />
                    </div>
                </div>*/}
            </Flex>

            <Table
                thead={
                    <tr>
                        <th>Date</th>
                        <th>Member Id</th>
                        <th>Referral name</th>
                        <th>Type/Detail</th>
                        <th>Amount</th>
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                    <td>{formatDateMMMDDYYYY(item.updated_on)}</td>
                                    <td>{item.memberid}</td>
                                    <td>{item.fullname}</td>
                                    <td>{item.type_details}</td>
                                    <td>{item.amount}</td>
                                </tr>
                            )
                        })}
                        {props?.tableData?.customdata?.total_amount > 0 && (
                            <tr>
                                <td colSpan="4" className="text-right text-white-half">Total</td>
                                <td className="font-weight-600 text-black">
                                    RM {props?.tableData?.customdata?.total_amount}</td>
                            </tr>
                        )}
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
        </>
    );
};

export default MyReportView;

