import React from "react";
import '../card/card.scss';
import { NavLink, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { AiOutlineHeart } from "react-icons/ai";



const HomePageCard = props => {

  const navigate = useNavigate();
  

    return(
      
        <div class="course-item all-scroll bg-col-white " 
        onClick={() => {
          navigate('/course-preview/' + props.courseId +'-0')
      }}
        >
              {props.price == "Stay Free" &&(
                <div class="course-content label-text-img">
                <div class="d-flex justify-content-between align-items-center rounded-start">
                 <h4>{props.price}</h4>
                  {/* <p class="price">{props.price}</p> */}
                </div>
                </div>
              )}
              
          
          <div className="card-img-height">
        <img src={process.env.REACT_APP_IMG_URL + props.imgsrc} class="img-fluid-height-150" alt="..." />
        </div>
              <div class="course-content">
                <div class="d-flex justify-content-between align-items-center mb-3">
                 <h4>{props.subtitle}</h4>
                 
                </div>

                <h3>{props.title.substr(0, 40)}</h3>
                <p style={{minHeight:75}}>{ props.desc.substr(0, 100)}{props.desc.length>100 &&(<>...</>)}</p>
                <div class="trainer d-flex justify-content-between align-items-center">
                  <div class="trainer-profile d-flex align-items-center">
                    <img src={process.env.REACT_APP_IMG_URL + props.trainerimg} class="img-fluid" alt="" />
                    <span>{props.trainername.replaceAll(" ",'')}</span>
                  </div>
                  <div class="trainer-rank d-flex align-items-center">
                    <RxPerson />&nbsp;{props.user}
                    &nbsp;&nbsp;
                    <AiOutlineHeart />&nbsp;{props.heart}
                  </div>
                </div>
              </div>
            </div>
    )
}

export default HomePageCard;

