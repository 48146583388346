import Button from '../button';
import Input from '../input';
//import './chat.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import React, {useEffect, useState} from 'react';
import useHelper from '../../hooks/helpers.hook';

const ChatOnlineStatus = (props) => {


const {formatDateDDMMYY} = useHelper();



  /*
  function useOnceCall(cb, condition = true) {
    const isCalledRef = React.useRef(false);
  
    React.useEffect(() => {
      if (condition && !isCalledRef.current) {
        isCalledRef.current = true;
        cb();
      }
    }, [cb, condition]);
  }

  useOnceCall(() => {
    console.log("loadDataOnlyOnce");
    props?.commonHook?.promiseServiceNonLoading({"uri":"getChatRooms"}).then((data) => {
      console.log("userListData",data.data.data);
      console.log(data.data.data);
      setUserListData(data.data.data);
      } );
  })
  */

  useEffect(() => {
    props?.commonHook?.promiseServiceNonLoading({"uri":"getChatRooms"}).then((data) => {
      console.log("userListData",data.data.data);
      console.log(data.data.data);
      props.setUserListData(data.data.data);
      props.setOnlineUsers(1);
      } );
  },[props.checkOnlineUser]);
 
  





return(
    <>
    <div class="discussions">
        <div class="discussion search">
          <div class="searchbar">
            <i class="fa fa-search" aria-hidden="true"></i>
            <input type="text" placeholder="Search..."></input>
          </div>
        </div>




{props.userListData?.map(function (item, index) {

  var message = "";
  var time = "";

  if(item.ChatMessage.length >= 1){
    const temp_arr = item.ChatMessage[0].roomMessage.split("%!#@");
    message = temp_arr[3]
    time = formatDateDDMMYY(item.ChatMessage[0].createdAt)
    
  }


      return(

        <div class="discussion message-active"   onClick={() => {
          console.log("data",item.ChatMessage[0].chatRoom)
          
          props.setPageNum(1);
          props.setMessages("")
          props.setRoomName(item.ChatMessage[0].chatRoom)
      }}>
        <div class="online"></div>
            <img src={process.env.REACT_APP_IMG_URL + item.pic} alt="" className='photo' />      
            
        
            <div class="desc-contact" >
              <p class="name">{item.userDetails.userName}</p>
              <p class="message">{message}</p>
            </div>
            <div class="timer">{time}</div>
          </div>


      );

})}



     </div>
 
    </>

)


}

export default ChatOnlineStatus;