import React from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import FavouriteCourseView from './views/favouriteCourseView'

const FavouriteCoursePage = (props) => {
  const commonHook = useGetTableList();

  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
}else{
  return (
    <div>
        <LayoutPanel {...props} footer>
      <FavouriteCourseView />
      </LayoutPanel>
    </div>
  )
}
}

export default FavouriteCoursePage
