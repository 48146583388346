import { StyleSheet, Text, View, Pressable,Image } from "react";
import './timeButton.scss'
import useHelper from "../../hooks/helpers.hook";
const TimeButtonLearner = (props) => {
  const {fromatGetDate,formatDateHms,formatDateHH,formatDateMMMDDYYYY} = useHelper();
  const item = {...props.item}
    return (
              <> 


                {props.type == "date"   &&(
                    <button className={item.slot_date == props.active.slot_date ? "slotDateButton" : "slotWebNair" } onClick={()=>{
                    console.log("item.slot_date == props.active",item.slot_date ,props.active)
                    props.buttonClick(item);


                   }} >
                      <text >{fromatGetDate(item.slot_date)}</text>
                   </button>
                )}




                {props.type === "time"   &&(
                                <button className={item.metting_type_id == 3 ? "slotWebNair" : "slotOnetoOne" } onClick={()=>{
                                        props.buttonClick(item);
                                 }}  >
                                <text >{item.slot_time +  " - " + item.metting + ' - Price : ' + item.price}</text>
                                </button>
                              
                )}

                {props.type === "meeting"   &&(
                                <button className={item.metting_type_id == 3 ? "slotWebNair" : "slotOnetoOne" } onClick={()=>{
                                        props.buttonClick(item);
                                        if(item.minutes >= 0 && item.minutes <5 ){
                                          
                                        }
                                 }}  >

                                <text >{ formatDateMMMDDYYYY(item.slot_date) +  " - " + item.slot_time +  " - " + item.metting}</text>
                                </button>
                              
                )}

               

                
             
             </>
 );
};


export default TimeButtonLearner;