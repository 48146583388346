import React, {useState} from "react";
import CalendarIcon from "../svg/calendarIcon";
import ViewEyeIcon from "../svg/ViewEyeIcon";
import ClockIcon from '../svg/clockIcon';
import Text from "../text";
import './videoPlayersDetails.scss';
import Moment from 'moment';
import Button from '../button';
import Icon from '../icon';
import LikesIcon from "../svg/likesIcon";
import Avatar from '../avatar';
import avatarPic from '../../assets/images/avatar_pic2.jpg';
import StarIcon from '../svg/starIcon';
import useHelper from "../../hooks/helpers.hook";
import { useMediaQuery } from "react-responsive";
import { GURU } from "../../constants/app.constants";

const VideoPlayersDetails = props => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const {formatDateDDMMMMYYYY, formatDateDDMMYY2, formatDateHms} = useHelper()
    const [showFullDescription, setShowFullDescription] = useState(false)
    const viewCount = props.views ? props.views > 1 ? props.views + ' views' : props.views + ' view' : '0 view'
    const likeCount = props.videoLikesCount ? props.videoLikesCount > 1 ? props.videoLikesCount + ' likes' : props.videoLikesCount + ' like' : '0 like'
    return (
        <div className="video-players-details p-3">
                <div className="vpd-upload-channel-info mb-2">
                <div className="vpd-dp-heading">

                <div className="vpd-dp-details video-catag ml-0">
                        <div className="">
                        
                        <label className="category-title">{props.category} {props?.subcategory && ('.')} {props.subcategory}</label>

                        <div className="vpd-views-date-time d-flex">
                        <div className="vdt">
                            <ViewEyeIcon width="14" height="9"/>
                            <Text
                                type="SPAN"
                                text={viewCount}
                            />
                        </div>
                        <div className="vdt">
                            <CalendarIcon width="14" height="16"/>
                            <Text
                                type="SPAN"
                                text={isMobile ? formatDateDDMMYY2(props?.date) : formatDateDDMMMMYYYY(props?.date)}
                            />
                        </div>
                        <div className="vdt">
                            <ClockIcon width="14" height="14"/>
                            <Text
                                type="SPAN"
                                text={formatDateHms(props.date)}
                            />
                        </div>
                    </div>

                        </div>
                        
                      
                       
                      
                    </div>               
                    <div className="vpd-upload-info-right">
                {props?.usertypeid != GURU &&(
                    <Button
                        link
                        text={likeCount}
                        className="d-flex"
                        iconLeft={
                            <Icon
                                gray={!props?.isLike}
                                primary={props?.isLike}
                                
                                size32
                                icon={<LikesIcon width="12" height="10"/>}
                            />
                        }
                        onClick={props?.onClickLikeVideo}
                    />
                )}
                </div>
                    
                </div>

                <div className="avatar-right d-flex">
                    <Avatar
                        large
                        src={props?.avatarImage}
                        alt=""
                    />
                    <div className="">
                    <Text type="H4" text={props.channelName}/>

<div className="d-flex align-items-center">
        
        <div
            className="pointer"
        >
            <Icon
                gray={!props.isGuruFavourite}
                primary={props.isGuruFavourite}
                smallExtra
                icon={<StarIcon fill width="10" height="10"/>}
            />
        </div>
        {props?.guruFavouritedCount != 0 && (
            <div className="vpd-dp-details-favourite-video-text">
                <Text
                    type="SPAN"
                    className="font-weight-700"
                    text={props.guruFavouritedCount}
                />
                <Text
                    type="SPAN"
                    text=" Favourited the video"
                />
            </div>

        )}
    </div>
                    </div>
                    </div>
                            {/*
                {props?.usertypeid != GURU &&(

                            <Button
                            type="button"
                            large={isMobile ? false : false}
                            small={isMobile ? true : false}
                            text={`${props.isGuruFavourite ? 'FAVOURITED' : 'FAVOURITE Instructor'}`}
                            iconLeft={props.isGuruFavourite ? <StarIcon fill width={isMobile ? '10' : '14'} height={isMobile ? '10' : '14'}/> : '+'}
                            onClick={props?.onClickFavoriteGuru}
                            />

                )
                    
                }*/}
                
            </div>
            <div className="vpd-upload-info mb-2">
                <div className="vpd-upload-info-left">
                   
                    <Text
                        type="H2"
                        className="title"
                        text={props.title}
                    />
                   
                </div>
                
                
            </div>
        
            <div className="vpd-upload-info-desc ">
                {/* <Text type="H4" text="Description" className="text-black-half mr-0 font-weight-400"/> */}
                {showFullDescription ? props.description : props.description}
                    {/* {props.description.length > 100 && (
                        <Button
                            type="button"
                            linkPrimary
                            text={showFullDescription ? 'Read Less' : 'Read More' }
                            style={{fontSize: 'inherit', marginLeft: '0.625rem'}}
                            onClick={() => {
                                setShowFullDescription(state => !state)
                            }}
                        />
                    )} */}
            </div>
        </div>
    )
}

export default VideoPlayersDetails;
