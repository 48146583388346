import React from "react";
import VideoCard from "../videocard";
import "./topicVideoCard.scss";
import TImage001 from "../../assets/img/logo.svg";
import Avatar from "../avatar";
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import { NavLink } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { AiOutlineHeart } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useSelector } from "react-redux";
import Button from "../button";
import copyImg from "../../assets/img/copyIcon.png";
import shareImg from "../../assets/img/shareIcon.png";
import viewImg from "../../assets/img/viewicon.png";

const InstructorCourseCard = (props) => {
  const navigate = useNavigate();
  const {formatDateDDMMMMYYYY} = useHelper();
  const userInfo = useSelector((state) => state.app.userInfo);
  const onCopy = () => {
    alert("Copied Course");
  };

  const { kFormatter, getShortDescription,getShortDescription_dot } = useHelper();
  return (
    <>
      <div
        className={`topic-video-card mb-2 mx-lg-0 h-100 ${
          props.secondary ? "topic-video-secondary" : ""
        } ${props.onClick ? "pointer" : ""}`}
        onClick={props.onClick}
        style={{ heigh: "250px !important" }}
      >
        <h4 className="cate_bg text-1 mt-3">{props.data.catName}</h4>
        <VideoCard
          noCaption
          noPlay
          poster={process.env.REACT_APP_IMG_URL + props.data.thumbnail}
          alt={props.data.title}
        />
        <div class="course-content">
        <span className="fs-8 pl-2">  <h6 className="mb-0"> {props.data.subCatName} </h6> <p className="mb-0">on   {formatDateDDMMMMYYYY(props.data.dot)}</p>  </span>
        </div>
        <div className="topic-video-details">
          <div className="topic-video-details-content w-100">
            <Text
              type="H3"
              text={getShortDescription_dot(props.data.title,45)}
              className="topic-video-details-content-title text-dark"
            />
            {props.by && (
              <Text
                type="PARAGRAPH"
                text={`by ${props.by}`}
                className="topic-video-details-content-by"
              />
            )}
            {props.desc && (
              <Text
                type="PARAGRAPH"
                text={getShortDescription_dot(props.data.des, 120)}
                className="topic-video-details-content-short-desc"
              />
            )}
            {props.views > 0 && (
              <Text
                type="PARAGRAPH"
                text={`${kFormatter(props.views)} ${
                  props.views > 1 ? "Views" : "View"
                }`}
                className="topic-video-details-content-views"
              />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="p-2">
              <div className="d-flex justify-content-between py-2">
                
                  <span className="box-social d-flex align-items-center">
                    <Text
                      type="p"
                      text={"+" + props?.data.likes}
                      className={"mb-0 fw-bold pr-2"}
                    />
                    <Text type="p" className=" pr-2 mb-0 fs-7" text="Likes" />
                  </span>
                  <span className="box-social d-flex">
                    <CopyToClipboard
                      onCopy={onCopy}
                      text={
                        URL +
                        "/course/" +
                        props?.data.courseid +
                        "-0-" +
                        userInfo?.myrefcode
                      }
                    >
                      <Button
                        toolTip="Copy Course"
                        link
                        className={"pull-left"}
                        text={
                          <img
                            src={copyImg}
                            alt="copy"
                            className="dashboard-share-icon"
                          />
                        }
                        type="button"
                      />
                    </CopyToClipboard>

                    <Text type="p" className=" pl-2 mb-0 fs-7" text="Copy" />
                  </span>
                  <span className="box-social d-flex">
                    <Button
                      link
                      toolTip="Share Course"
                      className={"pull-right"}
                      text={
                        <img
                          src={shareImg}
                          alt="copy"
                          className="dashboard-share-icon "
                        />
                      }
                      onClick={() => {
                        props.setSelectedCourseId(props?.data.courseid);

                        console.log("111");
                        props.setIsShareModal(true);
                      }}
                      style={{ marginLeft: "0px" }}
                      type="button"
                    />

                    <Text type="p" className="pl-2 mb-0 fs-7" text="Share" />
                  </span>
                  <span className="box-social d-flex">
                    <Button
                      link
                      text={
                        <div>
                          <img src={viewImg} alt="copy" className="icon-32" />
                        </div>
                      }
                      style={{ margin: "0px", padding: "1px" }}
                      onClick={() =>
                        navigate(
                          "/trainee/course-details/" + props?.data.courseid
                        )
                      }
                      type="button"
                    />
                    <Text type="p" className="pl-2  mb-0 fs-7" text="View" />
                  </span>
            
              </div>

              <div className="input-share-height">
                <Text
                  type="PARAGRAPH"
                  text={
                    process.env.REACT_APP_URL +
                    "/course/" +
                    props?.data.courseid +
                    "-0-" +
                    userInfo?.myrefcode
                  }
                  className="mb-0 fs-8"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InstructorCourseCard;
