import {BrowserRouter, Routes, Route, useNavigate,useHistory} from 'react-router-dom';
import HomeController from './pages/Home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/grid.css';
import './assets/scss/color.scss';
import './assets/css/style.scss';
import './assets/css/generalSettings.css'
import {useEffect, useState} from "react";
import ProtectedRoute from "./components/protectedRoute";
import About from "./pages/about";

import {useDispatch, useSelector} from "react-redux";
import {ToastContainer,toast} from "react-toastify";
import useTranslations from "./hooks/translations.hook";
import {ENGLISH, MALAY} from "./languages/languages.constants";
import Login from './pages/authentication/login'
import ResetPassword from "./pages/authentication/resetPassword";
import Logout from "./pages/authentication/logout";

// Learner
import RegisterLearner from './pages/trainee/register';
import OTPVerification from './pages/trainee/otpVerification';
import Subscribe from './pages/trainee/subscribe';
import Payment from './pages/trainee/payment';
import PaymentStatus from './pages/trainee/paymentStatus';
import PaymentSuccess from './pages/trainee/paymentSuccess';
import PaymentFailed from './pages/trainee/paymentFailed';
import ReferenceLink from './pages/trainee/referenceLink';

import PersonalInformation from './pages/trainee/personalInformation';
import AddressInformation from './pages/trainee/addressInformation';
import BankDetails from './pages/trainee/bankDetails';
import LearnerByTopics from "./pages/trainee/learnbytopics";
import LearnerLikedVideo from "./pages/trainee/likedVideo";
import LearnerFavouriteGuru from "./pages/trainee/favouriteInstructor";
import LearnerFavouriteGuruDetails from './pages/trainee/favouriteInstructorDetails';
import LearnerMain from "./pages/trainee/main";
import LearnerProfile from './pages/trainee/settings/profile';
import LearnerEditProfile from './pages/trainee/settings/profile/editProfile'
import LearnerSettingsBankDetails from './pages/trainee/settings/settingsBankDetails';
import LearnerSettingsEditBankDetails from './pages/trainee/settings/settingsBankDetails/editSettingsBankDetails';
import LearnerSettingsAccount from './pages/trainee/settings/account';
import LearnerMagazine from './pages/trainee/settings/magazine';
import LearnerMemberMyReport from './pages/trainee/member/myReport';
import LearnerMemberMySubscription from './pages/trainee/member/mySubscription';
import LearnerMemberMyNetwork from './pages/trainee/member/myNetwork';
import LearnerMemberMyCommission from './pages/trainee/member/myCommission';
import SearchController from './pages/trainee/searchGuru';

// Guru
import RegisterGuru from './pages/instructor/register';
import OTPVerificationGuru from './pages/instructor/otpVerification';
import PersonalInformationGuru from './pages/instructor/personalInformation';
import AddressInformationGuru from './pages/instructor/addressInformation';
import WorkExperienceGuru from './pages/instructor/workExperience'
import BankDetailsGuru from './pages/instructor/bankDetails';
import UploadAttachmentsGuru from './pages/instructor/uploadAttachments'
import ThankYouGuru from './pages/instructor/thankYouGuru'
import GuruDashboard from "./pages/instructor/dashboard";
import GuruMyVideo from "./pages/instructor/myVideo";
import GuruUploadVideo from './pages/instructor/uploadVideo'
import GuruUploadVideoSuccess from './pages/instructor/uploadVideoSuccess'
import GuruVideoGuideline from './pages/instructor/videoGuideline'
import GuruProfile from './pages/instructor/settings/profile';
import GuruEditProfile from './pages/instructor/settings/profile/editProfile'
import GuruSettingsBankDetails from './pages/instructor/settings/bankDetails';
import GuruSettingsEditBankDetails from './pages/instructor/settings/bankDetails/editBankDetails';
import GuruSettingsAccount from './pages/instructor/settings/account';
import GuruMonetizationMyReport from './pages/instructor/monetization/myReport';
import GuruMonetizationMyCommission from './pages/instructor/monetization/myCommission';


import ScrollToTop from "./components/scrolltotop";
import useHttpHandler from "./hooks/httphandler.hook";
import {setUserInfo} from "./store/reducers/app.reducer";
import useLogin from "./pages/authentication/login/hooks/login.hook";
import useHelper from "./hooks/helpers.hook";
import Loader from "./components/loader";
import {FREEMIUM, GURU, PREMIUM} from "./constants/app.constants";
import AboutUsPage from './pages/aboutUs';
import ContactUsPage from './pages/contactUs';
import PricingPage from './pages/pricing';
import ProductPage from './pages/newPages/productpage';
import CategeoryDetaisPage from './pages/newPages/instructorpages/categeoryDetails';
import UploadPage from './pages/newPages/instructorpages/uploadPage';
import InstructorDashboardpage from './pages/newPages/instructorpages/instructorDashboard';

import TableFormatPage from './pages/newPages/instructorpages/tableFormat';
import CreateCoursePage from './pages/instructor/createCourse';
import Modulespage from './pages/instructor/modulesContent';
import TopicPage from './pages/instructor/topicContent';
import TopicUploadpage from './pages/instructor/topicUpload';
import InstructorCoursesPage from './pages/trainee/instructorCourses';
import EditCoursePage from './pages/instructor/editCourse';
import ModuleUploadpage from './pages/instructor/moduleUpload';
import CourseContentPage from './pages/instructor/courseContent';
import CoursePage from './pages/trainee/courses';
import TopTenVideos from './pages/trainee/topTenVideos';
import TodayAddedVideosPage from './pages/trainee/todayAddedCourse';
import TrendingTopicPage from './pages/trainee/trendingTopics';
import FavouriteCoursePage from './pages/trainee/favouriteCourse';
import CourseDetailsPage from './pages/trainee/courseDetails';
import HomePage from './pages/Home';
import CategeoryPurchase from './pages/trainee/categeoryPurchase';
import SubCategeoryPurchase from './pages/trainee/subCategeoryPurchase';
import CategeoryPayment from './pages/trainee/categeoryPayment';
import SubscribeDetailInformation from './pages/trainee/subscribeDetailInformation';
import TraineeDashboard from './pages/trainee/dashboard';
import CategeoryPage from './pages/trainee/member/myCategeoryComission';
import ModuleCardEdit from './pages/instructor/moduleCardEdit';
import TopicCardEdit from './pages/instructor/topicCardEdit';
import CheckoutForm from './pages/trainee/stripe';
import CheckoutPage from './pages/trainee/stripe';
import CreateCourseStepBPage from './pages/instructor/createCourse/createeCourseStepb';
import FreeCourseContentPage from './pages/instructor/freeCourseContent';
import FreeCoursePage from './pages/trainee/courses/freeCourses';
import Register from './pages/authentication/register';
import WaitingListPage from './pages/waitingList';
import TotalLikes from './pages/instructor/totalLikes';
import TotalViews from './pages/instructor/totalViews';
import CoursePreview from './pages/trainee/coursePreview';
import HomeCoursePreview from './pages/Home/homeCoursePreview';
import AddQuizController from './pages/instructor/addQuiz';
import ChatWithInstructor from './pages/trainee/chatWithInstructor';
import Chatboard from './pages/instructor/chat';
import ChatTrainerboard from './pages/trainee/chat';

import useChatHook from './pages/trainee/chat/hooks/chat.hook';
import ChatHistory from './pages/trainee/chatWithInstructor/chatHistory';
import InstructorChatHistory from './pages/instructor/chat/instructorChatHistory';
import song from "./assets/notification.wav";
import { getFirebaseToken, onForegroundMessage } from './config/firebase';
import Text from './components/text';
import Button from './components/button';
import NotificationCard from './components/NotificationCard';
import MeetingPage from './pages/instructor/meetings';
import Wallet from './pages/trainee/member/Wallet';
import ChatDashboard from './pages/instructor/chat/chatDashboard';
import MeetingDashboard from './pages/instructor/meetings/meetingDashboard';
import ChatEndConversation from './pages/trainee/chat/chatEndConversation';
import LowBalanceView from './pages/trainee/chat/view/LowBalanceView';
import ChatLowBalance from './pages/trainee/chat/chatLowBalance';
import TotalMinutes from './pages/instructor/chat/dashboard/totalMinutes';
import EarningThisMonth from './pages/instructor/chat/dashboard/earningThisMonth';
import TotalEarnings from './pages/instructor/meetings/meetingTotalEarnings';
import InstructorWallet from './pages/instructor/chat/dashboard/instructorWallet';
import MeetingTotalEarnings from './pages/instructor/meetings/meetingTotalEarnings';
import MeetingList from './pages/instructor/meetings/meetingList';
import VideoConferenceMeetingPage from './pages/videoConference/VideoConferenceMeetingPage';
import VideoConference from './pages/instructor/meetings/videoConference';
import TrainerVideoConference from './pages/trainee/member/trainerVideoConference';
import CoursesDashboard from './pages/trainee/dashboard/coursesDashboard';
import InstructorsDashboard from './pages/trainee/dashboard/instructorsDashboard';
import UpcommingMeetings from './pages/trainee/instructors/upcommingMeetings';
import ScheduleMeeting from './pages/trainee/instructors/scheduleMeeting';
import MeetingHistory from './pages/trainee/instructors/meetingHistory';




function App() {
    
    const [loading, setLoading] = useState(true)
    const {post} = useHttpHandler()
    const {isGuruUser, isMember, hasToken, hasPermissions, updateUserMetaData} = useHelper()
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.app.userInfo)
    const {setDefaultLanguage} = useTranslations()
    const languageText = useSelector(state => state.app.languageText)
    const isMemberUser = isMember(FREEMIUM, PREMIUM)
    const isGuru = hasPermissions(GURU)
    const chatHook = useChatHook();
 
  //  

    
    const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');

    useEffect(() => {
      onForegroundMessage()
        .then((payload) => {
            console.log('Received foreground message: ',payload);
            let audio = new Audio(song);
            audio.play();
          
       
          toast(<ToastifyNotification title={payload.data.title} body={payload.data.data} />,{
            autoClose: 100000, // Set the duration to 3000 milliseconds (3 seconds)
          });
         
       //  
        })
        .catch(err => console.log('An error occured while retrieving foreground message. ', err));

       // handleGetFirebaseToken();
    }, []);
  
    const handleGetFirebaseToken = () => {
      getFirebaseToken()
        .then((firebaseToken) => {
          console.log('Firebase token: ', firebaseToken);
          if (firebaseToken) {
            setShowNotificationBanner(false);
          }
        })
        .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
    }
  
    const ToastifyNotification = ({ title, body }) => (
        <>
        <NotificationCard title={title} body={body}/>

       
     
      </>
    );
  

   //const socket = 
   //const isMemberUser = hasPermissions(FREEMIUM, PREMIUM)
   //const isGuru = hasPermissions(GURU)

   
   

    useEffect(() => {
        //setDefaultLanguage(MALAY)
        setDefaultLanguage(ENGLISH)
        if (hasToken()) {
            if (!userInfo) {
                post('getProfile').then((res) => {
                    setLoading(false)
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        
                        updateUserMetaData(data)
                    }
                }).catch(() => setLoading(false))
            }
        } else {
            setLoading(false)
        }

      
    }, [])
    if (loading) {
        return <Loader/>
    } else {
        return (
            <div className="App">
                <BrowserRouter>
                    <ToastContainer
                        autoClose={2000}
        />
                    <ScrollToTop/>
                    <Routes>
                        <Route path="/">
                            <Route index={true} element={<HomePage text={languageText}/>}/>

                            <Route path="referral/:referralCode" element={<ReferenceLink text={languageText}/>}/>

                            <Route path="/login" element={<Login text={languageText}/>}/>
                            <Route path="/register" element={<Register text={languageText}/>}/>
                            <Route path="/logout" element={<Logout text={languageText}/>}/>
                            <Route path="/resetPassword/:token" element={<ResetPassword text={languageText}/>}/>
                            <Route path="/setNewPassword" element={<Login text={languageText}/>}/>
                            <Route path="/forgotPassword" element={<Login text={languageText}/>}/>
                            <Route path="/trainee/signup" element={<RegisterLearner text={languageText}/>}/>
                            <Route path="/trainee/signup/socialsignup" element={<RegisterLearner text={languageText}/>}/>
                            <Route path="/ref-course/:id" element={<Login text={languageText}/>}/>
                            <Route path="/instructor/signup/" element={<RegisterGuru text={languageText}/>}/>
                            <Route path="/instructor/signup/socialsignup" element={<RegisterGuru text={languageText}/>}/>
                            <Route path="/course-preview/:courseId" element={<HomeCoursePreview text={languageText}/>}/>
                            <Route path="/course/:courseId" element={<HomeCoursePreview text={languageText}/>}/>
                            <Route element={<ProtectedRoute isLoggedIn={()=>{hasToken()}}/>}>
                                <Route path="/about" element={<About />}/>
                                 <Route path="/test" element={<About/>}/>
                            </Route>
                            <Route path="/about-us" element={<AboutUsPage text={languageText} />}/>
                            <Route path="/join-waiting-list" element={<WaitingListPage text={languageText} />}/>
                            <Route path="/pricing-page" element={<PricingPage text={languageText} />}/>
                            <Route path="/contact-us" element={<ContactUsPage text={languageText} />}/>

                            <Route path="/trainee">
                                <Route element={<ProtectedRoute isLoggedIn={isMemberUser}/>}>
                                <Route index={true} element={<RegisterLearner text={languageText}/>}/>
                                <Route path="otp-verification" element={<OTPVerification text={languageText}/>}/>
                                <Route path="subscribe" element={<Subscribe text={languageText}/>}/>
                                <Route path="purchase-categeory" element={<CategeoryPurchase  text={languageText}/>}/>
                                <Route path="purchase-sub-categeory/:id" element={<SubCategeoryPurchase text={languageText} />}/>
                                <Route path="subscribe-information" element={<SubscribeDetailInformation text={languageText} />}/>
                                <Route path="categeory-payment/:id" element={<CategeoryPayment text={languageText} />}/>
                                
                                <Route path="courses" element={<CoursesDashboard text={languageText} />}/>
                                
                                <Route path="courses">
                                         <Route path="paid-courses" element={<CoursePage text={languageText} />}/>
                                         <Route path="free-courses" element={<FreeCoursePage text={languageText} />}/>
                                         <Route path="today-added-courses" element={<TodayAddedVideosPage text={languageText} />}/>
                                         <Route path="favourite-course" element={<FavouriteCoursePage text={languageText} />}/>
                                         <Route path="trending-topics" element={<TrendingTopicPage text={languageText} />}/>
                                         <Route path="top-ten-videos" element={<TopTenVideos text={languageText} />}/>
                                        
                                </Route>


                                <Route path="instructors" element={<InstructorsDashboard text={languageText} />}/>

                                <Route path="instructor">
                                <Route path="chat-with-instructor" element={<ChatWithInstructor text={languageText} />} />
                                <Route path="chat-history" element={<ChatHistory text={languageText} />} />
                                <Route path="chat-instructor-details/:userId" element={<ChatTrainerboard text={languageText} />} />
                                <Route path="up-comming-meetings" element={<UpcommingMeetings text={languageText} />}/>
                                <Route path="schedule-meetings" element={<ScheduleMeeting text={languageText} />}/>
                                <Route path="meetings-history" element={<MeetingHistory text={languageText} />}/>
                                
                                
                                         <Route path="free-courses" element={<FreeCoursePage text={languageText} />}/>
                                         <Route path="today-added-courses" element={<TodayAddedVideosPage text={languageText} />}/>
                                         <Route path="favourite-course" element={<FavouriteCoursePage text={languageText} />}/>
                                         <Route path="trending-topics" element={<TrendingTopicPage text={languageText} />}/>
                                         <Route path="top-ten-videos" element={<TopTenVideos text={languageText} />}/>
                                </Route>
                                
                                {/*<Route path="courses" element={<CoursePage text={languageText} />}/> */}
                                
                               
                                <Route path="stripe-checkout" element={<CheckoutPage/> } />
                                <Route path="payment" element={<Payment text={languageText}/>}/>
                                <Route path="payment-package" element={<Payment text={languageText}/>}/>
                                <Route path="payment-status" element={<PaymentStatus text={languageText}/>}/>
                                <Route path="payment-success" element={<PaymentSuccess text={languageText}/>}/>
                                <Route path="payment-failed" element={<PaymentFailed text={languageText}/>}/>
                                <Route path="refer-friend" element={<ReferenceLink text={languageText}/>}/>
                              
                                <Route path="chat-conversation-end/:sessionId" element={<ChatEndConversation text={languageText} />} />
                                <Route path="low-balance" element={<ChatLowBalance text={languageText} />} />
                                <Route path="video-meeting" element={<TrainerVideoConference/>}/>
                              
                               {/** <Route path="survey-question-1" element={<SurveyQuestionOne text={languageText}/>}/>
                                <Route path="survey-question-2" element={<SurveyQuestionOne text={languageText}/>}/>
                                <Route path="survey-question-3" element={<SurveyQuestionOne text={languageText}/>}/> */}
                                <Route path="instructor-courses/:userId" element={<InstructorCoursesPage text={languageText} />}/>
                                <Route path="personal-information" element={<PersonalInformation text={languageText}/>}/>
                                <Route path="address-information" element={<AddressInformation text={languageText}/>}/>
                                <Route path="bank-details" element={<BankDetails text={languageText}/>}/>
                                <Route path="dashboard" element={<TraineeDashboard text={languageText}/>}/>
                                <Route path="learn-by-topics" element={<LearnerByTopics text={languageText}/>}/>
                                <Route path="course-details/:courseId" element={<CourseDetailsPage text={languageText}/>}/>
                                <Route path="video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="my-learning/video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="favourite-videos" element={<LearnerLikedVideo text={languageText}/>}/>
                                <Route path="favourite-instructor" element={<LearnerFavouriteGuru text={languageText}/>}/>
                                <Route path="favourite-instructor/favourite-instructor-details/all-videos/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                <Route path="favourite-instructor/favourite-instructor-details/newest/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                <Route path="favourite-instructor/favourite-instructor-details/top-viewed/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                <Route path="wallet" element={<Wallet text={languageText}/>}/>
                                
                                <Route path="member">
                                    <Route path="my-report" element={<LearnerMemberMyReport text={languageText}/>}/>
                                    <Route path="my-subscription" element={<LearnerMemberMySubscription text={languageText}/>}/>
                                    <Route path="my-network" element={<LearnerMemberMyNetwork text={languageText}/>}/>
                                    <Route path="my-commission" element={<LearnerMemberMyCommission text={languageText}/>}/>
                                    <Route path="categories-commission" element={<CategeoryPage text={languageText}/>}/>
                                </Route>
                                <Route path="settings">
                                    <Route path="profile" element={<LearnerProfile text={languageText}/>}/>
                                    <Route path="magazine" element={<LearnerMagazine text={languageText}/>}/>
                                    <Route path="profile/edit-profile" element={<LearnerEditProfile text={languageText}/>}/>
                                    <Route path="bank-details" element={<LearnerSettingsBankDetails text={languageText}/>}/>
                                    <Route path="bank-details/edit-bank-details" element={<LearnerSettingsEditBankDetails text={languageText}/>}/>
                                    <Route path="reset-password" element={<LearnerSettingsAccount text={languageText}/>}/>
                                </Route>
                                <Route path="search" element={<SearchController text={languageText}/>}/>
                            </Route>
                            </Route>
                            <Route path="/instructor">
                                <Route element={<ProtectedRoute isLoggedIn={isGuru}/>}>
                                <Route index={true} element={<RegisterGuru text={languageText}/>}/>
                                <Route path="otp-verification" element={<OTPVerificationGuru text={languageText}/>}/>
                                <Route path="course" element={<CourseContentPage text={languageText} />} />
                                <Route path="course-details/:courseId" element={<CourseDetailsPage text={languageText}/>}/>
                                <Route path="free-course" element={<FreeCourseContentPage text={languageText} />} />
                                <Route path="create-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="private-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="add-quiz/:courseId" element={<AddQuizController text={languageText} />}/>
                                <Route path="meetings" element={<MeetingDashboard text={languageText} />}/>
                                
                                <Route path="free-create-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="create-course-step-2" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="edit-course/:courseId" element={<EditCoursePage text={languageText} />}/>
                                <Route path="modules/:courseId" element={<Modulespage text={languageText} />}/>
                                <Route path="create-module/:courseId" element={<ModuleUploadpage text={languageText} />}/>
                                <Route path="edit-module/:courseId" element={<ModuleCardEdit text={languageText} />}/>
                                <Route path="topics/:moduleId" element={<TopicPage text={languageText} />}/>
                                <Route path="create-topic/:moduleId" element={<TopicUploadpage text={languageText} />}/>
                                <Route path="edit-topic/:moduleId" element={<TopicCardEdit text={languageText} />}/>
                                <Route path="personal-information" element={<PersonalInformationGuru text={languageText}/>}/>
                                <Route path="address-information" element={<AddressInformationGuru text={languageText}/>}/>
                                <Route path="work-experience" element={<WorkExperienceGuru text={languageText}/>}/>
                                <Route path="bank-details" element={<BankDetailsGuru text={languageText}/>}/>
                                <Route path="upload-attachments" element={<UploadAttachmentsGuru text={languageText}/>}/>
                                <Route path="thankyou" element={<ThankYouGuru text={languageText}/>}/>
                                <Route path="dashboard" element={<GuruDashboard text={languageText}/>}/>
                                <Route path="chat-board/:presentRoomName" element={<Chatboard text={languageText}/>}/>

                                <Route path="chat" element={<ChatDashboard text={languageText} />} />
                                <Route path="chat-dashboard">
                                    <Route path="total-minutes" element={<TotalMinutes text={languageText}/>}/>
                                    <Route path="earning-month" element={<EarningThisMonth   text={languageText}/>}/>
                                    <Route path="total-earnings" element={<TotalEarnings text={languageText}/>}/>
                                    <Route path="instructor-wallet" element={<InstructorWallet text={languageText}/>}/>
                                </Route>
                                <Route path="meeting">
                                    <Route path="create-meeting" element={<MeetingPage text={languageText} />}/>
                                    <Route path="video-meeting" element={<VideoConference/>}/>
                                    <Route path="meeting-list" element={<MeetingList text={languageText}/>}/>
                                    <Route path="earning-month" element={<EarningThisMonth   text={languageText}/>}/>
                                    <Route path="total-earnings" element={<MeetingTotalEarnings text={languageText}/>}/>
                                    
                                </Route>

                                <Route path="chat-history" element={<InstructorChatHistory text={languageText}/>}/>
                                <Route path="chat-conversation-end/:sessionId" element={<ChatEndConversation text={languageText} />} />
                                <Route path="my-video" element={<GuruMyVideo text={languageText}/>}/>
                                <Route path="upload-video" element={<GuruUploadVideo text={languageText}/>}/>
                                <Route path="video-details/:videoId" element={<GuruUploadVideoSuccess text={languageText}/>}/>
                                <Route path="course-module-video/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="course-video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="course-preview/:courseId" element={<CoursePreview text={languageText}/>}/>
                                <Route path="video-guideline" element={<GuruVideoGuideline text={languageText}/>}/>
                                <Route path="monetization">
                                    <Route path="my-report" element={<GuruMonetizationMyReport text={languageText}/>}/>
                                    <Route path="my-commission" element={<GuruMonetizationMyCommission text={languageText}/>}/>
                                </Route>
                                <Route path="dashboard">
                                    <Route path="total-likes" element={<TotalLikes text={languageText}/>}/>
                                    <Route path="total-views" element={<TotalViews   text={languageText}/>}/>
                                    <Route path="total-commission" element={<GuruMonetizationMyCommission text={languageText}/>}/>
                                </Route>
                                <Route path="settings">
                                    <Route path="profile" element={<GuruProfile text={languageText}/>}/>
                                    <Route path="profile/edit-profile" element={<GuruEditProfile text={languageText}/>}/>
                                    <Route path="bank-details" element={<GuruSettingsBankDetails text={languageText}/>}/>
                                    <Route path="bank-details/edit-bank-details" element={<GuruSettingsEditBankDetails text={languageText}/>}/>
                                    <Route path="account" element={<GuruSettingsAccount text={languageText}/>}/>
                                </Route>
                            </Route>
                            </Route>
                            <Route path='/product-page' element={<ProductPage text={languageText} />} />
                                <Route path='categeory-details' element={<CategeoryDetaisPage text={languageText} />} />
                                <Route path='upload-page' element={<UploadPage text={languageText} />} />
                                <Route path='instructor-dashboard-page' element={<InstructorDashboardpage text={languageText} />} />
                                <Route path='/table-page' element={<TableFormatPage text={languageText} />} />                               
                            <Route path="*" element={<p>There's nothing here: 404!</p>}/>
                        </Route>
                    </Routes>
                </BrowserRouter>
            </div>
        );
    }
}

export default App;
