import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import Breadcrumbs from "../breadcrumbs";
import Button from "../button";
import FlowCard from "../flowCard";
import Image from "../image";
import Input from "../input";
import Select from "../input/select";
import Textarea from "../input/textarea";
import ProgressBarCard from "../programBarCard";
import Text from "../text";
import { UploadComponent } from "../../pages/instructor/uploadAttachments/view/UploadAttachmentsView";
import binImg from "../../assets/img/Bin.png";
import useHttpHandler from "../../hooks/httphandler.hook";
import AvatarPic from "../../assets/images/avatar_pic.jpg";
import axios from "axios";
import ModuleCard from "../moduleCard";
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE } from "../../constants/app.constants";
import useHelper from "../../hooks/helpers.hook";
import useGuruUploadVideo from "../../pages/instructor/uploadVideo/hooks/guruUploadVideo.hook";
import { toast } from "react-toastify";
import Modal from "../modal";
import VideoPlayer from "../videoplayer";
import Icon from "../icon";
import TickIcon from "../svg/tickIcon";
import { useDropzone } from "react-dropzone";
import CameraIcon from "../svg/cameraIcon";
import { Result } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import type { UploadProps } from "antd";
import { Upload } from "antd";
import type { UploadFile } from "antd/es/upload/interface";
import ThumbNailUpload from "./videoUpload";
import PdfUpload from "./pdfUpload";
import VideoUpload from "./videoUpload";
import UploadVideoPreview from "./videoUploadPreview";
import Flex from "../flex";
import downloadImg from "../../assets/img/download.png";
import { Badge } from "react-bootstrap";

const AddTopicCompnent = (props) => {
  const guruUploadVideoHook = useGuruUploadVideo();
  const [progress, setProgress] = useState(null);
  const { courseId } = useParams();
  const navigate = useNavigate();
  const { videoId } = useParams();
  const { post } = useHttpHandler();
  const { getFileExtension, getFileSize } = useHelper();
  const { goToUploadSuccess, goToGuruVideoGuideline } = useGuruUploadVideo();
  const location = useLocation();
  const [isModal, setIsModal] = useState(false);
  const [isSucess, setSucess] = useState(false);
  const [showVideoEnded, setShowVideoEnded] = useState(false);
  const [videoSampleFile, setvideoSampleFile] = useState([]);

  const [videoDetails, setVideoDetails] = useState(null);
  const toggleIsModal = (video) => {
    console.log("video", video);
    setVideoDetails(video[0]);

    setIsModal((state) => !state);
  };

  const handleDownload = (blob, filename) => {
    if (!window) {
      return;
    }
    const blobUrl = blob;
    const anchor = window.document.createElement("a");
    anchor.download = filename;
    anchor.href = blobUrl;
    anchor.click();
    window.URL.revokeObjectURL(blobUrl, "_blank");
  };

  const {
    dropdownsData,
    uploadedImages,
    setUploadedImages,
    pdfuploadedImages,
    pdfsetUploadedImages,
    userInfo,
    uploadedVideosInfo,
    setUploadedVideosInfo,
    isUploading,
    setIsUploading,
    getVideoId,
    setVideoId,
  } = guruUploadVideoHook;

  const formik = useFormik({
    initialValues: {
      title: "",
      description: /*videoDetails[0]?.des ? videoDetails[0]?.des*/ "",
      sampleFile: [],
      pdfsampleFile: [],
      videosampleFile: videoSampleFile,
    },
    validationSchema: Yup.object({
      /*   
            title: Yup.string().required('Please enter a valid uname'),
            description: Yup.string().required('Please enter a valid description'), 
            videosampleFile:Yup.array().min(1,'Please Upload Video File').required("Please Upload Video File"), 
            prioritylevel: Yup.string().required('Please enter a valid priority level'),
            sampleFile:Yup.array().min(1,'Please Upload thumbnail').required("Please Upload thumbnail"),
            pdfsampleFile:Yup.array().min(1,'Please Upload PDF Document').required("Please Upload PDF Document"),**/

      title: Yup.string().required("Please enter a valid Title"),
      description: Yup.string().required("Please enter a valid description"),
      videosampleFile: Yup.array()
        .min(1, "Please Upload Video File")
        .required("Please Upload Video File"),
    }),
    onSubmit: (values, actions) => {
      console.log("values", values, props.selectedData.srno, uploadedImages);
      // setSucess(true);

      actions.setSubmitting(false);

      var thumbnail = "";

      if (uploadedImages == null) {
        thumbnail = uploadedVideosInfo[0].thumbnail;
      } else {
        thumbnail = uploadedImages[0].newfile_name;
      }

      var attachment = "";

      if (pdfuploadedImages != null) {
        attachment = pdfuploadedImages[0].newfile_name;
      }

      let postData = {
        moduleId: props.selectedData.srno,
        title: values.title,
        categoryId: 1,
        subCategoryId: 1,
        studylevelid: 1,
        description: values.description,
        videoId: getVideoId,
        thumbNail1: thumbnail,
        languageid: 1,
        view: 8001,
        section: 1,
        isguidelist: 1,
        priority: 1,
        attachment: attachment,
        oldid: 0,
      };

      post("addVideoDetails", postData)
        .then((res) => {
          actions.setSubmitting(false);
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            props?.setisShowPopUP(false);
            props.setisDeletePopUP(true);
            props.setIsPopType("SucessAddedLesson");
          }
        })
        .catch((err) => {
          actions.setSubmitting(false);
        });
    },
  });

  const testobj = {
    "image/*": [".png", ".jpeg", ".jpg"],
    "video/*": [".mp4", ".mov"],
  };

  useEffect(() => {
    if (formik.values.sampleFile.length > 0) {
      let formData = new FormData();
      formik?.values?.sampleFile?.map((file, index) => {
        formData.append("files", file);
      });

      const apiUrl = process.env.REACT_APP_API_URL + "/instructorUpload";

      axios
        .post(apiUrl, formData, {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            setUploadedImages(data);
          }
        })
        .catch((error) => {});
    }
  }, [formik.values.sampleFile]);

  useEffect(() => {
    console.log("uploadedVideosInfo_2", formik.values.pdfsampleFile);
    if (formik.values.pdfsampleFile.length > 0) {
      let formData = new FormData();
      formik?.values?.pdfsampleFile?.map((pdffile, index) => {
        formData.append("files", pdffile);
      });

      const apiUrl = process.env.REACT_APP_API_URL + "/instructorUpload";

      axios
        .post(apiUrl, formData, {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          const status = res.data.status;
          const data = res.data.data;
          if (status.result == "200") {
            pdfsetUploadedImages(data);
          }
        })

        .catch((error) => {});
    }
  }, [formik.values.pdfsampleFile]);

  const onChangeText = (event) => {
    //if(event.target.value.length <=500){

    const stg = event.target.value.substring(0, 500);
    formik.setFieldValue("description", stg);
    handleChange(event);
    //}
    //formik.setFieldValue("description",e.target.text);
  };

  const [inputText, setInputText] = useState("");
  const [characterLimit] = useState(500);
  // event handler
  const handleChange = (event) => {
    const stg = event.target.value.substring(0, 500);

    setInputText(stg);
  };

  return (
    <div>
      {isSucess && (
        <Result
          status="success"
          title="Successfully Added Lesson"
          subTitle=""
          width={300}
          extra={[
            <Button
              type="primary"
              key="console"
              text="Done"
              onClick={() => {
                console.log("close");

                props?.setCustomData(null);
                props.setLevel(true);
              }}
            />,
          ]}
        />
      )}
      {!isSucess && (
        <form onSubmit={formik.handleSubmit}>
          <b>Add a Lesson for your course</b>
          <div className="card border-radius-5 pad-10 ">
            <div className="row">
              <div className="col-12">
                <div className="formBox01">
                  <Input
                    star={true}
                    autoFocus={true}
                    type="text"
                    name="title"
                    placeholder="Title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    mandatory
                    errormessage={
                      formik.touched.title && formik.errors.title
                        ? formik.errors.title
                        : null
                    }
                  />
                </div>

                <div className="formBox01">
                  <Textarea
                    tertiary
                    label="Description"
                    type="text"
                    name="description"
                    placeholder="Write your description here..."
                    value={formik.values.description}
                    onChange={(event) => {
                      onChangeText(event);
                    }}
                    onBlur={formik.handleBlur}
                    mandatory
                    rows="5"
                    errormessage={
                      formik.touched.description && formik.errors.description
                        ? formik.errors.description
                        : null
                    }
                  ></Textarea>

                  <Badge
                    style={{ marginTop: -10 }}
                    bg={`${
                      inputText.length > characterLimit ? "danger" : "primary"
                    }`}
                  >
                    {inputText.length}/{characterLimit}
                  </Badge>
                </div>
              </div>

              <div className="col-12 col-md-4 mtb-10">
                <div className="card border-radius-5  h-100 p-3">
                  <div className="row">
                    <div className='="col-12 '>
                      <div className="formBox01">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -1"
                          />

                          <label className="form-label min-h-50">
                            Upload a video thumbnail for your Lesson video{" "}
                          </label>
                        </div>

                        <div className="col-12 create_course_image">
                          <UploadComponent
                            setFieldValue={formik.setFieldValue}
                            maxFilesUpload="3"
                            hideFooter
                            hideButton
                            thumbNailWrap
                            thumb="Upload Thumbnail"
                            fieldName="sampleFile"
                            accpetFileType={IMAGE_FILE_TYPE}
                            uploadedImages={uploadedImages}
                          />
                          {formik.touched.sampleFile &&
                          formik.errors.sampleFile ? (
                            <div className="message-error">
                              <small>{formik.errors.sampleFile}</small>
                            </div>
                          ) : null}

                          {/*
                                        uploadedImages && uploadedImages.length > 0 &&
                                        uploadedImages?.map((file, i) => (
                                            <div key={i} className="col-12 ">
                                                {file?.newfile_name && (
                                                    <img src={process.env.REACT_APP_IMG_URL + file?.newfile_name} alt="" className='course-upload-wrap' style={{ marginTop:"-4px" }} />      
                                                )}
                                            </div>
                                        ))
                                        */}
                        </div>

                        {!uploadedImages && (
                          <>
                            <div className="col-12 col-md-4 col-xs-12 ">
                              <div className="thumbimgempty"></div>
                            </div>
                          </>
                        )}

                        <Text
                          type="PARAGRAPH"
                          className="text-black-half my-2 mt-20"
                          text="This image will displayed for category when your members view their library."
                        />

                        <Text
                          type="PARAGRAPH"
                          className="text-black-half my-0"
                          text="Recommended dimensions of"
                        />

                        <Text
                          type="PARAGRAPH"
                          className="text-black my-0"
                          text="1024x720"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mtb-10">
                <div className="card border-radius-5  h-100 p-3">
                  <div className="row">
                    <div className='="col-12'>
                      <div className="formBox01">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -2"
                          />

                          <label className="form-label min-h-50">
                            Upload your Lesson video
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12 col-md-12 ">
                      <UploadVideoPreview
                        setFieldValue={formik.setFieldValue}
                        text="Drag and Drop Files"
                        setUploadedFilesInfo={setUploadedVideosInfo}
                        setIsUploading={setIsUploading}
                        fieldName="videosampleFile"
                        istopicVideo="yes"
                        setVideoId={setVideoId}
                      />

                      {formik.touched.videosampleFile &&
                      formik.errors.videosampleFile ? (
                        <div className="message-error">
                          <small>{formik.errors.videosampleFile}</small>
                        </div>
                      ) : null}
                    </div>
                    <Text
                      type="PARAGRAPH"
                      className="my-0 font-weight-500 pt-4"
                      text="Upload your video here, can drop or browse the file."
                    />

                    <Text
                      type="PARAGRAPH"
                      className="text-black-half my-0"
                      text="Format: MP4,MPEG"
                    />

                    {uploadedVideosInfo && (
                      <>
                        <div className="row mt-20">
                          <div className="col-md-2 col-xs-12">
                            <Icon
                              icon={<TickIcon width="24" height="24" />}
                              success
                            />
                          </div>
                          <div className="col-12 col-md-4 col-xs-12">
                            <Text
                              type="PARAGRAPH"
                              className="font-weight-500 text-black-half"
                              text="Upload Successful"
                            />
                          </div>

                          <div className="col-12 col-md-6 col-xs-12">
                            <Button
                              type="button"
                              text="Play Video"
                              isSubmitting={isUploading}
                              onClick={() => toggleIsModal(uploadedVideosInfo)}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-4 mtb-10">
                <div className="card border-radius-5  h-100 p-3">
                  <div className="row">
                    <div className='="col-12'>
                      <div className="formBo01">
                        <div className="form-field mb-10">
                          <Text
                            type="H5"
                            className="font-weight-500 mb-24"
                            text="Step -3"
                          />

                          <label className="form-label min-h-50">
                            Upload any accompanying documentation (optional){" "}
                          </label>
                        </div>

                        <div className="form-field form-field-tertiary">
                          {
                            <div className="row">
                              {pdfuploadedImages == null && (
                                <div className="col-12 col-md-12">
                                  <UploadComponent
                                    setFieldValue={formik.setFieldValue}
                                    maxFilesUpload="3"
                                    hideFooter
                                    hideButton
                                    thumbNailWrap
                                    thumb="Upload PDF"
                                    fieldName="pdfsampleFile"
                                    accpetFileType={DOC_FILE_TYPE}
                                  />
                                  {formik.touched.pdfsampleFile &&
                                  formik.errors.pdfsampleFile ? (
                                    <div className="message-error">
                                      <small>
                                        {formik.errors.pdfsampleFile}
                                      </small>
                                    </div>
                                  ) : null}
                                </div>
                              )}

                              <Text
                                type="PARAGRAPH"
                                className="text-white-half mt-20"
                                text="Upload your Document here. Drag and drop or browse the file"
                              />

                              {pdfuploadedImages &&
                                pdfuploadedImages.length > 0 &&
                                pdfuploadedImages?.map((pdffile, i) => (
                                  <div key={i} className="col-12 col-md-12">
                                    {pdffile?.newfile_name && (
                                      <>
                                        <Text
                                          type="H5"
                                          className="font-weight-500 mb-24"
                                          text={
                                            process.env.REACT_APP_IMG_URL +
                                            pdffile?.newfile_name
                                          }
                                        />
                                        <div>
                                          <Icon
                                            icon={
                                              <TickIcon
                                                width="24"
                                                height="24"
                                              />
                                            }
                                            success
                                          />

                                          <Button
                                            link
                                            text={
                                              <img
                                                src={binImg}
                                                alt="copy"
                                                className="icon-50"
                                              />
                                            }
                                            style={{
                                              margin: "0px",
                                              padding: "1px",
                                            }}
                                            onClick={() =>
                                              pdfsetUploadedImages(null)
                                            }
                                            type="button"
                                          />

                                          <Button
                                            link
                                            text={
                                              <img
                                                src={downloadImg}
                                                alt="copy"
                                                className="icon-50"
                                              />
                                            }
                                            style={{
                                              margin: "0px",
                                              padding: "1px",
                                            }}
                                            onClick={() =>
                                              handleDownload(
                                                process.env.REACT_APP_IMG_URL +
                                                  pdffile?.newfile_name,
                                                pdffile?.newfile_name
                                              )
                                            }
                                            type="button"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                ))}
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="action-buttons-end my-2">
            <Button
              type="button"
              text="cancel"
              onClick={() => {
                props.setisShowPopUP(false);
              }}
            />
            <Button
              outline
              type="submit"
              text="save"
              disabled={formik.isSubmitting}
              isSubmitting={formik.isSubmitting}
            />
          </div>
        </form>
      )}

      {isModal && (
        <>
          <Modal
            onClose={() => setIsModal(false)}
            onOuterClose={() => setIsModal(false)}
            modalContent={
              <>
                <VideoPlayer
                  videourl={
                    process.env.REACT_APP_IMG_URL + videoDetails.newfile_name
                  }
                  width="100%"
                  height="460"
                />
              </>
            }
          />
        </>
      )}
    </div>
  );
};

export default AddTopicCompnent;
