import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import useHelper from "../../../hooks/helpers.hook";
import {useNavigate} from "react-router-dom";

const useSubscribePremiumModal = () => {
    const userInfo = useSelector(state => state?.app?.userInfo)
    const {isFreemiumMember} = useHelper()
    const [isPremiumSubscribeModal, setIsPremiumSubscribeModal ] = useState(false);
    const [isPaymentUnderProcess, setIsPaymentUnderProcess ] = useState(false);
    const [isProfileInComplete, setIsProfileInComplete ] = useState(!userInfo?.is_profile_completed);
    const navigate = useNavigate()


    const goToPayment = ()=>{
        navigate('/trainee/purchase-categeory')
    }
    useEffect(()=>{

        if(isFreemiumMember(userInfo)) {
            if(userInfo?.premium_count == 0) {
                setIsPremiumSubscribeModal(true)
            } else {
                setIsPaymentUnderProcess(true)
            }
        }
    },[])
    return {
        isFreemium: isFreemiumMember(userInfo),
        isPremiumSubscribeModal,
        setIsPremiumSubscribeModal,
        isPaymentUnderProcess,
        setIsPaymentUnderProcess,
        goToPayment,
        isProfileInComplete,
        setIsProfileInComplete
    }
}
export default useSubscribePremiumModal
