import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate, useParams} from "react-router-dom";

import {useSelector} from "react-redux";
import ChatSwitchButton from '../../../../components/ChatswitchButton';

const InstructorsDashboardView = (props) => {
    
    const { goToLearnerSignup} = props
    const {getYearOptions,post} = useHelper()
    const viewsOption = [{id: 'views', value: 'Views'}, {id: 'likes', value: 'Likes'},]
    const [viewsSelected, setViewsSelected] = useState(viewsOption[0]);
    const yearsOption = getYearOptions()
    const [yearSelected, setYearSelected] = useState(yearsOption.at(-1));
    const {dashboardDetails} = {}
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()
    const {text} =props
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(false);
    const [isShareModal, setIsShareModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const [isVideoModal, setIsVideoModal] = useState(false);
    const{presentRoomName} = useParams();


   

  


    return (
        <>
            
            <div className="row top-main-boxes course-main-box"  >

                  <div className="row all-main-box"  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>
                      
                         

                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="Chat With Instructor"
                                 
                                  onClick={() => {
                                    navigate('/trainee/instructor/chat-with-instructor')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="Chat History"
                                  onClick={() => {
                                      navigate('/trainee/instructor/chat-history')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="Schedule Meeting"
                                  onClick={() => {
                                      navigate('/trainee/instructor/schedule-meetings')
                                  }}
                                  {...props}
                              />
                          </div>
                        

                          <div className="col-12 col-md-3">
                              <DashGuruStatus
                                  title="Upcomming Meetings"
                                  
                                  onClick={() => {
                                      navigate('/trainee/instructor/up-comming-meetings')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3 mt-20">
                              <DashGuruStatus
                                  title="Meeting History"
                                  
                                  onClick={() => {
                                      navigate('/trainee/instructor/meetings-history')
                                  }}
                                  {...props}
                              />
                          </div>


                          <div className="col-12 col-md-3 mt-20">
                              <DashGuruStatus
                                  title="Favourite Instrutor"
                                  
                                  onClick={() => {
                                      navigate('/trainee/courses/paid-courses')
                                  }}
                                  {...props}
                              />
                          </div>




                   


                   

                 
                      </div>            
                      </div>    
                    

                      
   
         
  
  
             
            {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
          


       
           

                                 


                    
       
        </>
    );
};


export default InstructorsDashboardView;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}

const DashGuruStatusTitle = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text={props.buttonTitle}  onClick={props.onClick}/>
        </div>
    )
}