import React, { useState } from "react";
import "../courseDetails/courseDetail.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import { toast } from "react-toastify";
import SelectNew from "../input/selectnew";
import { DEFAULT_FILTERS } from "../../constants/app.constants";
import Input from "../input";
import Button from "../button";

const CourseSearch = (props) => {
  console.log("commonHook.getCategoryId-->", props._props.getCategoryId);
  const navigate = useNavigate();
  const { post } = useHttpHandler();
  const formik = useFormik({
    initialValues: {
      category: props._props.getCategoryId,
      subcategory: props._props.getSubCategoryId,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter a valid Categeory"),
      subcategory: Yup.string().required("Please enter a valid Sub Categeory"),
    }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      let postData = {
        category: values.category,
        subcategory: parseInt(values.subcategory),
      };

      console.log("postData", postData);
    },
  });

  const setSubcategorys = (e) => {
    props?._props.setSubCategoryId(e.value);
    //formik.setFieldValue('subcategory', e.value)
    //getData("subCategory",e.value);
  };

  const getSubCategories = (e) => {
    console.log("props", props?.setTableData);
    props?._props.setTableData([]);
    props?._props.setCategoryId(e.value);
    getSubCategoriesById(e.value);
    // getData("category",e.value);
  };

  const getSubCategoriesById = (catId) => {
    let subCategoryOptions = [];
    if (props?.dropdownsData?.subCategories) {
      props?.dropdownsData?.subCategories.filter((item, index) => {
        if (item.parent_id == catId) {
          subCategoryOptions.push({
            value: item.subcatId,
            label: item.subCatName,
            text: item.subCatName,
          });
        }
      });
    }
    console.log(
      "catId",
      catId,
      subCategoryOptions,
      props?.dropdownsData?.subCategoriesOptions
    );
    props._props?.setSubCategories(subCategoryOptions);
  };

  return (
    <>
     
        <div className="row shadow bg-body rounded py-3 mb-3">
         
          <div className="col-lg-4 col-md-2 col-sm-12 ">
            <div className="">
              <form onSubmit={formik.handleSubmit}>
                <div className="">
                  <SelectNew
                    tertiary
                    label="Category"
                    name="category"
                    placeholder="Select Category"
                    value={formik.values.category}
                    onChange={getSubCategories}
                    onBlur={formik.handleBlur}
                    mandatory
                    options={props?.dropdownsData?.categoriesOptions}
                    errormessage={
                      formik.touched.category && formik.errors.category
                        ? formik.errors.category
                        : null
                    }
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="">
              <form onSubmit={formik.handleSubmit}>
                <div className="">
                  <SelectNew
                    tertiary
                    label="Sub Category"
                    name="subcategory"
                    placeholder="Select Sub Category"
                    value={formik.values.subcategory}
                    onChange={setSubcategorys}
                    onBlur={formik.handleBlur}
                    mandatory
                    options={props._props?.subCategories}
                    errormessage={
                      formik.touched.subcategory && formik.errors.subcategory
                        ? formik.errors.subcategory
                        : null
                    }
                  />
                </div>
              </form>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-8">
                <Input
                  className="lineBottom"
                  formControlClassName="form-control"
                  label="Search With Name"
                  labelClass="mb-2"
                  type="text"
                  name="uname"
                  placeholder="Search with name"
                  autoFocus={true}
                  value={formik.values.uname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  mandatory
                  errormessage={
                    formik.touched.uname && formik.errors.uname
                      ? formik.errors.uname
                      : null
                  }
                />
              </div>

              <div className="col-lg-4 col-md-4 mt-30">
                <Button
                  type="button"
                  outline
                  small-out
                  text="Search"
                  onClick={() => {
                    console.log("uname0------>", formik.values.uname);
                    if (formik.values.category == "") {
                      props._props.setFilters("0", 1, formik.values.uname);
                    } else {
                      props._props.setFilters(
                        formik.values.category,
                        1,
                        formik.values.uname
                      );
                    }
                  }}
                />
              </div>
            </div>
          </div>         

          </div>
       
  
    </>
  );
};

export default CourseSearch;
