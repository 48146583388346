import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import * as Yup from "yup";
import "../../createCourse/css/createCourse.scss"
import Breadcrumbs from '../../../../components/breadcrumbs';
import Button from '../../../../components/button';
import FlowCard from '../../../../components/flowCard';
import Image from '../../../../components/image';
import Input from '../../../../components/input';
import AvatarPic from '../../../../assets/images/avatar_pic.jpg';
import Select from '../../../../components/input/select';
import Textarea from '../../../../components/input/textarea';
import ProgressBarCard from '../../../../components/programBarCard';
import Text from '../../../../components/text';

import CourseImage from '../../../../assets/img/logo.svg';
import useHttpHandler from '../../../../hooks/httphandler.hook';
import useHelper from "../../../../hooks/helpers.hook";
import axios from "axios";
import useGuruUploadVideo from '../../uploadVideo/hooks/guruUploadVideo.hook';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../../../constants/app.constants';
import Flex from '../../../../components/flex';
import InputFile from '../../../../components/input/inputFile';
import Icon from '../../../../components/icon';
import TickIcon from '../../../../components/svg/tickIcon';
import CameraIcon from '../../../../components/svg/cameraIcon';
import ProgramBarSecondaryCard from '../../../../components/programBarCard/programBarSecondaryCard';
import Modal from '../../../../components/modal'
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import VideoPlayer from "../../../../components/videoplayer";
import TrainerImg from '../../../../assets/img/logo.svg';
import binImg from '../../../../assets/img/Bin.png'
import {toast} from "react-toastify";
import { UploadComponent } from '../../uploadAttachments/view/UploadAttachmentsView';
import UploadVideoPreview from '../../../../components/addTopicCompnent/videoUploadPreview';
import EditUploadVideoPreview from '../../../../components/addTopicCompnent/EditVideoUploadPreview';
const EditCourse = (props) => {
    //const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    const {videoId} = useParams();
    const {post} = useHttpHandler();
    const {getFileExtension, getFileSize} = useHelper()
    const {goToUploadSuccess, goToGuruVideoGuideline} = useGuruUploadVideo();
    const location = useLocation();
    const [isModal, setIsModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
  
    const toggleIsModal = (video) => {
        console.log("video",video)
        setVideoDetails(video);
        setIsModal(state => !state);
    }
    
      const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/instructor/dashboard',
        },
        
        {
            text:"Course",
            link:"/instructor/course",
        },
        {
            text: 'Create Course',
            link: '/instructor/create-course',
        },
    ]


    const {
        dropdownsData,
        uploadedImages,
        setUploadedImages,
        pdfuploadedImages,
        pdfsetUploadedImages,
        userInfo,
        uploadedVideosInfo,
        setUploadedVideosInfo,
        isUploading,
        setIsUploading,
    } = props?.guruUploadVideoHook


    const {
        
        arrangeImages,
        uploadVideo,
      
        
    } = props

    

    const [subCategories, setSubCategories] = useState(dropdownsData?.subCategoriesOptions)
    //var videoSampleFile = [{"fieldname":"file","originalname":"HTML-Paragraphs-YouTube.mp4","encoding":"7bit","mimetype":"video/mp4","destination":"/Volumes/Untitled/Projects_2023/CourseTsunami/CouseTSunamiNode/uploads/","filename":"HTML-Paragraphs-YouTube.mp4","path":"/Volumes/Untitled/Projects_2023/CourseTsunami/CouseTSunamiNode/uploads/HTML-Paragraphs-YouTube.mp4","size":1009792,"newfile_name":"HTMLParagraphsYouTube_1688221251493.mp4","thumbnail":"HTMLParagraphsYouTube_1688221251493_thumb.png","duration":34"}];

    var videoSampleFile =   [{
        "newfile_name":props.guruUploadVideoHook.serviceData.videofilename,
        "filename": "responseData.data.data[0].newfile_name",
        "file_size":"responseData.data.data[0].newfile_name",
        "file_type": "responseData.data.data[0].newfile_name",
        "thumbnail": "responseData.data.data[0].newfile_name",
        "duration": "responseData.data.data[0].newfile_name",
        "thumbnail":props.guruUploadVideoHook.serviceData.thumbnail,"filename":props.guruUploadVideoHook.serviceData.videofilename,"newfile_name":props.guruUploadVideoHook.serviceData.videofilename
        }
    ];
    
    const previewProfileImage = userInfo?.pic ? process.env.REACT_APP_IMG_URL + userInfo?.pic : AvatarPic
    
    

    const formik = useFormik({
        initialValues: {
            title: props.guruUploadVideoHook.serviceData.title,
            category: props.guruUploadVideoHook.serviceData.category,
            subcategory:  props.guruUploadVideoHook.serviceData.subcategory,
            description: props.guruUploadVideoHook.serviceData.des,
            price: props.guruUploadVideoHook.serviceData.price,
            sampleFile: [],
            pdfsampleFile: [],
            videosampleFile:videoSampleFile,
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Please enter a valid Categeory'),
            price: Yup.string().required('Please enter a valid Price'),
            subcategory: Yup.string().required('Please enter a valid Sub Categeory'),
            title: Yup.string().required('Please enter a valid title'),
            videotopicid: Yup.string('Please enter a valid subtopic list'),
            subtopicid: Yup.string('Please enter a valid subtopic list'),
            sampleFile:Yup.array().min(1,'Please Upload thumbnail').required("Please Upload thumbnail"),
            videosampleFile:Yup.array().min(1,'Please Upload Video File').required("Please Upload Video File"), 
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(false)

            var videoFile = videoSampleFile[0].newfile_name;

            if(uploadedVideosInfo != null){

                if(videoSampleFile[0].newfile_name != uploadedVideosInfo[0].newfile_name){
                    videoFile = uploadedVideosInfo[0].newfile_name;
                }
            }
            
            
            let postData = {
                coursetitle: values.title,
                category: values.category,
                subcategory: parseInt(values.subcategory),
                desc: values.description,
                video_id: videoId,
                thumbnail: uploadedImages && uploadedImages[0]?.newfile_name ? uploadedImages[0]?.newfile_name : '',
                attachment: pdfuploadedImages && pdfuploadedImages[0]?.newfile_name ? pdfuploadedImages[0]?.newfile_name : '',
                //coursevideo: uploadedVideosInfo && uploadedVideosInfo?.newfile_name ? uploadedVideosInfo?.newfile_name : '',
                coursevideo:videoFile,
                courseId:props.courseId,
                price:values.price
                
            }

              console.log("values",postData,uploadedVideosInfo);
            
                post('instructorEditCourse ', postData).then((res) => {
                    actions.setSubmitting(false)
                    const status = res.data.status
                    const data = res.data.data
                    console.log("Status",status);
                    if (status.result === '200') {
                        toast.success("status.msg")
                        navigate('/instructor/course')
                        
                    }
                }).catch(err => {
                    actions.setSubmitting(false)
                })
            
            

        },
    });
    
    
    if(props.guruUploadVideoHook.getCheck){
        setUploadedImages([{"fieldname":"files","size":275059,"newfile_name":props.guruUploadVideoHook.serviceData.thumbnail,"filename":props.guruUploadVideoHook.serviceData.introvideo}]);
        setUploadedVideosInfo([{"newfile_name":props.guruUploadVideoHook.serviceData.videofilename,
        "filename": "responseData.data.data[0].newfile_name",
        "file_size":"responseData.data.data[0].newfile_name",
        "file_type": "responseData.data.data[0].newfile_name",
        "thumbnail": "responseData.data.data[0].newfile_name",
        "duration": "responseData.data.data[0].newfile_name",
        "thumbnail":props.guruUploadVideoHook.serviceData.thumbnail,"filename":props.guruUploadVideoHook.serviceData.videofilename,"newfile_name":props.guruUploadVideoHook.serviceData.videofilename
    }
    
    ]);

    if(props.guruUploadVideoHook.serviceData.attacheddoc != ""){
        pdfsetUploadedImages([{"newfile_name":props.guruUploadVideoHook.serviceData.attacheddoc}]);
    }
      
       
        formik.values.sampleFile.push(uploadedImages)
        props.guruUploadVideoHook.setCheck(false);
    }

    useEffect(() => {
        if (formik.values.sampleFile.length > 0) {
            let formData = new FormData();
            formik?.values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    console.log(data);
                    setUploadedImages(data)
                }
            })
                .catch((error) => {
                });
        }
    }, [formik.values.sampleFile])



    useEffect(() => {
        console.log("uploadedVideosInfo_2",formik.values.pdfsampleFile);
        if (formik.values.pdfsampleFile.length > 0) {
            
            let formData = new FormData();
            formik?.values?.pdfsampleFile?.map((pdffile, index) => {
                formData.append("files", pdffile);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'
      
            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    pdfsetUploadedImages(data)
                }
            })
      
            .catch((error) => {
            });
        }
    }, [formik.values.pdfsampleFile])

    const testobj = {
        'image/*': ['.png', '.jpeg', '.jpg'],
        'video/*':['.mp4','.mov'],
    }

   
    const getSubCategories = (e) => {
        formik.setFieldValue('category', e.target.value)

        getSubCategoriesById(e.target.value)
    }
    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (dropdownsData?.subCategories) {
            dropdownsData?.subCategories.filter((item, index) => {

                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatId, label: item.subCatName})
                }
            })
        }

        console.log(subCategoryOptions,catId,dropdownsData?.subCategoriesOptions);
        setSubCategories(subCategoryOptions);

    }
    
    useEffect(() => {
        console.log("uploadedVideosInfo_0",formik.values.videosampleFile);
        //if(uploadedVideosInfo != null){
        videoSampleFile[0] = uploadedVideosInfo;
       // }
    }, [formik.values.videosampleFile])
    

    const hasProfileImage =  '' ;
    const profileImage = '';

    const showInputFile = !hasProfileImage || formik.values.file
    
  return (
    <div>
        <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
         <Text
        type="H1"
        className="font-weight-500 main_heading_color mb-24"
        text="Create Courses"
    />
     {isModal &&
            <Modal
                onClose={() => setIsModal(false)}
                onOuterClose={() => setIsModal(false)}
                modalContent={
                    <>
                        <VideoPlayer
                            videourl={process.env.REACT_APP_IMG_URL + videoDetails.newfile_name}
                            width="100%"
                            height="460"
                        />
                    </>
                }
            />
    }


    <form onSubmit={formik.handleSubmit}>

        
    <div className="card border-radius-5 pad-10 ">
    <div className="formBox row">
        

    {props.guruUploadVideoHook.serviceData.coursetype == 2 &&
    <>

        <div className=" col-md-4">
        <div className="formBox">
            
            <Input
                autoFocus={true}
                label="Price"
                type="text"
                name="price"
                placeholder=""
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                errormessage={formik.touched.price && formik.errors.price ? (
                    formik.errors.price
                ) : null}
            />
        </div>
        </div>
    
        <div className=" col-md-4">
            <Select
                 tertiary
                label="Category"
                name="category"
                placeholder="Select Category"
                value={formik.values.category}
                onChange={getSubCategories}
                onBlur={formik.handleBlur}
                mandatory
                options={dropdownsData?.categoriesOptions}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />
        </div>

        <div className="col-md-4">
            <Select
                tertiary
                label="Sub Category"
                name="subcategory"
                placeholder="Select Sub Category"
                value={formik.values.subcategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                options={subCategories}
                errormessage={formik.touched.subcategory && formik.errors.subcategory ? (
                    formik.errors.subcategory
                ) : null}
            />
        </div>
    </>
    }


{props.guruUploadVideoHook.serviceData.coursetype != 2 &&
    <>

        
    
        <div className=" col-md-6">
            <Select
                 tertiary
                label="Category"
                name="category"
                placeholder="Select Category"
                value={formik.values.category}
                onChange={getSubCategories}
                onBlur={formik.handleBlur}
                mandatory
                options={dropdownsData?.categoriesOptions}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />
        </div>

        <div className="col-md-6">
            <Select
                tertiary
                label="Sub Category"
                name="subcategory"
                placeholder="Select Sub Category"
                value={formik.values.subcategory}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                options={subCategories}
                errormessage={formik.touched.subcategory && formik.errors.subcategory ? (
                    formik.errors.subcategory
                ) : null}
            />
        </div>
    </>
    }
        
       
        
        
        </div>
            
            <div className="formBox">
    
                <Input
                    autoFocus={true}
                    label="Title"
                    type="text"
                    name="title"
                    placeholder=""
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    mandatory
                    errormessage={formik.touched.title && formik.errors.title ? (
                        formik.errors.title
                    ) : null}
                />
            </div>

            <div className="formBox">

                <Textarea
                    tertiary
                    label="Description"
                    type="text"
                    name="description"
                    placeholder="Write your description here..."
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    mandatory
                    rows="5"
                    errormessage={formik.touched.description && formik.errors.description ? (
                        formik.errors.description
                    ) : null}
                >
                </Textarea>
            </div>

     </div>   

    


     <div className="card border-radius-5 pad-10 mt-20 ">

           <div className='row'>
               {/*setp1  */}    
              
                <div className='col-12 col-md-4 mtb-10'>
                            <div className="card border-radius-5 pad-10 mt-20">      

                                        <div className='row'>
                                            <div className='="col-12'>
                                            <div className="formBox">
                                            <div className="form-field mb-10">
                                            
                                                <Text
                                                     type="H5"
                                                     className="font-weight-500 mb-24"
                                                     text="Step -1"
                                                />

                                                <label className="form-label">Thumbnail <span className="text-danger">*</span></label>
                                            </div>
                                            


                                           

                                            <div className="col-12 create_course_image">
                                                <UploadComponent
                                                    setFieldValue={formik.setFieldValue}
                                                    maxFilesUpload="3"
                                                    hideFooter
                                                    hideButton
                                                    thumbNailWrap
                                                    thumb="Upload Thumbnail"
                                                    fieldName = "sampleFile"
                                                    accpetFileType = {IMAGE_FILE_TYPE}
                                                    uploadedImages = {uploadedImages}
                                                />
                                                {formik.touched.sampleFile && formik.errors.sampleFile ?
                                                    (
                                                        <div className="message-error">
                                                            <small>{formik.errors.sampleFile}</small></div>
                                                    ) : null
                                                }
                                            

                                                {
                                                /*
                                                uploadedImages && uploadedImages.length > 0 &&
                                                uploadedImages?.map((file, i) => (
                                                    <div key={i} className="col-12 ">
                                                        {file?.newfile_name && (
                                                            <img src={process.env.REACT_APP_IMG_URL + file?.newfile_name} alt="" className='course-upload-wrap' style={{ marginTop:"-4px" }} />      
                                                        )}
                                                    </div>
                                                ))
                                                */
                                                }
                                                
                                            </div>


                                            {!uploadedImages &&
                                            (<>
                                                <div className="col-12 ">
                                                    <div className="thumbimgempty"></div>
                                                </div>
                                            </>)
                                            }

                                                <Text
                                                    type="PARAGRAPH"
                                                    className="text-black-half my-2 mt-20"
                                                    text="This image will displayed for category when your members view their library."
                                                />

                                                <Text
                                                    type="PARAGRAPH"
                                                    className="text-black-half my-0"
                                                    text="Recommended dimensions of"
                                                />

                                                <Text
                                                    type="PARAGRAPH"
                                                    className="text-black my-0"
                                                    text="1024x720"
                                                />

                                        </div>
                                            </div>
                                        </div>
                            </div>
                </div>

               {/*step1 - end */}


                {/*step2 - Start */}

                    <div className='col-12 col-md-4 mtb-10'>

                    <div className="card border-radius-5 pad-10 mt-20">      

                                <div className='row'>
                                    <div className='="col-12'>
                                    <div className="formBox">
                                    <div className="form-field mb-10">
                                    
                                        <Text
                                            type="H5"
                                            className="font-weight-500 mb-24"
                                            text="Step -2"
                                        />

                                        <label className="form-label">Upload Video<span className="text-danger">*</span></label>
                                    </div>  


                                   </div>
                                   </div>
                                   </div>


                                   <div className="row">
                                            <div className="col-12 col-md-12 ">
                                            
                                               
                                                <EditUploadVideoPreview
                                                    setFieldValue={formik.setFieldValue}
                                                    text="Drag and Drop Files"
                                                    setUploadedVideosInfo={setUploadedVideosInfo}
                                                    uploadedVideosInfo={uploadedVideosInfo}
                                                    setIsUploading={setIsUploading}
                                                    videoSampleFile={videoSampleFile}
                                                    fieldName = "videosampleFile"
                                                />
 
                                                {formik.touched.videosampleFile && formik.errors.videosampleFile ?
                                                        (
                                                            <div className="message-error">
                                                                <small>{formik.errors.videosampleFile}</small></div>
                                                        ) : null
                                                }

                                                <Text
                                                    type="PARAGRAPH"
                                                    className="my-0 font-weight-500"
                                                    text="Upload your video here, can drop or browse the file."
                                                />
                                                
                                                <Text
                                                    type="PARAGRAPH"
                                                    className="text-black-half my-0"
                                                    text="Format: MP4,MPEG"
                                                />

                                                {uploadedVideosInfo && (
                                                    <>
                                                    <div className='row mt-20'>
                                                        <div className='col-2'>
                                                        <Icon
                                                            icon={<TickIcon width="24" height="24"/>}
                                                            success
                                                        />
                                                        </div>
                                                        <div className='col-4'>
                                                            <Text
                                                                type="PARAGRAPH"
                                                                className="font-weight-500 text-black-half"
                                                                text="Upload Successful"
                                                            />
                                                        </div>

                                                        <div className='col-6'>
                                                        <Button
                                                            type="button"
                                                            text='Play Video'
                                                            isSubmitting={isUploading}
                                                            onClick={() => toggleIsModal(uploadedVideosInfo[0])}
                                                            
                                                        />
            
                                                        </div>


                                                    </div>
                                                        
                                                        
                                                    
                                                    </>
                                                )}

                                                {!uploadedVideosInfo && (
                                                    <>
                                                    <div className='row mt-20'>
                                                        <div className='col-2'>
                                                        
                                                        </div>
                                                        <div className='col-4'>
                                                            <Text
                                                                type="PARAGRAPH"
                                                                className="font-weight-500 text-black-half"
                                                                text="Uploaded Video"
                                                            />
                                                        </div>

                                                        <div className='col-6'>
                                                        <Button
                                                            type="button"
                                                            text='Play Video'
                                                            isSubmitting={isUploading}
                                                            onClick={() => toggleIsModal(uploadedVideosInfo[0])}
                                                            
                                                        />
            
                                                        </div>


                                                    </div>
                                                        
                                                        
                                                    
                                                    </>
                                                )}
                                            </div>
                                        
                                        </div>

                                       
                            </div>

                    </div>

                {/* step2 end */}


               {/* step3 start */}

                    <div className='col-12 col-md-4 mtb-10'>
                        <div className="card border-radius-5 pad-10 mt-20">      

                                    <div className='row'>
                                        <div className='="col-12'>
                                        <div className="formBox">
                                        <div className="form-field mb-10">
                                        
                                        <Text
                                   type="H5"
                                   className="font-weight-500 mb-24"
                                   text="Step -3"
                               />

                                            <label className="form-label">Upload Document </label>
                                        </div>



                                        <div className="form-field form-field-tertiary">
                                            
                                           
                                        { <div className="row">
                                        {pdfuploadedImages  == null &&
                      
                                                <div className="col-12 col-md-12">
                                                    <UploadComponent
                                                        setFieldValue={formik.setFieldValue}
                                                        maxFilesUpload="3"
                                                        hideFooter
                                                        hideButton
                                                        thumbNailWrap
                                                        thumb="Upload PDF"
                                                        fieldName = "pdfsampleFile"
                                                        accpetFileType = {DOC_FILE_TYPE}
                                                    />
                                                    {formik.touched.pdfsampleFile && formik.errors.pdfsampleFile ?
                                                        (
                                                            <div className="message-error">
                                                                <small>{formik.errors.pdfsampleFile}</small></div>
                                                        ) : null
                                                    }
                                                </div>
                                        }

                                            <Text
                                                type="PARAGRAPH"
                                                className="text-white-half mt-20"
                                                text="Upload your Document here. Drag and drop or browse the file"
                                            />

                                                {pdfuploadedImages && pdfuploadedImages.length > 0 &&
                                                pdfuploadedImages?.map((pdffile, i) => (
                                                    <div key={i} className="col-12 col-md-12">
                                                        {pdffile?.newfile_name && (
                                                        <>
                                                        {/*<Text
                                                            type="H5"
                                                            className="font-weight-500 mb-24"
                                                            text={process.env.REACT_APP_IMG_URL + pdffile?.newfile_name}
                                                        />
                                                            <Button
                                                            btn-link-primary
                                                        type="button"
                                                        text="Remove"
                                                        onClick={()=>{
 
                                                            pdfsetUploadedImages(null)
                                                        
                                                        }}
                                                        
                                                        
                                                    />*/}
                                                        <Flex>

                                                        <Icon
                                                            icon={<TickIcon width="24" height="24"/>}
                                                            success
                                                        />

                                                        <Button
                                                            link
                                                            text={
                                                                <img src={binImg}  alt="copy" className='icon-50' />
                                                            }
                                                            style={{margin:'0px',padding:'1px'}}
                                                            onClick={() => 
                                                                pdfsetUploadedImages(null)
                                                            }
                                                            type="button"
                                                        />
                                                        </Flex>

                                                       

                                                    </>
                                                        )}
                                                        
                                                    </div>
                                                ))
                                                }
                                            </div>}

                                            
                                            
                                            </div>     



                                        


                                    </div>
                                        </div>
                                    </div>
                        </div>
                    </div>                                        

                {/* step3 end */}                   


            </div>         

     </div>

 
        
        
     


        
        <div className="action-buttons-end">
                     <Button
                        type="button"
                        text="cancel"
                        onClick={()=>{navigate('/instructor/course')}}
                    />
                    <Button
                        light
                        type="submit"
                        text="save"
                        className="mt-20"
                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                    />
                    
                </div>
      </form>


      

    </div>

    



  )
  

  
  
}

/*
const UploadVideoPreview = props => {
    const {getFileExtension, getFileSize} = useHelper()
    const apiUrl = process.env.REACT_APP_API_URL + '/videoUpload'
    const [files, setFiles] = useState(null);
    const [progress, setProgress] = useState(0);
    const [getImg, setImg] = useState(process.env.REACT_APP_IMG_URL + props?.videoSampleFile[0].thumbnail );
    const {setFieldValue} = props;
    const [errors, setErrors] = useState("");
    
    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'video/*': ['.mp4', '.mpeg'],
        },
        onDrop: (acceptedFiles) => {
            
            setErrors('')
            console.log("props.fieldName",props.fieldName)
            setFieldValue(props.fieldName, acceptedFiles);
            props.setUploadedVideosInfo(null)
            acceptedFiles.forEach((file) => {
                setFiles(file);
                const data = new FormData();
                data.append('file', file);
                axios.post(apiUrl, data, {
                    headers: {
                        'Authorization': localStorage.getItem('token'),
                        "Content-Type": "multipart/form-data",
                    },
                    onUploadProgress: (progressEvent) => {
                        props.setIsUploading(true)
                        const {loaded, total} = progressEvent;
                        let percentage = Math.floor((loaded * 100) / total);
                        if (percentage <= 100) {
                            setProgress(percentage)
                        }
                    }

                }).then((responseData) => {
                    
                    let postData = {
                        filename: responseData.data.data[0].newfile_name,
                        file_size: responseData.data.data[0].size,
                        file_type: responseData.data.data[0].mimetype,
                        thumbnail: responseData.data.data[0].thumbnail,
                        duration: responseData.data.data[0].duration
                    }
                    console.log('responseData', responseData.data.data[0],postData,postData.thumbnail)
                    props.setUploadedVideosInfo(responseData.data.data)
                    
                    props.videoSampleFile[0] = responseData.data.data;
                    props.setIsUploading(false)
                    setImg(process.env.REACT_APP_IMG_URL + postData.thumbnail);

                    

                }).catch((error) => {
                    console.log("ERROR " + error.message)
                });
            });
        }
    });

    const fileSize = files ? getFileSize(files?.size) : ''
    const fileFormat = files ? getFileExtension(files?.name) : ''

    return (
        <>
            <div className="thumbnail-main-wrapper">
                <div className="thumbnail-wrapper">
                    <div className="thumbnail-upload-file-wrp">
                    
                        <div className="upload-content" {...getRootProps({className: 'dropzone'})}>
                        
                        
                        
                                <div class="create_course_image">

                                        <img src={getImg} alt="" className='course-upload-wrap' style={{ marginTop:"-4px" }} />      
                                        <input {...getInputProps()} />
                                        
                                        <div className="icon create_course_h2 div-center">
                                            <CameraIcon/>
                                        </div>
                                        <div className="div-center">
                                        <Text className="create_course_h2 " type="SPAN" text={ props.text}  />
                                        </div>
                                </div>
                           
                        </div>
                    </div>
                </div>
                {files && (
                    <div className="filesdetails">
                        <Text type="H4" className="mb-10" text={files?.name}/>
                        <Text type="PARAGRAPH" className="my-0 font-size-12 text-black-half"
                              text={`File size: ${fileSize}`}/>
                        <Text type="PARAGRAPH" className="mt-0 mb-10 font-size-12 text-black-half"
                              text={`File format: ${fileFormat}`}/>
                        <ProgressBarCard width={progress}/>
                    </div>
                )}
                {!files && (
                    <div className="filesdetails">
                        <Text type="H4" className="mb-10" text={props?.videoSampleFile[0].filename}/>
                        
                    </div>
                )}
            </div>
        </>
    );
}

*/



export default EditCourse;

