import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import Card from "../../../../components/card";
import Flex from "../../../../components/flex";
import useLearnByTopics from "../../../../components/learnByTopics/hooks/learnByTopics.hook";
import Section from "../../../../components/section";
import Text from "../../../../components/text";
import LearnByTopics from "../../../../components/learnByTopics";
import Breadcrumbs from "../../../../components/breadcrumbs";
import TotalTopicSearch from "../../../../components/total_topic_search";
import TopicsList from "../../../../components/topicsList";
import listImg from "../../../../assets/img/listImage.png";
import coloumImg from "../../../../assets/img/columnImage.png";
import Modal from "antd/es/modal/Modal";
import VideoPlayer from "../../../../components/videoplayer";
import CourseSearch from "../../../../components/courseSearch";

const CoursePageView = (props) => {
  const { text } = props;

  const breadcrumbList = [
    {
      text: "Dashboard",
      link: "/trainee/dashboard",
    },
    {
      text: "Courses",
      link: "/trainee/courses",
    },
  ];

  const [isVideoModal, setIsVideoModal] = useState(false);
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");

  const playVideo = (val) => {
    console.log(val);
    setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
    setIsVideoModal(true);
  };
  return (
    <div>
      {/* <Breadcrumbs
        breadcrumbList={breadcrumbList}
      /> */}
      <Section className="pt-3 lbt-section rml-24 overflow-outer">
        <div className="section-head mt-lg-2 mt-5">
          <Text
            type="H3"
            text="Courses"
            className="section-title text-uppercase fs-4"
          />
        </div>

        <CourseSearch
          dropdownsData={props.traineeDropdownsData}
          _props={props}
        />

        <div className="row shadow bg-body rounded  p-2 text-right mb-4">
          <div className="col-md-12">
            <Button
              link
              text={
                <img src={listImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("list");
              }}
              type="button"
            />

            <Button
              link
              text={
                <img src={coloumImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("coloum");
              }}
              type="button"
            />
          </div>
        </div>

        
        <div className="row ">
         
          <TopicsList {...props} playVideo={playVideo} />
        
      
        </div>

          
      </Section>

      {isVideoModal && (
        <Modal
          title=""
          centered
          open={isVideoModal}
          onOk={() => setIsVideoModal(false)}
          onCancel={() => setIsVideoModal(false)}
          zIndex={10000}
          width={window.innerWidth - window.innerWidth / 5}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <VideoPlayer videourl={vidoePlayUrl} width="100%" height="460" />
        </Modal>
      )}
    </div>
  );
};

export default CoursePageView;
