import React,{useContext,useEffect,useRef,useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/button';
import AddSurveyView from './views/addQuizView';
import useVideosCommonHooks from '../createCourse/videosCommonHook';
import Loader from '../../../components/loader';
import CourseMainBar from '../../../components/courseMainbar';
import Modal from '../../../components/modal';
import VideoPlayer from '../../../components/videoplayer';
import ShareVia from '../../../components/shareVia';
import AddQuizView from './views/addQuizView';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import { DEFAULTFILTERS, DEFAULT_PAGE_COUNT } from '../../../constants/app.constants';

const AddQuizController = (props) => {

        const [isVideoModal, setIsVideoModal] = useState(false);
        const [vidoePlayUrl, setVidoePlayUrl] = useState("");
        const [isShareModal, setIsShareModal] = useState(false);

        const closeShare = () => {
        setIsShareModal(false);
    };
   
    const getTableListHook = useGetTableList();
    
        const commonHook = useVideosCommonHooks();
    
        const {courseId} = useParams();

        const navigate = useNavigate();
    
        const playVideo = (val) => {
                console.log(val);
                setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
                setIsVideoModal(true);
            };
        
   
        useEffect(() => {
        
                if(commonHook.checkLevel){
             
                    commonHook.getDropDowns().then(()=>{
                        console.log("commonHook.serviceData",commonHook.serviceData);

                      
                            
                            commonHook.setLevel(false);
                            commonHook.getCustomData({"uri":"instructorCourseModuleList",'courseid':courseId,"levelid":0});       


                        
                        
                    })

                
              
                   
                }
                
            },[commonHook.checkLevel])
        
        if(commonHook.serviceData == null){
          
                return <Loader/>
        }else{
            
      
   
       
       
        return (
            
                <LayoutGuru {...props}>
                
                   <div className="row">
  <div className="col-12">
         <Button
         type="primary"
         style={{
          marginBottom: 10, marginTop : "10px"
         }}
         text="PREVIEW COURSE"
         onClick={() => {
            navigate('/instructor/course-preview/' + courseId +'-0')
        }}
        />
        </div>
</div>
       
<CourseMainBar index={0} playVideo={playVideo} data={commonHook.serviceData.course_details} showButtons={false}  setIsShareModal={setIsShareModal}/>     
                 
<AddQuizView {...getTableListHook} promiseService = {commonHook.promiseService}/>

                </LayoutGuru>
        )
    }

    
    


}


export default AddQuizController;
