import React from 'react'
import Breadcrumbs from '../../../../components/breadcrumbs'
import LearnByTopics from '../../../../components/learnByTopics'
import Section from '../../../../components/section'
import Text from '../../../../components/text'

const FavouriteCourseView = (props) => {

  const breadcrumbList = [
    {
        text: 'Dashboard',
        link: '/trainee/dashboard',
    },
    {
        text:"Courses",
        link:"/trainee/courses",
    },
    {
      text:"Favourite Courses",
      link:"/trainee/favourite-course",
  },
]

  return (
    <div>
         <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />

<Section className="pt-0 lbt-section rml-24 overflow-outer">
                <div className="section-head mt-2">
                    <Text
                        type="H3"
                        text="Favourite Courses"
                        className="section-title text-uppercase"
                    />
                </div>
                <LearnByTopics {...props}/>
             </Section>   
            

    </div>
  )
}

export default FavouriteCourseView
