import React, {useState} from 'react';
import Text from '../../../../components/text';
import Button from '../../../../components/button';
import Loader from "../../../../components/loader";
import useHelper from "../../../../hooks/helpers.hook";
import TopicVideoCard from "../../../../components/topicVideoCard";
import JoinGuru from '../../../../components/joinGuru';
import topicVideoPoster from "../../../../assets/images/topic-video-poster.jpg";
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import CategoryButtons, {SubCatButton} from "../../../../components/categoryButtons";
import Section from '../../../../components/section';
import { useFormik } from 'formik';
import Select from '../../../../components/input/select';
import * as Yup from 'yup';
import Breadcrumbs from '../../../../components/breadcrumbs';

const LikedVideosList = (props) => {
    const navigate = useNavigate()
    const {getShortDescription} = useHelper()
    const {videosListLoading, videosList} = props

    if (videosListLoading) {
        return <Loader/>
    } else {
        if (videosList && videosList.length > 0) {
            return (videosList?.map((item, index) => {
                const posterImage = item?.thumbnail ? process.env.REACT_APP_IMG_URL + item?.thumbnail : topicVideoPoster
                const avatarImage = item?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + item?.uploaded_by_pic : AvatarPic
                return (
                    <>
                   
                    <div className="col-12 col-md-4">
                    <TopicVideoCard
                        secondary
                        videoPoster={posterImage}
                        duration={item.videoduration}
                        avatarSrc={avatarImage}
                        avatarName={item?.title}
                        title={item?.title}
                        by={item?.uploaded_by_name}
                        shortDescription={getShortDescription(item?.des, 10)}
                        views={item?.ViewsCount}
                        imgWidth="320"
                        imgHeight="233"
                        onClick={() => {
                            navigate('/trainee/my-learning/video-details/' + item.videoid)
                        }}
                    />
                </div>
                
          </>
                )
            }))
        }
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No videos available"/></div>
    }
}

const LikedVideoView = (props) => {
    const {getLikedVideos, text} = props
    const [active, setActive] = useState({catId: 0, catName: 'All topics'});
    const formik = useFormik({
        initialValues: {
            category: '',
            
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Please enter a valid study level'),
           
        }),
        onSubmit: (values, actions) => {

        },
    });

    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Favourite Videos",
            link:"/trainee/courses",
        },
    ]

    const {
        dropdownsData,
        videoDetails,
    } = props?.guruUploadVideoHook


    const [subCategories, setSubCategories] = useState(null)
    const getSubCategories = (e) => {
        formik.setFieldValue('category', e.target.value)
        getSubCategoriesById(e.target.value)
    }
    const getSubCategoriesById = (catId) => {
        let subCategoryOptions = []
        if (dropdownsData?.subCategoriesOptions) {
           return dropdownsData?.subCategoriesOptions.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatid, label: item.subCatName})
                }
            })
        }
        setSubCategories(subCategoryOptions)
    }


    return (
        <>
              <Breadcrumbs         breadcrumbList={breadcrumbList}       />

                <Text
                    type="H1"
                    text="Favourite Videos"
                />
               <form onSubmit={formik.handleSubmit}>
   
   <div className=" bg-white p-5">
    <div className='w-80'>
    <Select
           tertiary
           label="Category"
           name="category"
           placeholder="Select Category"
           value={formik.values.category}
           onChange={getSubCategories}
           onBlur={formik.handleBlur}
           mandatory
           options={dropdownsData?.categoriesOptions}
           errormessage={formik.touched.category && formik.errors.category ? (
               formik.errors.category
           ) : null}
       />
    </div>

   </div>
</form>
            <div className="row">
                <LikedVideosList {...props} />
            </div>
            
            
        </>
    );
};

export default LikedVideoView;

