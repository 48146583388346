import React, {useEffect, useRef} from 'react';
import Text from "../../../../components/text";
import Section from '../../../../components/section';
import TopTenVideoCard from '../../../../components/topTenVideoCard';

import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {Pagination} from "swiper";
import "swiper/css/pagination";

import topicVideoPoster from '../../../../assets/images/topic-video-poster.jpg'
import useTop10Videos from "../hooks/top10Videos.hook";
import Loader from "../../../../components/loader";
import useHelper from "../../../../hooks/helpers.hook";
import TopicInterestSecondary from "../../../../components/topicInterestSecondary";
import TopicImgInvestment from "../../../../assets/img/logo.svg";
import InvestmentIcon from "../../../../components/svg/InvestmentIcon";
import TopicImgBusiness from "../../../../assets/img/logo.svg";
import BusinessIcon from "../../../../components/svg/businessIcon";
import TopicImgBudgeting from "../../../../assets/img/logo.svg";
import BudgetingIcon from "../../../../components/svg/budgetingIcon";
import TopicImgSaving from "../../../../assets/img/logo.svg";
import SavingsIcon from "../../../../components/svg/savingsIcon";
import TopicImgHousing from "../../../../assets/img/logo.svg";
import HousingIcon from "../../../../components/svg/housingIcon";
import useTopicsYouAreInterested from "../hooks/topicsYouAreInterested.hook";
import Flex from '../../../../components/flex';
import Button from '../../../../components/button';
import SwiperPrevIcon from '../../../../components/svg/swiperPrevIcon';
import SwiperNextIcon from '../../../../components/svg/swiperNextIcon';
import {useNavigate} from "react-router-dom";
import LikedVideos from './LikedVideos';
import TopicVideoCard from '../../../../components/topicVideoCard';

const TopicsYouAreInterested = (props) => {
    const navigate = useNavigate();
    const swiperTopicsInterestedRef = useRef();

    const {getShortDescription, formatMinutes} = useHelper()

    const topicsYouAreInterested = useTopicsYouAreInterested()

    useEffect(() => {
        topicsYouAreInterested.getTopicsInterested()
    }, [])

    if (topicsYouAreInterested?.loading) {
        return <Loader/>
    } else {
        if (topicsYouAreInterested?.videosList && Object.keys(topicsYouAreInterested?.videosList).length === 0) {
            return <></>

        } else {
            return (


                <Section>
                    <div className="section-head">
                        <Text
                            type="H3"
                            text="Topics You Are Intrested In"
                            className="section-title text-uppercase"
                        />
                       
                    </div>
                    <Swiper
                        spaceBetween={14}
                        slidesPerView={1}
                        onBeforeInit={(swiper) => {
                            swiperTopicsInterestedRef.current = swiper;
                        }}
                        modules={[Pagination]}
                        pagination={true}
                        breakpoints={{
                            640: {
                                slidesPerView: 1,
                                pagination: true,
                            },
                            768: {
                                slidesPerView: 4,
                                pagination: true,
                            },
                            1024: {
                                slidesPerView:4,
                                pagination: false,
                            },
                        }}
                    >
                        {topicsYouAreInterested?.videosList?.map((item, index) => {
                            const topicImage = item?.cat_img ? process.env.REACT_APP_IMG_URL + item?.cat_img : TopicImgInvestment

                            return (
                                <SwiperSlide>
                                    
                                   <TopicVideoCard
                                     poster={item.thumbnail}
                                     categeoryname="categeoryname"
                                     courseId={item.courseid}
                                     categeorytitle={item.catName}
                                     subcategeory="subcategeory"
                             subcategeorytitle={item.subCatName}
                                     title={item.title}
                                     desc={item.des}
                                     heart="25"
                                     imgWidth="320"
                                     imgHeight="233"
                                     onClick={() => {
                                         navigate('/trainee/course-details/' + item.videoid)
                                     }}
                                     duration={item.videoduration}
                                     watchedupto={item?.watchedupto}
                                            />
                                   {/** <TopicInterestSecondary
                                        src={topicImage}
                                        /*icon={<InvestmentIcon/>}*
                                        title={item?.category}
                                        totalVideos={item?.videoscount}
                                        totalGuru={item?.gurucount}
                                        imgWidth="210"
                                        imgHeight="246"
                                        onClick={()=>{navigate('/trainee/learn-by-topics?'+'catId='+item?.categoryid + '&catName='+item?.category)}}
                                    /> */}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
        
                </Section>
            );
        }
    }
};

export default TopicsYouAreInterested;

