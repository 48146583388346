import React, {useState} from 'react';
import Text from '../../../../../components/text';
import NavLearnerMember from '../../../../../components/navLearnerMember';
import Pagination from '../../../../../components/pagination';
import Flex from '../../../../../components/flex';
import DropdownNew from '../../../../../components/dropdown/dropdownNew';
import Button from '../../../../../components/button';
import Table from '../../../../../components/table';
import useHelper from "../../../../../hooks/helpers.hook";
import Card from '../../../../../components/card';
import { useFormik } from 'formik';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/select';
import * as Yup from 'yup';
import SubscribeView from '../../../subscribe/view/SubscribeView';
import TrainerCategoryBar from '../../../../../components/trainerCategoryBar';
import {toast} from "react-toastify";
const WalletView = (props) => {
    const {text} = props
    const {formatDateMMMDDYYYY} = useHelper()
    const tableData = props?.tableData?.listData

    const [yearSelected, setYearSelected] = useState('Year 2022');
    const [monthSelected, setMonthSelected] = useState('Select Month');

   

const formik = useFormik({
    initialValues: {
        amount: '',
      
    },
    validationSchema: Yup.object({
        amount: Yup.string('Please enter a Amount'),
        
    }),
    onSubmit: (values, actions) => {
       
        actions.setSubmitting(true)
        values.uri = "AddToWallet";
        
      
        
        
        props.promiseService(values).then((data) => {

      
            
            if(data.data.status.result == 200){
                toast.success(data.data.status.msg);
                props?.setReloadPage(true);
               
            }else{
                toast.error(data.data.status.msg);
            }
          
        
        });
        


    },
});



   
    const yearsOption = [
        {
            value: 'Debit',
        },
        {
            value: 'Credit',
        },
       
    ];
    return (
        <>

           <Text
                type="H1"
                text="Wallet"
            />

            <div className='row' >
                
                <div className='col-md-4'></div>
                <div className='col-md-3'>
                <div className="row top-main-boxes  course-main-box "  style={ {padding:'0px'}} >
                <form onSubmit={formik.handleSubmit}>
                <div className="formBox">

                <Text type="PARAGRAPH" text={"Current Wallet : " + props?.walletAmount } className="text-dark mb-0 mr-10" />

                <Input
                className="lineBottom"
                formControlClassName="form-control"
                label="Add Amount"
                type="text"
                name="amount"
                placeholder="Amount"
                autoFocus={true}
                value={formik.values.uname}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                errormessage={formik.touched.uname && formik.errors.uname ? (
                    formik.errors.uname
                ) : null}
            />

                <Button
                    outline
                    type="submit"
                    text="Add"
                    disabled={formik.isSubmitting}
                    isSubmitting={formik.isSubmitting}
                />


   
</div>
  
</form>

                 </div>  
                </div>


            </div>
                    

            {/*<NavLearnerMember/>*/}
            <Flex justifyContent="between" className="mb-24 flex-column flex-md-row">
                <div>
                    {props?.tableData?.customdata?.total_amount > 0 && (
                        <>
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-black font-weight-400"
                                text="Current Income"
                            />
                            <Text
                                type="H4"
                                className="mb-0 line-height-1 text-white-half mt-2"
                                text={`RM ${props?.tableData?.customdata?.total_amount}`}
                            />
                        </>
                    )}

                </div>
           {/*
             <div className="filterby">
                    <Text type="PARAGRAPH" text="Filter by:" className="text-dark mb-0 mr-10"/>
                    <div className="filterby-lists">
                       
                        <DropdownNew
                            secondaryDropdown
                            right
                            selected={yearSelected}
                            setSelected={setYearSelected}
                            options={yearsOption}
                            placeholder="Filter By"
                        />
                        <Button
                            type="button"
                            text="Search"
                        />
                    </div>
                    </div> */}
            </Flex>

            <Table
                thead={
                    <tr>
                        <th>Srno</th>
                        <th>Amount</th>
                        <th>Reference Id</th>
                        <th>Type</th>
                        <th>Created On</th>
                        
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.amount}</td>
                                    <td>{item.pg_id}</td>
                                    <td>Credited</td>
                                    <td>{formatDateMMMDDYYYY(item.createdon)}</td>
                        
                                </tr>
                            )
                        })}
                        {props?.tableData?.customdata?.total_amount > 0 && (
                            <tr>
                                <td colSpan="4" className="text-right text-white-half">Total</td>
                                <td className="font-weight-600 text-black">
                                    RM {props?.tableData?.customdata?.total_amount}</td>
                            </tr>
                        )}
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
        </>
    );
};

export default WalletView;

