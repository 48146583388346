import React, {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import useUploadAttachments from '../hooks/uploadAttachments.hook'
import Text from '../../../../components/text';
import Input from '../../../../components/input';
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import {useDropzone} from "react-dropzone";
import Image from '../../../../components/image';
import Icon from '../../../../components/icon';
import UploadIcon from '../../../../components/svg/uploadIcon'
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {setUserInfo} from "../../../../store/reducers/app.reducer";
import {toast} from "react-toastify";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import GoBackButton from "../../../../components/goBackButton";
import TickIcon from "../../../../components/svg/tickIcon";
import CameraIcon from "../../../../components/svg/cameraIcon";
import NavSide from '../../../../components/navSide';
import { IMAGE_FILE_TYPE } from '../../../../constants/app.constants';
import uploadImage from '../../../../assets/img/uploadImage.png'
import uploadDocument from '../../../../assets/img/uploadDocument.png'
import useVideosCommonHooks from '../../createCourse/videosCommonHook';
import ImageUpload from '../../../../components/image/imageUpload';

const UploadAttachmentsView = (props) => {
    const {text} = props
    const navigate = useNavigate()
    const {post} = useHttpHandler()
    const {
        goBackToBankDetails,
        goToThankyou,
    } = useUploadAttachments();

    
    const formik = useFormik({
        initialValues: {
            sampleFile: [],
        },
        validationSchema: Yup.object({
            sampleFile: Yup.mixed().required('Please upload Copy of Identification Card (Front and Back) '),
        }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(true)

            let formData = new FormData();
            values?.sampleFile?.map((file, index) => {
                formData.append("files", file);
            })

            const apiUrl = process.env.REACT_APP_API_URL + '/instructorUpload'

            axios.post(apiUrl, formData, {
                headers: {
                    'Authorization': localStorage.getItem('token'),
                    "Content-Type": "multipart/form-data",
                }
            }).then((responseData) => {
                if (responseData.data.status.result == '200') {
                    toast.success(responseData.data.status.msg)
                    let postData = {
                        front_side: '',
                        back_side: '',
                        front_size: '',
                        back_size: ''

                    }
                    responseData?.data?.data?.map((file, index) => {
                        if (index === 0) {
                            postData.front_side = file.newfile_name;
                            postData.front_size = file.size;
                        } else {
                            postData.back_side = file.newfile_name;
                            postData.back_size = file.size;
                        }
                    })

                    post('guru_attachmentsentry', postData).then((res) => {
                        actions.setSubmitting(false)
                        const status = res.data.status
                        const data = res.data.data
                        if (status.result == '200') {
                            goToThankyou();
                        } else {
                            toast.error(status.msg)
                        }
                    })
                } else {
                    toast.error('Something went wrong')
                }

            })
                .catch((error) => {
                    actions.setSubmitting(false)
                    console.log("ERROR " + error.message)
                });
        },
    });
    return (
        <>
<div class="p-3 mb-3">
<div class="card-body">
  <div class="pt-4 pb-2">
    <h5 class="card-title text-center pb-0 fs-4">{text?.UPLOAD_ATTACHMENT}</h5>
    <p class="text-center small">Enter your personal details to create account</p>
  </div>
  <NavSide
    title=""
options={[
    {text:"", link: '/instructor/personal-information'},
    {text: "", link: '/instructor/work-experience'},
    {text: "", link: '/instructor/upload-attachments'},
    {text: "", link: '/instructor/bank-details'},
]}
/>
           
            <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <div className="formBox">
                    <div className="form-field">
                        <label className={`form-label`}>{text?.UPLOAD_COPY_OF_IDENTIFICATION_CARD_FRONT_AND_BACK}</label>
                        <UploadComponent {...props} setFieldValue={formik.setFieldValue}  fieldName = "sampleFile"  accpetFileType = {IMAGE_FILE_TYPE} maxFilesUpload="2"/>
                        {formik.touched.sampleFile && formik.errors.sampleFile ?
                            (
                                <div className="message-error"><small>{formik.errors.sampleFile}</small></div>
                            ) : null
                        }
                    </div>

                    <Text type="H4" text={text?.UPLOADED_FILES+''+formik?.values?.sampleFile?.length}
                          className="font-weight-500 mb-24"/>
                    {formik?.values?.sampleFile &&
                    formik?.values?.sampleFile?.map((file, i) => (
                        <div key={i}>
                            {`File:${file.name}`}{" "}
                        </div>
                    ))}
                </div>
                <Flex justifyContent="center" className="mb-48 mt-48">
                <Button
                        type="button"
                        text={text?.GOBACK}
                        large
                        light
                        outline
                        className="mt1"
                        onClick={()=>{navigate('/instructor/bank-details')}}

                    />
                    <Button
                        type="submit"
                        text={text?.PROCEED}
                        className="btn-verification"
                        large
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting || formik.values.sampleFile == ''}
                    />

<Button
                        type="button"
                        className="btn-verification"
                        text="skip"
                        link
                        onClick={()=>{navigate('/instructor/bank-details')}}
                        
                 
                    />
                </Flex>
                
            </form>
            </div>
            </div>
        </>
    );
};  

export default UploadAttachmentsView;

export const UploadComponent  = props => {
    console.log("props.uploadedImage",props.uploadedImages)
    const [errors, setErrors] = useState("");
    const commonHook = useVideosCommonHooks();
    const {post} = useHttpHandler()
    const {setFieldValue} = props;
    const [imageUrl,setImageUrl] = useState("");
  

    useEffect(() => {
        if(props.uploadedImages != null && props.uploadedImages != undefined){
            if(props.uploadedImages.length >0){
                console.log("props.uploadedImages",props.uploadedImages)
                    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':props.uploadedImages[0]?.newfile_name}).then((data) => {
                        console.log("props.uploadedImages",data.data)
                        setImageUrl(data.data); 
            
                    })
            }
        }
    }, [props.uploadedImages]);

       
   


    const {getRootProps, getInputProps} = useDropzone({
        accept: props.accpetFileType,
        maxFiles: props?.maxFilesUpload,
        onDrop: (acceptedFiles, fileRejections) => {
            setErrors('')
            console.log("props.fieldName",props.fieldName)
            setFieldValue(props.fieldName, acceptedFiles);
            fileRejections.forEach((file) => {
                file.errors.forEach((err) => {
                    if (err.code === "file-too-large") {
                        setErrors(`Error: ${err.message}`);
                    }
                    if (err.code === "file-invalid-type") {
                        setErrors(`Error: ${err.message}`);
                    }
                    if (err.code === "too-many-files") {
                        setErrors(`Error: ${err.message} please select maximum of ${props?.maxFilesUpload}`);
                    }
                });
            });
        },
    });
    return (
        <div className={props.thumbNailWrap ? 'thumbnail-wrapper' : 'mupload-wrapper'}>
            <div className={props.thumb == "Upload Thumbnail" ? 'thumbnail-upload-file-wrp imageUpload' : 'mupload-main-wrapper'} style={{width:'100%'}} >
               
                <div {...getRootProps({className: "dropzone"})}>
                    <input {...getInputProps()} />
                    {!props?.thumbNailWrap && (<div className="d-flex flex-column align-items-center">
                            <Icon
                                light
                                icon={<UploadIcon width="24" height="24"/>}
                            />
                            <Text type="H4" text={"Drag and Drop here"} className="mt-20"/>
                            <br/>
                            <Text type="PARAGRAPH" text="or"/>
                            <div className="btn btn-primary btn-large">+ SELECT FILES</div>
                        </div>
                    )}


                        
                    {props?.thumb == "Upload Thumbnail" && (
                         
                        <>
                        {imageUrl !== ""  &&(

//props?.uploadedImages?.map((file, i) => (
    <> 
      <img src={imageUrl} alt="" className='course-upload-wrap'  />
      
    </>

    
//))
)}

{props?.uploadedImages == undefined || props?.uploadedImages == [] || props?.uploadedImages == "undefined" || props?.uploadedImages == null  &&(

//props?.uploadedImages?.map((file, i) => (
    <div className="create_course_image_v">
        <img src={uploadImage} className='course-upload-wrap' alt="" /> 
    </div>
//))
)}

 

                            


                        </>
    )}




                    {props?.thumb == "Upload PDF" && (
                        <>
                        
                            <div className="create_course_image_v ">
                            <img src={uploadDocument} className='course-upload-wrap' alt="" /> 
                            </div>
                        </>
                    )}
                </div>
            </div>
            {!props?.hideFooter && (
                <Flex justifyContent="between">
                    <Text type="PARAGRAPH" text="Format fail: .jpg,.jpeg,.png" className="mb-0"/>
                    <Text type="PARAGRAPH" text="Secure" className="mb-0"/>
                </Flex>
            )}
            {errors && (<Text type="PARAGRAPH" className="text-danger mt-10" text={errors}/>)}
        </div>
    );
};
