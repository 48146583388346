import React from 'react';
import Text from "../../../../components/text";
import Section from '../../../../components/section';
import NumberCard from '../../../../components/numberCard';
import BasicFinance from '../../../../components/basicFinance';
import LearnByTopics from '../../../../components/learnByTopics';
import {useState} from 'react';
import {useSelector} from "react-redux";
import useHelper from '../../../../hooks/helpers.hook';
import Flex from '../../../../components/flex';
import TrendingVideos from "../components/TrendingVideos";
import {GURU} from "../../../../constants/app.constants";
import {useMediaQuery} from 'react-responsive';
import * as Yup from 'yup';
import {
    FacebookIcon,
    FacebookShareButton, TwitterIcon,
    TwitterShareButton, WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import Input from '../../../../components/input';
import { useFormik } from 'formik';
import { AiOutlineCopy } from 'react-icons/ai';
import { NavLink, useNavigate } from 'react-router-dom';
import FreemiumVideoPanel from '../../../../components/freemiumVideoPanel';
import PremiumVideoPanel from '../../../../components/premiumVideoPanel';



const TraineeDashboardView = (props) => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const {hasPermissions, isPremiumMember,isFreemiumMember} = useHelper();
    const navigate = useNavigate();
    const isGuru = hasPermissions(GURU)
    const {text} = props
    const {dashboardDetails} = props
    const userInfo = useSelector(state => state?.app?.userInfo)
    const title = userInfo.fullname ? text?.WELCOME_BACK + ' ' + userInfo.fullname + '!' : text?.WELCOME_BACK + ' ' + userInfo.uname + '!'
    const affiliateLink = process.env.REACT_APP_URL + '/referral/' + userInfo?.myrefcode
    const isPremium = isPremiumMember()
    const isFremium = isFreemiumMember();
    const [isCopied, setIsCopied] = useState(false)

    const onCopy = () => {
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }
    const formik = useFormik({
        initialValues: {
            affiliateLink: affiliateLink,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values, actions) => {
        },
    });


    const premiumVideo = [{"videoid":1,"videosection":2,"videofilename":"first_video.mp4","videoduration":334,"videofiletype":"video/mp4","thumbnail":"","title":"CourseTSunami","des":"<p>This is CourseTSunami</p>"}]

    return (
        <>
    

        {dashboardDetails?.isAdvanceSelected != 0 && dashboardDetails?.video_info && (
                <Section className="mt-5 mt-lg-2">
                    <div className="section-head">
                        <Text
                            type="H3"
                            text="MY COURSES "
                            className="section-title text-uppercase fs-4"
                        />
                    </div>
                    <div className="">
                    <BasicFinance {...props} videoInfo={dashboardDetails?.video_info}/>
                    </div>
                </Section>
            )}

    {isPremium && dashboardDetails?.isAdvanceSelected == 0 && !dashboardDetails?.video_info &&(
      <PremiumVideoPanel options={premiumVideo}  />)
    }
    {isFremium  && (
      <FreemiumVideoPanel options={premiumVideo}  />
    )
    }

  


            <Section className="pb-0">
                {isPremium && (
                    <Flex
                        alignItems="center"
                        justifyContent="between"
                        flexColumn={isMobile ? true : false}
                        className="shadow p-3 mb-3 bg-body rounded py-3 px-4 borderRadius5px"
                    >
                        <div className="pr-3">
                            <Text type="H3" className="mb-1 fs-5" text="Share AFFILIATE LINK! & EARN REWARDS!"/>
                            <Text
                                type="PARAGRAPH"
                                className="mb-3 mb-md-0 "
                                text="Let your frends and family learn financial topics to enhance their knowledge, help them while earning exciting rewards."
                            />
                  <form onSubmit={formik.handleSubmit} className="mt-3 mb-0 ">
                        <div className="formBox ">
                        <Input
                            secondary
                            type="text"
                            name="affiliateLink"
                            placeholder=""
                            value={formik.values.affiliateLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            formControlClassName="text-left w-100 border-0 text-theme-color"
                            
                        />
                         <AiOutlineCopy size={'25px'} className="mt-2 text-theme-color" />
                    </div>
                   
                    </form>
                        </div>
                    <div className='d-flex'>
                    <Flex justifyContent='between'>
                        <FacebookShareButton
                            url={affiliateLink}
                            className="mr-10"
                        >
                            <FacebookIcon
                                size={44}
                                round={true}
                            />
                        </FacebookShareButton>

                        {/*<Button link text={<GoogleShareButton width="39" height="38" />} />*/}
                        <WhatsappShareButton url={affiliateLink}
                                             className="mr-10" >
                            <WhatsappIcon size={44} round={true}/>
                        </WhatsappShareButton>
                        <TwitterShareButton url={affiliateLink}
                                            className="mr-10" >
                            <TwitterIcon size={44} round={true}/>
                        </TwitterShareButton>

                    </Flex>
                    </div>
                    </Flex>
                    
                )}

                <div className="section-head">
                    <Text
                        type="H3"
                        text="COURSE TSUNAMI STATS"
                        className="text-dark text-5 fs-4"
                    />
                </div>
                <div className={`row ${isPremium ? 'row-cols-1 row-cols-md-5 row-mrl-5' : 'row-cols-1 row-cols-md-3'}`}>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <NumberCard
                            title={'Subscribers'}
                            number={dashboardDetails?.total_member_subscribed}
                            text={'Members Subscirbed'}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <NumberCard
                            title={'Enrolled by you'}
                            number={dashboardDetails?.TOTAL_MEMBERS_ENROLLED_BY_YOU}
                            text={"Members Enrolled"}
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                        <NumberCard
                        title={'Video Views'}
                            number={dashboardDetails?.total_video_views_by_all_members}
                            text="Total Videos Viewed by you"
                        />
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                <NumberCard
                                title={'Earnings'}
                                number={dashboardDetails?.total_video_views_by_you}
                                text="Total Videos Viewed by you"
                                />
                    </div>
                   
                </div>
            </Section>
            {/*
           <Section>
            <div className='trending_bg'>
            <TrendingVideos />
            </div>
            </Section>
            */}
        </>
    );
};

export default TraineeDashboardView;

