import React, {useEffect, useRef, useState} from "react";
import VideoJs from "./videoJs";
import videojs from 'video.js';
import Modal from "../modal";
import Text from "../text";
import Listen from "../listen";
import PremiumAccountSubscribeNow from "../premiumAccountSubscribeNow";
import useSubscribe from "../../pages/trainee/subscribe/hooks/subscribe.hook";
import useHelper from "../../hooks/helpers.hook";
import {FREEMIUM, FREEMIUM_MINIMUM_VIDEO_PLAY_SECONDS, GURU, PREMIUM} from "../../constants/app.constants";
import RecommendedVideosOverlay from "../../pages/trainee/main/view/RecommendedVideosOverlay";


const VideoPlayers = props => {
    const [showRecommendedVideos, setShowRecommendedVideos] = useState(false)
    const {isFreemiumMember} = useHelper()

    const {
        isModal,
        setIsModal,
        updatePresentVideoDuration,
        finishedPlayingVideo,
        getVideoDocId,
        goToNextVideo,
        isMyLearningPage,
        usertypeid
    } = props
    const {videofilename, videofiletype, videoduration, watchedduration, videoId} = props.videoDetails
    const playerRef = useRef(null);


    const videoSrc = videofilename ? process.env.REACT_APP_IMG_URL + videofilename : ''

    const videoJsOptions = {
        liveui: true,
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: videoSrc,
            type: videofiletype
        }],
    };


    const callVideoCreateDocId = () => {
        if(isMyLearningPage && usertypeid == PREMIUM){
        console.log('callVideoCreateDocId')
        getVideoDocId(sessionStorage.getItem('videoId'))
        }
    }

    const callVideoUpdateApi = (currentTime) => {
        //console.log('callVideoUpdateApi docId', '-', sessionStorage.getItem('docId'), '=', currentTime)
        if(isMyLearningPage && usertypeid == PREMIUM ){
        updatePresentVideoDuration(sessionStorage.getItem('docId'), currentTime)
        }
    }

    const callVideoFinishedApi = () => {
        if(isMyLearningPage && usertypeid == PREMIUM){
        console.log('callVideoFinishedApi')
        finishedPlayingVideo(sessionStorage.getItem('docId')).then(() => {
            if (isMyLearningPage) {
                //goToNextVideo()
            }
        })
         }
    }

    const playFirstVideo = (player) =>{
        
        if(isMyLearningPage && usertypeid == FREEMIUM){

            if(props.videoDetails.coursetype != 0){

                if(props.videoDetails.parent_priority == 0 && props.videoDetails.priority == 0){
                    
                }else{
                    console.log('onplay video else',props.videoDetails.parent_priority,props.videoDetails.priority);
                    player.pause();
                    player.controls(false);
                    setIsModal(true);
                }
            }
        }
    }

    const handlePlayerReady = (player, docId) => {
        let isVideoFinished = false
        playerRef.current = player;
        
        // You can handle player events here, for example:
        player.on('waiting', () => {
            videojs.log('player is waiting');
        });

     //   player.controlBar.progressControl.disable();


        player.on('dispose', () => {
            videojs.log('player will dispose');
        });

        player.on("loadedmetadata", function () {
            console.log('loadedmetadata duration', videoduration)
            console.log('loadedmetadata watchedduration', watchedduration)
            player.currentTime(watchedduration)
        });

        player.on("play", function () {
            
            if (isVideoFinished && Math.round(player.currentTime()) == 0) {
                
                if(isMyLearningPage && usertypeid == PREMIUM){
                        playFirstVideo();
                        isVideoFinished = false
                        callVideoCreateDocId()
                }else{
                    if(props.videoDetails.coursetype != 0){
                    playFirstVideo(player);
                    }
                }
                
                console.log('onplay video restarted')
            } else {
                playFirstVideo(player);
              
                console.log('onplay video else',props.videoDetails.parent_priority,props.videoDetails.priority)

            }
        });

        player.on("playing", function () {
        });
        player.on("ended", function () {
            
            isVideoFinished = true
            if(isMyLearningPage){
            callVideoUpdateApi(Math.round(player.currentTime()))
            callVideoFinishedApi()
            setShowRecommendedVideos(true)
            }
            document.exitFullscreen()
        });
        let updateApiCallCount = 0
        player.on("timeupdate", function () {

            console.log('timeupdate->', Math.round(player.currentTime()))
            updateApiCallCount++
            if (updateApiCallCount == 16) {
                if(isMyLearningPage){
                callVideoUpdateApi(Math.round(player.currentTime()))
                updateApiCallCount = 0
                }
            }
/*
            if (Math.round(player.currentTime()) >= FREEMIUM_MINIMUM_VIDEO_PLAY_SECONDS) {
                if (isFreemiumMember()) {
                    player.pause()
                    player.controls(false)
                    setIsModal(true)
                }
            }
    */
        });
    };


    return (
        <>
            <VideoJs options={videoJsOptions} onReady={handlePlayerReady} {...props}/>
            {showRecommendedVideos && (
                <RecommendedVideosOverlay
                    {...props}
                    setShowRecommendedVideos={setShowRecommendedVideos}
                    playerRef={playerRef}
                    isMyLearningPage={isMyLearningPage}/>
            )}
            {isModal &&
            <Modal

                light
                modalLarge
                onClose={() => setIsModal(false)}
                onOuterClose={() => setIsModal(false)}
                modalContent={
                    <>
                        <div className="row">
                            <div className="col-12 col-md-7">
                                <Text
                                    type="H2"
                                    className="font-weight-700 text-uppercase"
                                    text={props?.text?.TO_CONTINUE_WATCHING_SUBSCRIBE_TO_OUR_PREMIUM_SUBSCRIPTION}
                                />
                                <Text
                                    type="PARAGRAPH"
                                    className="mb-24"
                                    text={props?.text?.TO_CONTINUE_WATCHING_ALL_OUR_CONTENT_ON_REZKILAH_WE_ENCOURAGE_YOU_TO_SUBSCRIBE_TO_OUR_MONTHLY_SUBSCRIPTION_AND_ENJOY_ALL_OUR_SERVICES_AND_fEATURES}
                                />

                                <Listen
                                    listen={[
                                        props?.text?.EXECUTIVE_LEVEL_COURSES_FROM_TOP_UNIVERSITIES,
                                        props?.text?.FACILITATION_TO_GUIDE_AND_COACH_PARTICIPANTS,
                                        props?.text?.AI_POWERED_ANALYTICS_AND_INSIGHTS,
                                        props?.text?.MULTIMODAL_LEARNING,
                                        props?.text?.LIVE_SYNCHRONOUS_EVENTS,
                                        props?.text?.COURSE_ASSIGNMENT_WITH_CUSTOM_MESSAGING,
                                        props?.text?.MOBILE_ENABLED_PLATFORM,
                                        props?.text?.PREMIUM_LIST_7,
                                    ]}
                                />
                            </div>
                            <div className="col-12 col-md-5">
                                <PremiumAccountSubscribeNow setIsModal={setIsModal} {...props}/>
                            </div>
                        </div>
                    </>
                }
            />
            }


        </>
    );
}
export default VideoPlayers;
