import React from 'react';
import LearnByTopics from '../../../../components/learnByTopics';
import TotalTopicSearch from '../../../../components/total_topic_search';

const LearnByTopicsView = (props) => {
    
    return (
        <>  
      
            <LearnByTopics {...props}/>
        </>
    );
};

export default LearnByTopicsView;

