import {useState} from "react";
import {toast} from "react-toastify";
import useHttpHandler from "../../../../hooks/httphandler.hook";

const useLikedVideo = () => {
    const  {post} = useHttpHandler()
    const [loading, setLoading] = useState(false)
    const [videosListLoading, setVideosListLoading] = useState(false)
    const [videosList, setVideosList] = useState(null)
    const [categories, setCategories] = useState(null)

    const getDropDowns = ()=> {
        setLoading(true)
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                let topicOfInterest = data?.categories;

                setCategories(topicOfInterest)

            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        })
    }

    const getLikedVideos = (catId, page = null, subCatId = null)=> {
        setVideosListLoading(true)
        post('user_liked_videos', {category_id:catId, sub_category_id:subCatId}).then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                setVideosList(data)
            } else {
                toast.error(status.msg)
            }

            setVideosListLoading(false)
        }).catch(err => setVideosListLoading(false))
    }

    return {
        getDropDowns,
        loading,
        categories,
        videosListLoading,
        getLikedVideos,
        videosList
    }
}
export default useLikedVideo
