import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CourseCard from '../../../../components/courseCard'
import { useMediaQuery } from 'react-responsive';

import Status from "../../../../components/status";
import Breadcrumbs from '../../../../components/breadcrumbs';
import Pagination from '../../../../components/pagination';
import Table from '../../../../components/table';
import Text from '../../../../components/text';
import useHelper from '../../../../hooks/helpers.hook';
import Button from '../../../../components/button';
import Modal from '../../../../components/modal';
import RescheduleMeeting from '../../../../components/rescheduleMeeting/rescheduleMeeting';

const MyMeetingList = (props) => {
    const {formatDateDDMMYY, getShortDescription,onlyServerDateFormat,medicalSlotBlockDay,secondsToMinutes,todayYesterdayMonth} = useHelper();
	const {text, goToLearnerSignup, goToGuruSignup} = props
	const {formatDateMMMDDYYYY,formatDateDDMMYYHms} = useHelper()
    const [todayDate,setTodaydate] = useState(onlyServerDateFormat(new Date()))
    const tableData = props?.tableData?.listData
    const customData = props?.tableData?.customdata;
    const navigate = useNavigate();
    const [isReschedule,setIsReschedule] = useState(false);
    const [slotId,setSlotId] = useState(false);

  
    const [isModal, setIsModal] = useState(false);
    
    const breadcrumbList = [
        {
            text: 'Meeting',
            link: '/instructor/meetings',
        },
        {
            text:"Meeting List",
            link:"/instructor/meeting/meeting-list",
        },
    ]

 


  
     

     


  return (
    
    <>
    
       
    <div className='row p-2' style={{background:"#fff"}}>
    
    <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />


<Text
                type="H3"
                text="Total Meetings"
            />
           
           

            <Table
                thead={
                    <tr>
                        <th role="columnheader">Meeting Type</th>
                        <th role="columnheader">Slot Date</th>
                        <th role="columnheader">Slot Time</th>
                        <th role="columnheader">Amount</th>
                        <th role="columnheader">Meeting Timmings</th>
                        <th role="columnheader">Action</th>
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr role="row">
                                   <td role="cell">
                                   {item.course_id != 0 &&(
                                     item.coursetitle  + " (" + item.metting + ")"
                                   )
                                  }
                                  {item.course_id == 0 &&(
                                      item.metting
                                   )
                                  }
                                   
                                  
                                   
                                   </td> 
                                    <td role="cell">{formatDateMMMDDYYYY(item.slot_date)}</td>
                                    <td role="cell">{item.sub_title}</td>
                                    <td role="cell">{'$' + item.price}</td>
                                    <td role="cell">  
                                    {todayDate == onlyServerDateFormat(item.slot_date)  && (
                                        <>
                                            Starts in {secondsToMinutes(item.timeVaration)} 
                                        </>
                                    )}

                                    {todayDate != onlyServerDateFormat(item.slot_date)  && (
                                            <>
                                                Starts in {todayYesterdayMonth(item.slot_date)} 
                                            </>
                                    )} 

                                  
                        
                                </td>
                                <td>
                                {item.timeVaration >=5  && (

                                    <>
                                             <button className='resechulde-meeting-btn' onClick={()=>{
                                                setSlotId(item.idno);
                                                setIsReschedule(true)

                                             }} >Reschedule</button>
                                    </>
                                )}

{todayDate == onlyServerDateFormat(item.slot_date)  && (
                                       <>
                                        {item.timeVaration <=5   && (

                                                                            <>
                                                                                    <button className='start-meeting-btn' onClick={()=>{
                                                                                        navigate('/instructor/meeting/video-meeting');
                                                                                    }}>Start Meeting</button>
                                                                            </>
                                        )}
                                        </>
                                    )}

                                </td>
                            
                             
                                </tr>
                            )
                        })}
                      
                    </>
                }
            />
            <Pagination
                totalPages={props?.tableData?.totalPages}
                currentPage={props?.tableData?.currentPage}
                totalRecords={props?.tableData?.totalRecords}
                nextPage={props.nextPage}
                previousPage={props.previousPage}
                perPage={props.filterKeys.perpage}
            />
       

        </div>  
            


          {isReschedule &&

<>



<Modal
className={'modal_width'}
onClose={() => setIsReschedule(false)}
onOuterClose={() => setIsReschedule(false)}
modalContent={
<>
   <RescheduleMeeting />
</>
}
/>


</>  



}   



    </>
  )
}

export default MyMeetingList
    
CourseCard.defaultProps = {
    videoListOption: []
}
