import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useEffect, useState} from "react";
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {useSelector} from "react-redux";

const useChatWithInstructor = () => {
  
  
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [videosListLoading, setVideosListLoading] = useState(false)
    const [videosList, setVideosList] = useState([])
    const [videosApiData, setVideosApiData] = useState(null)
    const [mongoList,setMongoList] = useState([]);
    const [mongoIds,setMongoIds] = useState([]);
    const [onlineStatus,setOnlineStatus] = useState([]);
    
    
    var int = 0;

    useEffect(()=>{

        
        getData();
     },[mongoIds])

    

    

    const defaultFilters = {
        category_id: "1",
        page: 1,
        perpage: 1,
        fullname:"",
        perpage:6,

        
    }

    const [filterKeys, setFilterKeys] = useState(defaultFilters)

 

    const setFilters = (catId, page, fullname = null) => {
        setMongoIds([]);
                

             
        setVideosList([])
        let keys = {
            category_id: catId,
            fullname: fullname,
            page: page,
        }
        console.log('setFilters', keys)
        setFilterKeys({...filterKeys, ...keys})
        getVideos();
    }

    

   const getInstructorData = () =>{
    console.log("temp_arr-->",videosList);
   }

   const getData = () => {

    console.log("mongoIds",mongoIds)

    var obj = {
        "_id" : {
          "$in" : 
          mongoIds
        }
      }
     
        post('getInstructorsOnlineStatus', {"obj":obj}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setOnlineStatus(data);
            } else {

            }
        }).catch(err=>{})
   
 }

    const getVideos = () => {
        setVideosListLoading(true)
        post('userGetInstructors', filterKeys).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                setVideosApiData(data)
                let currentList = videosList
               
                if (filterKeys.page == 1) {
                    currentList = []
                }
              return  data?.listData?.map((item) => {
                    
                    
                    currentList.push(item)

                })

                
                var temp_arr = Array();
                for(var i=0;i<currentList.length;i++){
                    temp_arr.push(currentList[i].mongoId)
                }      
                setMongoIds(temp_arr);
                

             
                setVideosList(currentList)

                
                

               
            } else {
                toast.error(status.msg)
            }

            setVideosListLoading(false)
        }).catch(err => setVideosListLoading(false))
    }



    return {
        loading,
        videosListLoading,
        getVideos,
        videosList,
        filterKeys,
        setFilterKeys,
        setFilters,
        videosApiData,
        mongoList,
        setMongoList,
        mongoIds,
        getInstructorData,
        getData,
        onlineStatus
    }


}
export default useChatWithInstructor


