import React from "react";
import './pricingTable.css';

import AboutImg from '../../assets/images/subscribe.jpg';




const PricingTable =()=>{
    return(
        <>
        <section class="parallax section section-text-light section-parallax mt-0 mb-0">
            <h2 className="text-center fs-1 fw-semibold">Pricing Plans</h2>
    <div class="container mt-5">
        <div class="pricing-table row ">
            <div class="col-md-6 d-flex align-content-stretch ">
                <div class="plan">                    
                    <div class="plan-price bg-sect-grey">
                        <span class="price"> Freemium Plan </span>                            
                    </div>
                    <div class="plan-header bg-light py-4">
                        <h3 class="text-color-dark"> Start Learning for Free! </h3>
                    </div>
                    <div class="plan-features">
                        <ul>
                            <li> <b>Access to Basic Courses:</b> Gain entry to a selection of foundational courses to kickstart your 
                            learning journey.</li>
                            <li> <b>Community Support:</b> Join our community forums and connect with other learners for advice 
                            and support.</li>
                            <li> <b>Limited Content: </b> Access to a limited number of lessons and modules in each course.</li>
                            <li> <b>Basic Quizzes and Assignments: </b>Test your knowledge with our introductory quizzes and 
                            assignments.</li>
                            <li> <b>No Time Limits: </b> Enjoy unrestricted access to your enrolled courses without any time 
                            constraints.</li>
                            <li> <b>Earn Monthly Recurring Commissions:</b> Earn recurring monthly income for referring new 
                            subscribers. </li>
                          
                            
                        </ul>

                        <div className="p-2">
                        <p>Perfect for beginners and those wanting to explore our platform without any financial commitment.</p>
                        </div>

                        <div className="">
                        <img src={AboutImg} />
                        </div>
                       
                    </div>
                    <div class="plan-footer">
                        <a href="#" class="btn btn-dark btn-modern btn-outline py-2 px-4">Sign Up</a>
                    </div>
                </div>
            </div>

           
            <div class="col-md-6  d-flex align-content-stretch ">
                <div class="plan">
                <div class="plan-price  bg-color-prim">
                        <span class="price">Subscription Plan</span>                            
                    </div>
                    <div class="plan-header bg-sect-col py-4">
                        <h3 class="text-color-dark"> Unlock Your Full Potential! </h3>
                    </div>
                    <div class="plan-features bg-sect-col">
                        <ul>
                        <li> <b>Unlimited Course Access: :</b> Enjoy unlimited access to our entire course library, covering a wide 
                            range of topics and skill levels.</li>
                            <li> <b>Exclusive Content: </b> Access premium courses, advanced lessons, and specialized modules 
                            available only to subscribers.</li>
                            <li> <b>Priority Support: </b> Receive dedicated support from our expert instructors and customer service 
                            team.</li>
                            <li> <b>Personalized Learning Path:</b> Get customized course recommendations based on your learning 
                            goals and progress.</li>
                            <li> <b>Certification:</b> Earn certificates of completion for every course you finish, showcasing your 
                            achievements and skills.</li>
                            <li> <b>Interactive Quizzes and Assignments:</b> Enhance your learning with comprehensive quizzes, 
                            assignments, and feedback.</li>
                            <li> <b>Live Sessions and Workshops:</b> Participate in live video sessions, workshops, and Q&A with 
                            instructors.</li>
                            <li> <b>Downloadable Resources: </b>Download course materials, handouts, and additional resources for 
                            offline learning.</li>
                            <li> <b>Direct Chat Support: </b>Get instant help with course content through our chat feature, connecting 
                            you directly with instructors for guidance.</li>
                            <li> <b>Earn Monthly Recurring Commissions:</b> Earn recurring monthly income for referring new 
                            subscribers.</li>
                           
                        </ul>
                        <div className="p-2"> <p>Ideal for serious learners and professionals looking to gain in-depth knowledge and skills with full 
                        access to all our resources.</p> </div>
                    </div>
                   
                    <div class="plan-footer">
                        <a href="#" class="btn btn-dark btn-modern btn-outline py-2 px-4">Sign Up</a>
                    </div>
                </div>
            </div>
            <div class="col-md-4 d-none">
                <div class="plan plan-featured">
                    <div class="plan-header py-4">
                        <h3>Standard</h3>
                    </div>
                    <div class="plan-price">
                        <span class="price"><span class="price-unit">$</span>17</span>
                        <label class="price-label">PER MONTH</label>
                    </div>
                    <div class="plan-features">
                        <ul>
                            <li>3GB Disk Space</li>
                            <li>25GB Monthly Bandwith</li>
                            <li>5 Email Accounts</li>
                            <li>Unlimited Subdomains</li>
                        </ul>
                    </div>
                    <div class="plan-footer">
                        <a href="#" class="btn btn-primary btn-modern py-2 px-4">Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
        
        </>
    )
}

export default PricingTable;