import React, { useState } from "react";
import '../courseDetails/courseDetail.css';
import { Link, NavLink, useNavigate } from "react-router-dom";
import Select from "../input/select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import SelectNew from "../input/selectnew";
import { DEFAULT_FILTERS } from "../../constants/app.constants";
import Input from "../input";
import Button from "../button";


const TrainerCategoryBar =(props)=>{


    
    console.log("commonHook.getCategoryId-->",props._props.getCategoryId)
    const navigate = useNavigate();
    const {post} = useHttpHandler();
    const formik = useFormik({
        initialValues: {
            category: "",
            uname: "",
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Please enter a valid Categeory'),
            
        }),
        onSubmit: (values, actions) => {

            console.log("cat selected");
/*
            actions.setSubmitting(true)
            let postData = {
                
                category: values.category,
                subcategory: parseInt(values.subcategory),
            }

            post('instructorCreateCourse ', postData).then((res) => {
                actions.setSubmitting(false)
                const status = res.data.status
                const data = res.data.data
                if (status.result == '200') {
                    toast.success(status.msg)
                    navigate('/instructor/course')
                    
                }
            }).catch(err => {
                actions.setSubmitting(false)
            })
*/
        },

    });

  
  
    const getSubCategories = (e) => {
    
        formik.setFieldValue('category', e.value)

        props?._props.setCategoryId(e.value);
        console.log("e--->",formik.values.category)
       // 

        
    }
    
    const getSubCategoriesById = (catId) => {
        
        let subCategoryOptions = []
        if (props?.dropdownsData?.subCategories) {
            props?.dropdownsData?.subCategories.filter((item, index) => {
                if (item.parent_id == catId) {
                    subCategoryOptions.push({value: item.subcatId, label: item.subCatName,text: item.subCatName})
                }
            })
        }
        console.log("catId",catId,subCategoryOptions,props?.dropdownsData?.subCategoriesOptions);
        props._props?.setSubCategories(subCategoryOptions)
 
    }

    

    return(
        <>
    <div className="container">
    
   
    <form onSubmit={formik.handleSubmit}>
    <div className="row" style={ {padding:'10px'}}  >
    <div className="col-lg-6 col-md-2 col-sm-12 mt-10">
            
                <div className="p-0">
           

        <div className="">

       

      
            <SelectNew
                tertiary
                label="Category"
                name="category"
                placeholder="Select Category"
                value={formik.values.category}
                onChange={getSubCategories}
                onBlur={formik.handleBlur}
                mandatory
                options={props?.dropdownsData?.categoriesOptions}
                errormessage={formik.touched.category && formik.errors.category ? (
                    formik.errors.category
                ) : null}
            />
        </div>

                </div>
         
        </div>
    

        <div className="col-lg-6 col-md-2 col-sm-12 mt-10">
            
                <div className="row p-0">
            
                <div className="col-lg-9 col-md-2 col-sm-12 mt-10">
                    <div className="formBox">
                    
                    <Input
                            className="lineBottom"
                            formControlClassName="form-control"
                            label="Search With Name"
                            type="text"
                            name="uname"
                            placeholder="Search with name"
                            autoFocus={true}
                            value={formik.values.uname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={formik.touched.uname && formik.errors.uname ? (
                                formik.errors.uname
                            ) : null}
                        />
                    
                    </div>
                    </div>

                    <div className="col-lg-3 col-md-4 mt-30">
                <Button type="button" outline small-out text="Search" onClick={() => {
                           console.log("uname0------>",formik.values.uname);
                            if(formik.values.category == ""){
                                props._props.setFilters("0", 1,formik.values.uname)
                            }else{
                                props._props.setFilters(formik.values.category, 1,formik.values.uname)
                            }
                           
                        }}/>
                </div>
    
                </div>

               
         
        </div>
        </div>
</form>
    {/*
        <div className="col-lg-1 col-md-12 col-sm-12">
            <div className="ps-md-0 mb-3 mb-md-0 pe-4 me-4 d-flex flex-row align-items-center">
            <div className="p-1 ml1">
                    <p className="mb-1 text-1 font-weight-bold text-dark text-uppercase">{props.module}</p>
                    <h4 className="mb-0 text-center text-6 text-color-secondary">{props.value}<br/></h4>
                </div>
            </div>
        </div>
        <div className="col-lg-1 col-md-12 col-sm-12">
            <div className="ps-md-0 mb-3 mb-md-0 pe-4 me-4 d-flex flex-row align-items-center">
            <div className="p-1 ml2">
                    <p className="mb-1 mt-1 text-1 font-weight-bold text-dark text-uppercase">{props.availablevideos}</p>
                    <h4 className="mb-0 text-center text-6 text-color-secondary">{props.valuevideo} <br/></h4>
                </div>
            </div>
        </div>
        
                                */}    
    
    </div>


        
        </>
    )
}


export default TrainerCategoryBar;


