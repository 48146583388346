import React, {useEffect} from "react";
import useSubscribePremiumModal from "../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import LayoutPanel from "../../../components/layoutPanel";
import SubscribePremiumModal from "../../../components/subscribePremiumModal";
import LikedVideoView from "./view/LikedVideoView";
import useLikedVideo from "./hooks/likedVideo.hook";
import Loader from "../../../components/loader";
import GlobalInfoModal from "../../../components/globalInfoModal";
import useGuruUploadVideo from "../../instructor/uploadVideo/hooks/guruUploadVideo.hook";

const LikedVideo = props => {
    const likedVideoHook = useLikedVideo()
    const guruUploadVideoHook = useGuruUploadVideo()
    useEffect(() => {
        likedVideoHook.getLikedVideos(0)
        guruUploadVideoHook.getVideoDetailsById().then(()=>{
            guruUploadVideoHook.getDropDowns()
        })
    }, [])
    if (likedVideoHook?.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>
               {/** <SubscribePremiumModal {...props} /> */}

                <LikedVideoView {...props} guruUploadVideoHook={guruUploadVideoHook} {...likedVideoHook} />
            </LayoutPanel>
        </>
    )
}

export default LikedVideo;
