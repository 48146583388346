import React, { useState } from "react";
import Section from "../../../../components/section";
import Text from "../../../../components/text";
import CourseImg from "../../../../assets/img/logo.svg";
import CourseImg1 from "../../../../assets/img/logo.svg";
import CourseImg2 from "../../../../assets/img/logo.svg";
import { HiOutlineUsers } from "react-icons/hi";
import { MdOutlinePriceChange } from "react-icons/md";
import Flex from "../../../../components/flex";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "../../../../components/input/select";
import Pagination from "../../../../components/pagination";
import Input from "../../../../components/input";
import Button from "../../../../components/button";
import { NavLink } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";

const SubCategeoryPurchaseView = (props) => {
  const tableData = props?.tableData?.listData;
  const formik = useFormik({
    initialValues: {
      category: "",
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Please enter a valid study level"),
    }),
    onSubmit: (values, actions) => {},
  });

  const getFilterTopic = (e) => {
    props?._props.setcheckFilter(true);
    props?._props.setFilterId(e.target.value);
    formik.setFieldValue("category", e.target.value);
    getSubCategoriesById(e.target.value);
  };
  const [subCategories, setSubCategories] = useState(null);
  const getSubCategories = (e) => {
    formik.setFieldValue("category", e.target.value);
    getSubCategoriesById(e.target.value);
  };
  const getSubCategoriesById = (catId) => {
    let subCategoryOptions = [];
    if (dropdownsData?.subCategoriesOptions) {
      dropdownsData?.subCategoriesOptions.filter((item, index) => {
        if (item.parent_id == catId) {
          subCategoryOptions.push({
            value: item.subcatid,
            label: item.subCatName,
          });
        }
      });
    }
    setSubCategories(subCategoryOptions);
  };

  const { dropdownsData } = props?.guruUploadVideoHook;

  console.log(dropdownsData?.customData);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 py-3">
            <Link to={"/trainee/purchase-categeory"}>
              {" "}
              <AiOutlineArrowLeft color="#8081ff" />{" "}
              <Button linkPrimary text="Back to Category" />
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 ">
            <div class="purchase_card_sub text-bg-light bg-col-white h-100">
              <div class="container py-2">
                <div class="row">
                  <div class="col-md-1 col-sm-12 pt-2">
                    <img
                      src={
                        process.env.REACT_APP_IMG_URL +
                        dropdownsData?.customData.catPic
                      }
                      class="img-fluid"
                      alt="Card Image"
                    />
                  </div>
                  <div class="col-md-11">
                    <Text
                      type="H4"
                      className="mb-1 "
                      text={dropdownsData?.customData.catName}
                    />
                    <Text
                      type="PARAGRAPH"
                      className="mb-0"
                      text={
                        dropdownsData?.customData.catDesc.substring(0, 120) +
                        "...."
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div class="count_card">
              <div className="row">
                <div className="col-md-8">
                  <div className="d-flex flex-row">
                    <span className="text-gray mt-1">Sub-Categeories :</span>{" "}
                    <span className="text-gray font-weight-bold mt-1">
                      {dropdownsData?.customData.subCatCount}
                    </span>
                  </div>
                  <div className="d-flex flex-row">
                    <span className="text-gray mt-1">Price :</span>{" "}
                    <span className="text-gray font-weight-bold mt-1">
                      £{dropdownsData?.customData.amount}
                    </span>
                  </div>
                </div>
                <div className="col-md-4">
                  <Flex justifyContent="end">
                    <Link
                      to={
                        "/trainee/categeory-payment/" +
                        dropdownsData?.customData.catId
                      }
                    >
                      <button class="btn-primary btn-donate_1">
                        Purchase Now{" "}
                      </button>
                    </Link>
                  </Flex>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-8">
            <Text type="H2" text="SubCategeory" className="mb-2 text-start" />
            <Text
              type="PARAGRAPH"
              text="please Purchase any one Categeory to enjoy Learning and Earn Affiliate Income."
              className="mb-3 text-start"
            />
          </div>
          <div className="col-md-4">
            <div className="row pt-2">
              <div className="col-md-8">
                <form onSubmit={formik.handleSubmit}>
                  <div className="formBox-01">
                    <input
                      type="text"
                      label={props.search}
                      placeholder="Search for any topics..."
                      name="searchValue"
                      className="input-box mt-0 pl-2 w-100"
                      value={formik.values.searchValue}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      errormessage={
                        formik.touched.searchValue && formik.errors.searchValue
                          ? formik.errors.searchValue
                          : null
                      }
                    />
                  </div>
                </form>
              </div>
              <div className="col-md-4 pl-lg-0">
                <Button
                  type="submit"
                  text="search"
                  onChange={getFilterTopic}
                  className="btn-categeory-sub-search"
                  isSubmitting={formik.isSubmitting}
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-3">
          {dropdownsData?.subCategories?.map(function (item, index) {
            return (
              <>
                <div className="col-md-4">
                  <div class="card box-shadow-4">
                    <div class="card-img-top position-relative">
                      <img
                        src={process.env.REACT_APP_IMG_URL + item.image}
                        class="img-fluid w-100"
                        alt="Card Image"
                      />
                    </div>
                    <div class="card-body align-items-center mt-4 px-4">
                      <h4 class="font-weight-bold text-dark text-4 mb-1">
                        {item.subCatName}
                      </h4>
                      <p className="text-lightdark">{item.description}</p>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
        <Pagination
          totalPages={props?.tableData?.totalPages}
          currentPage={props?.tableData?.currentPage}
          totalRecords={props?.tableData?.totalRecords}
          nextPage={props?.nextPage}
          previousPage={props?.previousPage}
          perPage={props?.filterKeys?.perpage}
        />
      </div>
    </div>
  );
};

export default SubCategeoryPurchaseView;
