import {useStripe, useElements, PaymentElement} from '@stripe/react-stripe-js';
import '../styles/payment.scss';
import {useNavigate} from "react-router-dom";
const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();
    

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_URL +  "/trainee/payment-success",
      },
    });


    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log("result-->",result.error.message);
      navigate("/trainee/payment-failed")
    } else {

      console.log("result->",result);

      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button className='payment_button' disabled={!stripe}>Submit</button>
    </form>
  )
};

export default CheckoutForm;
