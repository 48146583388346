import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import {useDispatch, useSelector} from "react-redux";
import {setLearnerProfileDetails} from "../../../../../store/reducers/app.reducer";


const useProfile = () => {
    const dispatch = useDispatch()
    const [isModal, setIsModal ] = useState(false);
    const [dropdownsData, setDropdownsData] = useState(null)
    const [loading, setLoading] = useState(true)
    const profileDetails = useSelector(state => state.app.learnerProfileDetails)
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const goToEditProfile = () => {
        navigate('edit-profile');
    }
    const goToProfile = () => {
        navigate('../profile');
    }
    const openSubscribeNow = () => {
        setIsModal(true);
    }
    const getDropDowns = ()=> {
        setLoading(true)
        post('getCommonDropdownData').then((res)=>{
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                
                let occupationOptions = []

                let topicOfInterest = data?.categories;
                
                data?.occupation?.map((item, index)=>{
                    occupationOptions.push({value:item.occupationid, label: item.occupation})
                })
                
                setDropdownsData({
                
                    occupationOptions,
                    topicOfInterest,
                    
                })
            } else {
                toast.error(status.msg)
            }

            setLoading(false)
        }).catch(err => {setLoading(false);})
    }

    let selectedTopicsOfInterest = []

    const setSelectedOptions = (isSelected, optionId)=>{
        if(isSelected) {
            selectedTopicsOfInterest.push(optionId)
        } else {
            const index = selectedTopicsOfInterest.indexOf(optionId);
            if (index > -1) { // only splice array when item is found
                selectedTopicsOfInterest.splice(index, 1); // 2nd parameter means remove one item only
            }
        }
    }

    const getProfileDetails = ()=> {
        setLoading(true)
        post('get_member_details').then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                localStorage.setItem('currentUserTypeId',data.usertypeid)
                dispatch(setLearnerProfileDetails(data))
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {setLoading(false);})
    }
    return {
        goToEditProfile,
        goToProfile,
        loading,
        profileDetails,
        getProfileDetails,
        dropdownsData,
        selectedTopicsOfInterest,
        getDropDowns,
        setSelectedOptions,
        isModal,
        setIsModal,
        openSubscribeNow
    }
}
export default useProfile


