import React from "react";
import SignupLayout from '../../../components/signuplayout';
import OTPVerificationView from './view/OTPVerificationView';

const OTPVerification = props => {
    return (
        <>
            
                <OTPVerificationView {...props} />
            
        </>
    )
}

export default OTPVerification;
