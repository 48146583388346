import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Image from '../../../../components/image';
import RecentImage from '../../../../assets/images/video-details-img.jpg';
import Dropdown from '../../../../components/dropdown';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate, useParams} from "react-router-dom";

import {useSelector} from "react-redux";
import Icon from '../../../../components/icon';
import PlayIcon from '../../../../components/svg/playIcon';
import CoursePage from '../../../trainee/courses';
import CourseMainBar from '../../../../components/courseMainbar';
import {Popconfirm,Result,Tag } from 'antd';
import VideoPlayer from '../../../../components/videoplayer';
import ShareVia from '../../../../components/shareVia';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import Modal from '../../../../components/modal';
import rightIcon from '../../../../assets/img/rightIcon.png'
import { DEFAULT_FILTERS, SECRETKEY, SOCKET_URL, socket } from '../../../../constants/app.constants';
import SwitchButton from '../../../../components/switchButton';
import ChatSwitchButton from '../../../../components/ChatswitchButton';
import {toast} from "react-toastify";

import Input from '../../../../components/input';
import { useFormik } from 'formik';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import { io } from "socket.io-client";
import ChatOnlineStatus from '../../../../components/chatOnlineStaus';

import { CgAdd } from "react-icons/cg";


import CryptoJS from "crypto-js";
import InstructorCard from '../../../../components/instructorCard';
import ChatTimerCard from '../../../../components/chatTimerCard';
import DashboardBoxes from '../../../../components/dashboardBoxes';
import noOfMeeting from '../../../../assets/img/noOfMeeting.png'
import meetingRevenue from '../../../../assets/img/meetingRevenue.png'
import meetingTotalRevenue from '../../../../assets/img/meetingTotalRevenue.png'
import createMeetingNew from '../../../../assets/img/createMeetingNew.png'





const MeetingDashBoardView = (props) => {
    
    const { goToLearnerSignup} = props
    const {getYearOptions,post} = useHelper()
    const viewsOption = [{id: 'views', value: 'Views'}, {id: 'likes', value: 'Likes'},]
    const [viewsSelected, setViewsSelected] = useState(viewsOption[0]);
    const yearsOption = getYearOptions()
    const [yearSelected, setYearSelected] = useState(yearsOption.at(-1));
    const {dashboardDetails} = props?.guruDashboardHook
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()
   

   

  


    return (
        <>
            
            <div className="row top-main-boxes course-main-box"  >

                  <div className="row all-main-box"  style={ {marginLeft: '0px',padding:'0px',marginTop:'20px',marginBottom:'20px'}}>

                

                          <div className="col-12 col-md-3">
                              <DashboardBoxes
                              icon={CgAdd}
                              img={createMeetingNew}
                                  title="Create Meeting"
                                number={"$"}
                                  onClick={() => {
                                    navigate('/instructor/meeting/create-meeting')
                                  }}
                                  {...props}
                              />
                          </div>

                  <div className="col-12 col-md-3">

                            <DashboardBoxes
                                icon={0}
                                img={noOfMeeting}
                                  title="No Of Meeting"
                                  number={kFormatter(dashboardDetails?.thisMonthEarned)}
                                  onClick={() => {
                                    navigate('/instructor/chat-dashboard/earning-month')
                                  }}
                                  {...props}
                              />     

                             
                          </div>


                          <div className="col-12 col-md-3">

                          

                          <DashboardBoxes
                                icon={0}
                                img={meetingRevenue}
                                  title="This Month Revenue"
                                  number={kFormatter(dashboardDetails?.thisMonthEarned)}
                                  onClick={() => {
                                    navigate('/instructor/chat-dashboard/earning-month')
                                  }}
                                  {...props}
                              />     
                             
                          </div>

                          <div className="col-12 col-md-3">
                              <DashboardBoxes
                              icon={0}
                              img={meetingTotalRevenue}
                                    title="Total Revenue"
                                  number={kFormatter(dashboardDetails?.top_total_likes)}
                                  onClick={() => {
                                      navigate('/instructor/meeting/total-earnings')
                                  }}
                                  {...props}
                              />
                          </div>


                   
                      
  
                      </div>        
   
         
  
  
              </div>
            {/*props.titlesObj.showDashboard == 1 &&(
                  
            )

            */}
          


       
           

                                 


                    
       
        </>
    );
};


export default MeetingDashBoardView;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}
