import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";

import useHelper from "../../../hooks/helpers.hook";
import {useSelector} from "react-redux";



import Loader from "../../../components/loader";
import useChatHook from "../chat/hooks/chat.hook";

import CourseDashboardView from "./view/courseDashboardView";
import LayoutPanel from "../../../components/layoutPanel";



const   CoursesDashboard = props => {

    const userInfo = useSelector(state => state.app.userInfo)
    
     const chatHook = useChatHook();

     const [startTime,setStartTime] = useState(0);

    // const titlesObj = {title: "Chat Board",showDashboard:1}

     const popupEvets = (type) => {
        
      }
    
      useEffect(() => {
      
    }, [])
      


 

    
    return (
        <>
              <LayoutPanel {...props}  footer>
                
                { /*chatHook.isModal &&(<Modal  
               
                onOuterClose={() =>  chatHook.setIsModal(false)}
                
                modalContent={
                    <>
                     <ChatConfirmInstructor {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook}  />         </>
                }
            />) */}
            
           
                <CourseDashboardView {...props} userInfo={userInfo}   />
            </LayoutPanel>
        </>
    )
      
}

export default CoursesDashboard;
