import React,{useContext,useEffect,useRef,useState} from 'react'
import LayoutGuru from '../../../components/layoutGuru';
import useGuruUploadVideo from "../uploadVideo/hooks/guruUploadVideo.hook";
import Loader from "../../../components/loader";
import ModulePageView from './views/modulePageView';
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";
import { useNavigate, useParams } from 'react-router-dom';
import NewModulePageView from './views/newModulePageView';
import { NestedListComponent } from './views/DragComponent/NestedListComponent';


//import {Modal, Popconfirm,Tag } from 'antd';
import VideoPlayer from '../../../components/videoplayer';
import CourseMainBar from '../../../components/courseMainbar';
import useGuruDashboard from '../dashboard/hooks/guruDashboard.hook';
import Button from '../../../components/button';
import Modal from '../../../components/modal';
import ShareVia from '../../../components/shareVia';
import { URL } from '../../../constants/app.constants';

const ModuleUploadpage = (props) => {
    
    const commonHook = useVideosCommonHooks()
    const {courseId} = useParams(); 
    const navigate = useNavigate()
    const [isVideoModal, setIsVideoModal] = useState(false);
    const [vidoePlayUrl, setVidoePlayUrl] = useState("");
    const guruDashboardHook = useGuruDashboard()
    const [isShareModal, setIsShareModal] = useState(false);
    const closeShare = () => {
        setIsShareModal(false);
    };
    const playVideo = (val) => {
        console.log(val);
        setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
        setIsVideoModal(true);
    };

    useEffect(() => {

        

            
                
                commonHook?.promiseServiceNonLoading({"uri":"instructorCourseModuleList",'courseid':courseId,"levelid":0}).then((data) => {
                    commonHook.setCustomData(data.data.data)
                    commonHook.getDropDowns().then(()=>{

                    })
                });
            
            
           
        
    },[])

    if(commonHook.dropdownsData == null){
  
        return <Loader/>
    }
    
    else{
        
        return (
            
                <LayoutGuru {...props}>
                  
                   <div className="row">
          <div className="col-12">
         <Button
         type="primary"
         style={{
          marginBottom: 10, marginTop : "10px"
         }}
         text="PREVIEW COURSE"
         onClick={() => {
            navigate('/instructor/course-preview/' + courseId +'-0')
        }}
        />
        </div>
</div>
       
                   <CourseMainBar index={100} playVideo={playVideo} data={commonHook.serviceData.course_details} showButtons={false}  setIsShareModal={setIsShareModal}/>     
                    <div style={{marginTop:'-20px'}}>
                    <NestedListComponent {...props}  playVideo={playVideo} data={commonHook.serviceData} {...commonHook} courseId={courseId}  guruDashboardHook={guruDashboardHook} setLevel={commonHook.setLevel} setCustomData={commonHook.setCustomData}/>
                    </div>


                    {isShareModal &&
                <>
                <Modal
                                    className="modal-white"
                                    canClose={closeShare}
                                    onClose={closeShare}
                                    modalContent={
                                    
                                        <ShareVia {...props} affiliateLink={URL  + courseId }/>

                                    }
                                />


                </>
        }


                   {isVideoModal &&

<>

<Modal
    onClose={() => setIsVideoModal(false)}
    onOuterClose={() => setIsVideoModal(false)}
    modalContent={
        <>
            <VideoPlayer
                videourl={vidoePlayUrl}
                width="100%"
                height="460"
            />
        </>
    }
/>
</>  

}      
         </LayoutGuru>
        
        )
    }
}

export default ModuleUploadpage;
