import React from "react";
import '../courseDetails/courseDetail.css';
import Button from '../button'
import { NavLink, useNavigate } from "react-router-dom";
import ViewContent from "../viewContent";
import DP from '../../assets/img/no_img.webp';

const LearnerCard =(props)=>{
	const navigate = useNavigate();
	
	 
    return(
        <>
	<div class="container">
	<div class="row d-flex flex-wrap pb-2 bg-light favourite_instructor_card">
		<div class="position-relative col-12 col-md-3 lazyloaded">
			<img src={props.imgsrc ? process.env.REACT_APP_IMG_URL+props.imgsrc : DP} className="img-fluid faourite_img mt-2" alt="instructor image" />
		</div>
		<div class="col-md-9">
			<div class="row mt-5">
				<div class="col-md-4">
					<div class="ps-md-0 border-right align-items-center h-100">
						<div class="p-0">
							<p class="mb-0 text-center text-4 font-weight-bold text-dark text-uppercase">{props.course}</p>
							<h4 class="mb-0 text-center text-color-secondary">{props.coursetitle}</h4>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="ps-md-0 border-right align-items-center h-100">
						<div class="p-0">
							<h4 class="mb-1 text-center text-4 font-weight-bold text-dark text-uppercase">{props.module}</h4>
							<h5 class="mb-0 text-center text-color-secondary">{props.value}<br/><ViewContent  title={props.viewcontent} link={props?.link}/></h5>
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="ps-md-0 flex-grow-1 align-items-center h-100">
						<div class="p-0">
							<h4 class="mb-1 text-center text-4 font-weight-bold text-dark text-uppercase">{props.availablevideos}</h4>
							<h5 class="mb-0 text-center text-color-secondary">{props.valuevideo} <br/><ViewContent  title={props.viewcontent1} link={props?.link}/></h5>
						</div>
					</div>
				</div>
			</div>
			
			<div class="col-12">
				<p class="text-2 text-start text-dark">{props.desc}</p>
				</div>
		</div>
		
	</div>
	</div>
        </>
    )
}


export default LearnerCard;

