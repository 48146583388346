import React from "react";
import './joinGuru.scss';
import JoinusGuruImage from '../../assets/img/logo.svg'
import Text from "../text";
import Button from "../button"
import Image from "../image";
import Listen from "../listen"
import useLogin from "../../pages/authentication/login/hooks/login.hook";
import {useSelector} from "react-redux";

const JoinGuru = props => {

    const userInfo = useSelector(state => state?.app?.userInfo)
    const {text} = props
    const {convertUser, isSwitchingUser} = useLogin()

    if (userInfo?.user_status_id != 1) {
        return (
            <>
                <div className="join-guru-wrapper">
                    <div className="row">
                        <div className="col-12 col-md-5">
                            <Image
                                src={JoinusGuruImage}
                                alt="Join us now"
                            />
                        </div>
                        <div className="col-12 col-md-7">
                            <Text
                                type="H3"
                                text={props?.PLANNING_TO_SHARE_YOUR_KNOWLEDGE_JOIN_US_NOW_AND_BECOME_A_GURU}
                            />
                            <Listen
                                listen={[
                                    {
                                        text: 'Teach your own way',
                                        description: 'Publish the educational video and control your own video contents',
                                    },
                                    {
                                        text: 'Inspire members',
                                        description: 'Teach what you know and help members gain new skills',
                                    },
                                    {
                                        text: 'Get rewarded',
                                        description: 'Expand your professional network, build your expertise, and earn money',
                                    },
                                ]}
                            />
                            <Button
                                large
                                text="Sign up as guru"
                                isSubmitting={isSwitchingUser}
                                onClick={() => {
                                    convertUser('guru')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </>
        )
    } else {
        return <></>
    }

}

export default JoinGuru;
