import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import VideoPlayers from '../../../../components/videoPlayers';
import VideoPlayersDetails from '../../../../components/videoPlayersDetails';
import SidePanelLists from '../../../../components/sidePanelLists';
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import TopicsYouAreInterested from "../../dashboard/components/TopicsYouAreInterested";
import LikedVideos from "../../dashboard/components/LikedVideos";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import AccordionCourse from '../../../../components/accordionCourse';
import VideoPlayer from '../../../../components/videoplayer';

import { useSelector } from 'react-redux';
import { GURU } from '../../../../constants/app.constants';

import HeroSlider from '../../../../components/heroslider';
import CourseDetailsPage from '../../../../components/courseDetailsPage';
import LineModule from '../../../../components/lineModule';
import Button from '../../../../components/button';
import Icon from '../../../../components/icon';
import PlayIcon from '../../../../components/svg/playIcon';
import ParticipateQuiz from '../../../instructor/addQuiz/views/participateQuiz';
import { Modal } from 'antd';
import ParticipateQuizMainFrame from '../../../instructor/addQuiz/views/participateQuizMainFrame';
import MyMeetingSlots from '../../../../components/myMeetingSlots/myMeetingSlots';


const CourseMainView = (props) => {
    const {isSideOpen, onOpenIsSide, videoDetails, getVideoDocId, docId, watchNextPremium, isMyLearningPage} = props;
    const {courseId} = useParams()
    var ids = courseId.split("-");
    const avatarImage = videoDetails?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + videoDetails?.uploaded_by_pic : AvatarPic

    var modulesData = [];
    var topicsData = []    
    const [isShowPopUP,setisShowPopUP] = useState(false);
    const [isPopType,setIsPopType] = useState("");

    
    const [isVideoModal, setIsVideoModal] = useState(false);
const [vidoePlayUrl, setVidoePlayUrl] = useState("");

const playVideo = (val) => {
  console.log(val);
  setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
  setIsVideoModal(true);
};

const closeVideo = () => {
    setIsVideoModal(false);
};

    var breadcrumbList = []
    
    const userInfo = useSelector(state => state?.app?.userInfo);

    if(userInfo?.usertypeid === GURU){

        breadcrumbList =  [
            {
                text: 'Courses',
                link: '/instructor/course',
            },
            {
                text: props?.videoDetails.videocategoryname,
                link: '/instructor/course-preview/'+ids[0] + "-0",
            },
            /*
            {
                text: videoDetails?.videocategoryname,
                link: isMyLearningPage ? '/instructor/course-video-details/' + videoDetails?.videoid : '/instructor/course-video-details/' + videoDetails?.videoid,
            },*/
        ]    

    }else{

            breadcrumbList =  [
                {
                    text: 'Main page',
                    link: '/trainee/dashboard',
                },
                {
                    text: videoDetails?.videocategoryname,
                    link: isMyLearningPage ? '/trainee/my-learning/video-details/' + videoDetails?.videoid : '/trainee/video-details/' + videoDetails?.videoid,
                },
            ]
    
    }

    useEffect(() => {
        if(isMyLearningPage){
      //  getVideoDocId(videoDetails?.videoid)
        }

    }, [])


     
    if(props?.getCourseData.length>=1){
        modulesData = [];
        console.log("props?.getCourseData?.data",props?.videoDetails)

        var val = 0;
           
                        props?.getCourseData?.map(function (item, index) {
                            
                                if(item?.data?.length >=1){

                                    val = 0;    
                                    item?.data?.map(function (ldata, lindex) {

                                    var obj={"moduletitle":ldata.category_name,"srno":ldata.category_id,"videos":ldata?.videos,"thumbnail":ldata?.thumbnail,"introvideo":ldata?.introvideo,"desc":ldata?.desc,"attacheddoc":ldata?.attacheddoc}    

                                    for(var i = 0;i<modulesData.length;i++){

                                        if(modulesData[i].srno == ldata.category_id){
                                            val = 1;
                                        }
                                    }
                                    if(val == 0){
                                        if(ldata.videos.length >=1){
                                        modulesData.push(obj);
                                        }
                                    }    
                                    })
                                }
                            
                           
                        
                        })

                        modulesData.map(function (item, index) {
                            
                            item?.videos?.map(function (vdata, vindex) {
                                console.log("vdata",vdata);
                                var obj={"moduleid":item.srno,"title":vdata.title,"videoid":vdata.videoid,"thumbnail":vdata.thumbnail,"des":vdata.des}    
                                topicsData.push(obj);

                            });


                        });


                        
                        console.log("ldata",modulesData)

    }


    

    return (
        <>
        
            {isMyLearningPage && (
                <>
                <Breadcrumbs
                    breadcrumbList={breadcrumbList}
                />

                <Text type="H2" text="MY LEARNING" className="mb-20"/>
                </>
            )}

            {userInfo?.usertypeid === GURU &&(
                <>
                <Breadcrumbs
                    breadcrumbList={breadcrumbList}
                />

                    {ids[1] == 0 &&(

                            <Text type="H2" text={"Course : " + props?.videoDetails.coursetitle } className="mb-20"/>
                    )   
                    }

                    {ids[1] != 0 &&(

                    <Text type="H2" text={"Course : " + props?.videoDetails.coursetitle + " / " + props?.videoDetails?.moduletitle} className="mb-20"/>
                    )   
                    }


                
                </>

            )}
            
            {/*
            
            <div className="video-details-main-wrapper">
                <div className='row'>
                    
                </div>
                {isMyLearningPage && (
                    <div className={`video-details-main-side ${!isSideOpen ? 'video-details-main-side-active' : ''}`}>
                        <SidePanelLists
                            {...props}
                            isSideOpen={!isSideOpen}
                            onOpenIsSide={!onOpenIsSide}
                            getAllVideosWatched={props.getAllVideosWatched}
                            usertypeid = {userInfo?.usertypeid}
                            setVidoePlayUrl={setVidoePlayUrl}
                            setIsVideoModal={setIsVideoModal}
                        />
                    </div>


                )
                }
                
                <div className={`video-details-main-right ${!isSideOpen ? 'video-details-main-right-active' : ''}`}>
                    <div className="vpd-watch-video">

                    <HeroSlider options={[{"videofilename":videoDetails.introvideo}]} {...props}  />

                    <CourseDetailsPage
                       
                        title={videoDetails?.title}
                    
                        description={videoDetails?.desc}
                        
                        {...props}
                    />
                        
                    
                      
                    </div>
                    




                     {topicsData?.map(function (topic, topicIndex) {
            
                if(topic.moduleid == ids[1]){
                    return (
                    <LineModule {...topic}/>
                    )
                }
              })}


                </div>
            </div>
            */}



                <div className='row'>
                
                <div className='col-md-4' >
                
              
                   
                        <SidePanelLists
                            {...props}
                            isSideOpen={!isSideOpen}
                            onOpenIsSide={!onOpenIsSide}
                            getAllVideosWatched={props.getAllVideosWatched}
                            usertypeid = {userInfo?.usertypeid}
                            setVidoePlayUrl={setVidoePlayUrl}
                            setIsVideoModal={setIsVideoModal}
                        />
                  


                
                
                </div>
                <div className='col-md-8' style={{padding:'0px'}}>
                
              
                   

                    <HeroSlider options={[{"videofilename":videoDetails.introvideo}]} {...props}  />

                    <CourseDetailsPage
                       
                        {...props}

                        {...videoDetails}

                        ids={ids}

                        setisShowPopUP = {setisShowPopUP}

                        setIsPopType = {setIsPopType}

                    />

                    <MyMeetingSlots courseId={courseId}/>
                        
                    

</div>
                
                            </div>
             
        
            
            

            {isVideoModal &&

                <Modal
                    className=""
                    canClose="onClose"
                    onClose={closeVideo}
                    onOuterClose={closeVideo}
                    modalContent={
                    
                        <VideoPlayer
                            videourl={process.env.REACT_APP_IMG_URL + vidoePlayUrl}
                            width="100%"
                            height="460"
                        />

                    }
                />

            }


               {isShowPopUP &&(
             <>
                  
                    <Modal
                      closable={false}
                      title=""
                      centered
                      open={isShowPopUP}
                      onOk={() => setisShowPopUP(false)}
                      onCancel={() => setisShowPopUP(false)}
                    
                      okButtonProps={{ style: { display: 'none' } }}
                      width={window.innerWidth - window.innerWidth/4}
                      zIndex={10000}
                    >

                  

              

                            <ParticipateQuizMainFrame moduleId={ids[1]}/>
                
                  
               


                    </Modal>
            </>)
            
      }          

        </>
    );
};

export default CourseMainView;

