import React from "react";
import Button from "../button";
import Text from "../text";
import './noVideoUploadedYet.scss';
import VideoPlusIcon from '../svg/videoPlusIcon';
import {useNavigate} from "react-router-dom";
import Flex from "../flex";

const NoVideoUploadedYet = props => {
    const navigate = useNavigate();
    const goToUploadVideo = () => {
        navigate('/instructor/create-course')
    }
    return (
        <div className="no-video-uploaded-yet-wrapper">
            <div className="row justify-content-center">
                <div className="col-12 col-md-7 mx-auto">
                    <Text
                        type="H3"
                        text="No Video uploaded yet"
                    />
                    <Text
                        type="PARAGRAPH"
                        text="It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using"
                    />
                    <Flex justifyContent="center">
                        <Button
                            text="UPLOAD VIDEO"
                            iconLeft={<VideoPlusIcon width="23" height="16"/>}
                            onClick={goToUploadVideo}
                        />
                    </Flex>
                </div>
            </div>
        </div>
    )
}

export default NoVideoUploadedYet;
