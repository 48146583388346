import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import EyeIcon from "../../../../components/svg/EyeIcon";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import FacebookIcon from "../../../../components/svg/facebookIcon";
import GoogleIcon from "../../../../components/svg/googleIcon";
import AppleIcon from "../../../../components/svg/appleIcon";
import {useFormik} from "formik";
import OtpInput from "otp-input-react";
import * as Yup from "yup";
import Checkbox from '../../../../components/checkbox';
import {useNavigate} from 'react-router-dom';
import {DEFAULT_COUNTRY_ID, FREEMIUM, GURU} from "../../../../constants/app.constants";

import useHttpHandler from "../../../../hooks/httphandler.hook";
import useLogin from "../../../authentication/login/hooks/login.hook";
import useGuruRegister from '../hooks/register.hook';
import PhoneInput, {isValidPhoneNumber} from 'react-phone-number-input'
import logoImg from '../../../../assets/userimg/login-bg2.png';
import Modal from "../../../../components/modal";
import TermsCondition from '../../../../components/terms-condition';

import OTPVerificationView from '../../otpVerification/view/OTPVerificationView';
import Textarea from '../../../../components/input/textarea';
import { IoHome,IoShareSocial } from "react-icons/io5";

const RegisterView = (props) => {
    const navigate = useNavigate();
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isModal, setIsModal] = useState(false);
    const [isExist,setIsExist] = useState(false);
    const {
        redirectTo,
        storeUserInfo,
        setUserSessionInfo,
        lowercaseRegex,
        uppercaseRegex,
        numericRegex,
        nonalphanumeric,
        numbersOnly,
        phoneNumbersFormat
    } = useLogin()



    const {
        registerWithGoogle,
        registerWithFacebook
    } = props;


    const {post} = useHttpHandler()


    const {
        showPassword,
        ShowPasswordClick,
        showConfirmPassword,
        ShowConfirmPasswordClick,
        goToRegisterAsLearner
    } = useGuruRegister()
    const {text} = props
    const formik = useFormik({
        initialValues: {
            uname: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
            acceptTerms: false,
            fullname:'',
        },
        validationSchema: Yup.object({
            uname: Yup.string().required('Please enter a valid username'),
            email: Yup.string().email('Please enter a valid email address').required('Please enter a valid email address'),
            mobile: Yup.string()
                .required('Please enter a valid phone number')
                .min(12, 'Phone number should be minimum 9 digits')
                .max(13, 'Phone number should be less than 10 digits')
                .matches(phoneNumbersFormat, 'Please enter a valid phone number'),
            password: Yup.string().min(6, 'Your password must be atleast 6 digits')
                .matches(uppercaseRegex, 'Atleast one capital letter required')
                .matches(lowercaseRegex, 'Atleast one small letter required')
                .matches(numericRegex, 'Atleast one number required')
                .matches(nonalphanumeric, 'Atleast one Special Character required')
                .required('Please enter a password'),
            confirmPassword: Yup.string().min(6, 'Your password must be atleast 6 digits').required('Please re-enter password').oneOf([Yup.ref('password'), null], 'Passwords must match'),
            acceptTerms: Yup.bool().oneOf([true], 'Please accept Terms of use')
        }),
        onSubmit: (values, actions) => {
            
           
            const isd = values.mobile.substring(0, 3)
            const newPhone = values.mobile.substr(3);

            let data = {
                country_id: isd,
                usertype_id: GURU,
                mobile: newPhone,
                email: values.email,
                uname: values.uname,
                password: values.password,
                ref_code: sessionStorage.getItem('referralCode') | 1
            }
            console.log("formData",data)
            

           post('userRegistration', data).then((res) => {
                actions.setSubmitting(false);
                const status = res.data.status
                const data = res.data.data
                console.log(status)
                if (status.result == '200') {

                    setUserSessionInfo(data).then(() => {
                        redirectTo(data.redirectcode, data.usertypeid)
                    })

                } else {
                    console.log("the username is already registered-->1");
                    setIsExist(true);
                    props.toast.error("the username is already registered")
                    
                }
            }).catch(err => {
                actions.setSubmitting(false);
            })
           
        },
    });
    useEffect(() => {
        localStorage.removeItem('token')
        sessionStorage.removeItem('isConvertUserFlow')
    }, [])

    useEffect(() => {
        formik.setFieldValue('mobile', phoneNumber)
    }, [phoneNumber])


   



    return (
        <>

            {isModal &&(
                <Modal
                    className="modal-otp-right-area"
                    onClose={() => setIsModal(false)}
                    onOuterClose={() => setIsModal(false)}
                    
                    modalContent={<div class="row" style={{width:"100%"}}>
                                    <TermsCondition/>
                    </div>}
                />
            )
            }


          
        <div class="register">
        

<main> 

  <section class="section register-view d-flex flex-column align-items-center justify-content-center">
      <div class="container">
      <div className='row p-lg-0 py-2'>
            <div className='col-12'>
            <div class="feature-box-icon">
            

            <Button
                              link
                              text={
                                <IoHome className="text-color-light fs-2 " /> 
                              }
                              onClick={() => {
                                //navigate("/trainee/signup")
                                navigate("/");
                              }}
                              type="button"
                            />
        </div> 
                  </div>
            </div>
        <div class="row " >
        {/* justify-content-center float-right */}
          <div class="col-lg-6 col-md-5">
          <div className='branding-logo'>
            <img src={logoImg} className='img-fluid' alt='logo' /> 
          </div>
          
             </div>

           

        
          <div class="col-lg-6 col-md-12 d-flex flex-column align-items-center justify-content-center">
            
            <div class="card mb-3 register_flow"  >
            
              <div class="card-body">

                <div class="">
                  <h5 class="card-title line-height-2 text-center pb-0 fs-4">Instructor Account</h5>
                  <p class="text-center small">Enter your personal details to create account</p>
                </div>            

            <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                    <div className='col-md-6'>
                    <div className="formBox">
                    <Input
                        autoFocus={true}
                        label={text?.USERNAME}
                        type="text"
                        name="uname"
                        placeholder=""
                        value={formik.values.uname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.uname && formik.errors.uname ? (
                            formik.errors.uname
                        ) : null}
                    />
                </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                    <Input
                        label={"Nick Name"}
                        type="text"
                        name="fullname"
                        placeholder=""
                        value={formik.values.fullname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        
                        errormessage={formik.touched.fullname && formik.errors.fullname ? (
                            formik.errors.fullname
                        ) : null}
                    />
                </div>
                    </div>
                  
                    <div className='col-md-6'>
                    <div className="formBox">

<div className="form-field mb-0">
    <label className="form-label">Phone Number <span
        className="text-danger">*</span></label>
   <PhoneInput
    className={formik.touched.mobile && formik.errors.mobile ? 'error' : ''}
    defaultCountry={'GB'}
    countries={['GB','UA','IN']}
    placeholder="Enter phone number"
    value={phoneNumber}
    onChange={setPhoneNumber}
    countryCallingCodeEditable={false}
    addInternationalOption={false}
    limitMaxLength="10"
    international
/>
</div>

<div className="formBox hiddenInput">
    <Input
        type="hidden"
        maxlength="10"
        name="mobile"
        placeholder=""
        value={formik.values.mobile}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        mandatory
        errormessage={formik.touched.mobile && formik.errors.mobile ? (
            formik.errors.mobile
        ) : null}
    />
</div>
</div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                    <Input
                        label={text?.EMAIL_ADDRESS}
                        type="text"
                        name="email"
                        placeholder=""
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.email && formik.errors.email ? (
                            formik.errors.email
                        ) : null}
                    />
                </div>
                    </div>
                    <div className='col-md-12'>
                    <div className="formBox">
                        {/*
                    <Input
                        label={"Location"}
                        type="text"
                        name="email"
                        placeholder=""
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        mandatory
                        errormessage={formik.touched.email && formik.errors.email ? (
                            formik.errors.email
                        ) : null}
                    />*/}

                    <Textarea
                    tertiary
                    label="Location"
                    type="text"
                    name="description"
                    placeholder="Write your description here..."
                    value={formik.values.description}
                    onBlur={formik.handleBlur}
                    mandatory
                    rows="5"
                    errormessage={formik.touched.description && formik.errors.description ? (
                        formik.errors.description
                    ) : null}
                    />
                
                </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                    <Input
                        label={text?.PASSWORD}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        placeholder="********"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.password && formik.errors.password ? (
                            formik.errors.password
                        ) : null}
                        icon={
                            <span onClick={ShowPasswordClick}><EyeIcon visible={showPassword}/></span>
                        }
                    />
                </div>
                    </div>
                    <div className='col-md-6'>
                    <div className="formBox">
                    <Input
                        label={text?.CONFIRM_PASSWORD}
                        type={showConfirmPassword ? 'text' : 'password'}
                        name="confirmPassword"
                        placeholder="********"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                            formik.errors.confirmPassword
                        ) : null}
                        icon={
                            <span onClick={ShowConfirmPasswordClick}><EyeIcon visible={showConfirmPassword}/></span>
                        }
                    />
                </div>
                    </div>
                    <div className='col-12'>
                    <div className="formBox">
                    <Checkbox
                        name="acceptTerms"
                        value={formik.values.acceptTerms}
                        label={<>I have read and agree the &nbsp; 
                        <Button linkSecondary text="TERMS OF USE" 
                                                            onClick={() =>   setIsModal(true) }/>
                       </>}
                        id="acceptTerms"
                        isChecked={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        errormessage={formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            formik.errors.acceptTerms
                        ) : null}
                    />

                    

                </div>

                    </div>
                </div>
              {isExist &&(
                <Text type="H5" className="btn-danger" text="the username is already registered"/>
              )}
              
               {/** <div className="formBox">
                    <Checkbox
                        name="acceptTerms"
                        value={formik.values.acceptTerms}
                        label={<>{text?.I_HAVE_READ_AND_AGREE_TO_THE} <Button type="button" linkSecondary
                                                                              className="ml-1"
                                                                              text={text?.TERMS_OF_USE}/></>}
                        id="acceptTerms"
                        isChecked={formik.values.acceptTerms}
                        onChange={formik.handleChange}
                        errormessage={formik.touched.acceptTerms && formik.errors.acceptTerms ? (
                            formik.errors.acceptTerms
                        ) : null}
                    />

                </div> */}
                <Flex justifyContent="center" className="mb-2">
                    <Button
                        type="submit"
                        text={text?.SIGN_UP}
                        className="btn-verification"
                        large
                        disabled={formik.isSubmitting}
                        isSubmitting={formik.isSubmitting}
                   /> 
{/*
<Button
                        type="button"
                        text={text?.SIGN_UP}
                        className="btn-verification"
                        large
                        onClick={() => {
                            console.log("123")
                          onSignup()
                        }}
                    
                    /> */}
                </Flex>
            </form>
            <Flex
                justifyContent="center"
                className="or"
            >
                <Text type="SPAN" text={text?.OR_SIGN_UP_WITH}/>
            </Flex>
            <Flex
                justifyContent="center"
                className="connect-social"
            >
                <Button
                    link
                    text={
                        <FacebookIcon
                            width="14"
                            // height="46"
                        />
                    }
                    onClick={registerWithFacebook}
                />
                <Button
                    link
                    text={
                        <GoogleIcon
                            width="20"
                            // height="51"
                        />
                    }
                    onClick={registerWithGoogle}
                />
                {/*<Button
                    link
                    text={
                        <AppleIcon
                            width="48"
                            height="48"
                        />
                    }
                />*/}
            </Flex>
            <Flex
                justifyContent="center"
            >
                {text?.ALREADY_HAVE_ACCOUNT} &nbsp; <Button linkSecondary text={text?.LOG_IN}
                                                            onClick={() => navigate('/login')}/>
            </Flex>
            
              </div>
            </div>


          </div>
        </div>
      </div>

    </section>

  
</main>
</div>



           

        </>
    );
};

export default RegisterView;

