import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import './dashboard.scss';
import useHelper from "../../../hooks/helpers.hook";
import {useSelector} from "react-redux";
import Text from "../../../components/text";
import Button from "../../../components/button";
import useLogin from "../../authentication/login/hooks/login.hook";
import Flex from "../../../components/flex";
import NoticeCard from "../../../components/noticeCard";
import IncompleteProfileIcon from "../../../components/svg/incompleteProfileIcon";
import PendingApprovalIcon from "../../../components/svg/pendingApprovalIcon";
import RejectIcon from "../../../components/svg/rejectIcon";
import useGuruDashboard from "./hooks/guruDashboard.hook";
import InstructorDashboardView from "./view/ChatContainerView";
import ChatContainer from "./view/ChatContainerView";
import useVideosCommonHooks from "../createCourse/videosCommonHook";
import Loader from "../../../components/loader";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../../constants/app.constants";
import { message } from "antd";
import useChatHook from "../../trainee/chat/hooks/chat.hook";
import Modal from "../../../components/modal";
import ChatConfirmInstructor from "../../../components/chatConfirmationInstructor";
import InstructorChatHistoryView from "./view/instructorChatHistoryView";



const InstructorChatHistory = props => {
    
    const commonHook = useVideosCommonHooks();
  
    const userInfo = useSelector(state => state.app.userInfo)
    
    const chatHook = useChatHook();
    
    const titlesObj = {title: "Chat History",showDashboard:0}


    
      

    useEffect(() => {
        commonHook.promiseService({"uri":"getInstructorChatDashboard"}).then((data) => {
            
            console.log("getInstructorChatDashboard",data.data.data[0]);
            chatHook.setchatDashboardData(data.data.data[0]);
            console.log("props?.getchatDashboardData.onlineStatus-->",data.data.data[0],userInfo);
            
            if(data.data.data[0].onlineStatus == 1){
                const socket = io(SOCKET_URL)
                socket.on("connection", () => {
                    console.log("connected to server");
                });
              
                socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,pic:userInfo.pic,userId:userInfo.userid});
                
                    if(data.data.data[0].presentRoomName != ""){
                        socket.emit("instructions",{type:"JoinRoomByInstructor",roomName:data.data.data[0].presentRoomName});
                        chatHook.setRoomName(message.roomName);
                    }
                

                chatHook.setSocket(socket);
            }
            
            
           
          


        });
    }, [])

    useEffect(() => {
    chatHook.socket?.on("roomMessage", message =>{
        console.log("roomMessage",message)
        chatHook.setMessages(message);
        
    });


    chatHook.socket?.on("instructions", message =>{
        console.log("instructions",message)

        if(message.type == "TellToJoinRoom"){
            
            if(message.userId == userInfo.userid){
                console.log("JoinRoomByInstructor---->")
                chatHook.setIsModal(true)
                chatHook.setTempRoomName(message.roomName);
               chatHook.socket.emit("JoinRoomByInstructor",message.roomName);
                chatHook.setRoomName(message.roomName);
        
            }
        }
            
        
        });
    }, [chatHook.socket])


    
    useEffect(() => {
  
        commonHook?.promiseServiceNonLoading({"uri":"getChatRoomMessages","chatRoom":chatHook.roomName,"pageNum":chatHook.pageNum}).then((data) => {
           
         
          var temparr = [];
          console.log("message_array",data.data)
              for(var i=0;i<data.data.data.length;i++){
                
                const message_array = data.data.data[i].roomMessage.split('%!#@')
                console.log("message_array",message_array)
                temparr.push(message_array);
                
                // appendMessage_arr(message_array);
              }
              chatHook.setAppendMessages(temparr)
             // setPageNum(pageNum+1);
      
        });
      
      
      }, [chatHook.pageNum,chatHook.roomName])
    
    
 

    if (commonHook?.loading) {
  
        return (
            <>
               <LayoutGuru {...props}>
                <Loader></Loader>
                </LayoutGuru>
            </>
        )
      
      } else {

    return (
        <>
            <LayoutGuru {...props} footer>
                <InstructorChatHistoryView {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook} />
            </LayoutGuru>
        </>
    )
      }
}

export default InstructorChatHistory;
