import React, {useEffect} from "react";
import './premiumAccountSubscribeNow.scss';
import SubscribeImage from '../../assets/img/logo.svg';
import Image from "../image";
import Button from "../button";
import Text from "../text";
import Flex from "../flex";
import useSubscribe from "../../pages/trainee/subscribe/hooks/subscribe.hook";

import Logo from '../../assets/img/logo.svg';
import { useNavigate } from "react-router-dom";

const PremiumAccountSubscribeNow = props => {
    const {text}=props
    const navigate = useNavigate();
    const subscribeHook = useSubscribe()
    useEffect(()=>{
      //  subscribeHook.getPackages()
    },[])
    return (
        <>

<img src={Logo} alt=""  style={{ marginTop:"5px" }} />      
            <div className="premium-account-subscribe-wrap">
              {/*
                <Text
                    type="H3"
                    text={text?.REZKILAH_PREMIUM_ACCOUNT}
                    
                />
                <Flex justifyContent="center" alignItemsEnd className="mb-10">
                   
                    <Text
                        type="H3"
                        className="mb-0"
                        text={text?.MONTH}
                    />
                </Flex>
                <Text
                    type="PARAGRAPH"
                    className="text-white-half mb-40"
                    text={text?.MONTHLY_SUBSCRIPTION}
                />
    */}
                <Button
                    type="button"
                    large
                    className="mb-20"
                    text={text?.SUBSCRIBE_NOW}
                    onClick={()=>{
                        //subscribeHook.subscribeNow
                        navigate('/trainee/purchase-categeory');
                    }
                    }
                />
                <Button
                    type="button"
                    linkLight
                    text={text?.PAY_LATER}
                    style={{
                        fontSize: 'inherit',
                        textDecoration: 'underline',
                        fontWeight: '400',
                        textTransform: 'inherit',
                    }}

                    onClick={()=>{
                        console.log("close")
                        props.setIsModal(false)
                    
                    

                    }}

                />
                </div>
           
        </>
    )
}

export default PremiumAccountSubscribeNow;
