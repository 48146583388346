import React from 'react';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import EditIcon from '../../../../../components/svg/editIcon';
import TextReadonly from '../../../../../components/textReadonly'
import Image from '../../../../../components/image';
import SubscriptionNowImg from '../../../../../assets/images/ssdasdasdasd 2.png';
import SubscribePremiumModal from "../../../../../components/subscribePremiumModal";
import { BiBook } from "react-icons/bi";
import { GiMoneyStack } from "react-icons/gi";
import { AiOutlineShareAlt } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';



const FreemiumProfileView = (props) => {
    const navigate= useNavigate();
    const { goToEditProfile, profileDetails, isModal, setIsModal, openSubscribeNow } = props;
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL+profileDetails?.pic : DP
    const role_of_user = profileDetails?.user_type ? 'Learner ('+profileDetails?.user_type+')':'Learner'
    const UserStatusIcon = ()=>{
        if(profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8" />} />

        } else if(profileDetails?.userstatus == 'Rejected'){
            return <Icon small danger icon={<TickIcon width="12" height="8" />} />
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8" />} />
        }

    }
    const fullAddress = ()=>{
        const address1 = profileDetails?.address1 ? profileDetails?.address1 : ''
        const address2 = profileDetails?.address2 ? profileDetails?.address2 : ''
        const city = profileDetails?.city ? profileDetails?.city : ''
        const statetitle = profileDetails?.statetitle ? profileDetails?.statetitle : ''
        const country = profileDetails?.country ? profileDetails?.country : ''
        const zipcode = profileDetails?.zipcode ? profileDetails?.zipcode : ''

        return address1 +' '+ address2 +' '+ ' '+ city  +' '+ statetitle +' '+ country +' '+zipcode

    }
    return (
        <>
        <div className='change-password my-4 px-5 py-3 rounded-0'>
            <Text
                type="H1"
                text={props?.SETTINGS}
            />
            <div className="row">
                <div className="col-12 col-md-7">
                    <Text
                        type="H3"
                        text="Personal Info"
                        className="font-weight-500 mb-48"
                    />
                    <div className="profile-dp mb-20">
                        <Avatar
                            w100
                            src={profileImage}
                            alt={profileDetails?.fullname}
                        />
                        <div className="profile-dp-details">
                            <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title" />
                            <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email" />
                            <div className="account-status">
                                <UserStatusIcon/>
                                <Text type="PARAGRAPH" text={profileDetails?.userstatus} />
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center mb-30">
                        <Text type="H4" text="General Information" className="mr-24 mb-0" />
                        <Button
                            linkPrimary
                            text="Edit"
                            iconLeft={<Icon small square icon={<EditIcon />} />}
                            onClick={goToEditProfile}
                        />
                    </div>
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Role of user "
                                text={role_of_user}
                            />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Username"
                                text={profileDetails?.uname}
                            />
                        </div>
                      
                    </div>

                    <div className="row">
                    <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Phone Number"
                                text={profileDetails?.mobile}
                            />
                        </div>


                        <div className="col-12 col-md-4">
                            <TextReadonly
                                label="Email Address"
                                text={profileDetails?.email}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-12 col-md-5">
                    <div className='shadow bg-color-grey-scale-1 '>
                    <div className="circle-right-wrapper p-5">
                    <div className="learner_video">
    {/** <video width="750" height="500" controls className='learner_video_demo' >
      <source src={process.env.REACT_APP_IMG_URL + first_video.mp4} type="video/mp4"/>
     </video> */}
    </div>
                        <div className="content">
                            <div className='row'>
                            <div className='col-md-12'>
                        <Button
                                type="button"
                                 
                                text="subscribe now"
                                className="btn-profile mb-1"
                                onClick={() =>{navigate('/trainee/purchase-categeory')}}
                            />
                            </div>
                            </div>
                            <Text type="H5" text="Affiliate Income Generate Step By Step Precess..." className="mb-2 mt-2 text-dark line-height-2 text-uppercase"/> 
                        <div class="row pb-2 mb-1">
							<div class="col-lg-12 col-md-6 col-sm-12 mb-4 mb-md-0">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
										<em>1.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="250">
										<div class="animated-icon animated fadeIn svg-fill-color-primary">
										<BiBook size={'30px'} className='icon-fill-color-primary' />
										</div>
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="150">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 1</p>
										<h4 class="mb-0 text-color-secondary">Learn</h4>
									</div>
								</div>

							</div>
							<div class="col-lg-12 col-md-6 col-sm-12 mb-4 mb-md-0">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
										<em>2.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="500">
										<div class="animated-icon animated fadeIn svg-fill-color-primary ms-2">
										 <GiMoneyStack  size={'30px'} className='icon-fill-color-primary' />
										</div>
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="300">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 2</p>
										<h4 class="mb-0 text-color-secondary">Earn</h4>
									</div>
								</div>

							</div>
							<div class="col-lg-12 col-md-6 col-sm-12">
								
								<div class="feature-box feature-box-steps">
									<div class="feature-box-step-number appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
										<em>3.</em>
									</div>
									<div class="feature-box-icon bg-color-quaternary feature-box-icon-extra-large appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="750">
										<div class="animated-icon animated fadeIn svg-fill-color-primary">
                                          <AiOutlineShareAlt size={'30px'} className='icon-fill-color-primary' />
                                     </div> 
									</div>
									<div class="feature-box-info appear-animation animated fadeInLeftShorterPlus appear-animation-visible" data-appear-animation="fadeInLeftShorterPlus" data-appear-animation-delay="450">
										<p class="mt-2 pt-1 mb-0 text-1 p-relative top-5 text-uppercase">Step 3</p>
										<h4 class="mb-0 text-color-secondary">Share</h4>
									</div>
								</div>

							</div>
						</div>
                          
                        </div>
                        
                    </div>
                    </div>
                </div>
            </div>
            {isModal &&
                <SubscribePremiumModal setIsModal={setIsModal}/>
            }
            </div>
        </>
    );
};

export default FreemiumProfileView;

