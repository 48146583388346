import React from 'react';
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import Icon from '../../../../components/icon';
import TickIcon from '../../../../components/svg/tickIcon'

const PaymentSuccessView = (props) => {
    const {text} = props

    

    return (
        <>
            <Flex justifyContent="center" className="flex-column align-items-center text-center">
                <Text
                    type="H2"
                    text={text?.THANK_YOU_FOR_THE_PAYMENT}
                    className="mb-40"
                />
                <Icon
                    icon={<TickIcon width="52" height="34"/>}
                    extralarge
                    success
                />
                <Text
                    type="PARAGRAPH"
                    text={text?.PAYMENT_SUCCESSFUL}
                    className="mt-20 mb-64 text-success font-size-20"
                />
                <Button large text={text?.CONTINUE} onClick={props?.onClickContinue}/>
            </Flex>
        </>
    );
};

export default PaymentSuccessView;

