import React from 'react';
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import Listen from '../../../../components/listen';
import Image from '../../../../components/image';
import SubscribeImage from '../../../../assets/img/logo.svg';
import usePayment from "../../payment/hooks/payment.hook";

const SubscribeView = (props) => {
    const {text, subscribeNow, packages, isSubmitting} = props
    const {onClickPayLater} = usePayment()

    return (
        <>
            <Text
                type="H2"
                text={text?.SUBSCRIBE_TO_OUR_MONTHLY_PACKAGE}
                className="mb-40"
            />
            <Flex
                justifyContent="between"
                className="subscribe-textimg"
            >
                <div className="subscribe-textimg-content">
                    <Text type="H3" text={text?.REZKILAH_PREMIUM_ACCOUNT} className="mb-0" />
                    <Text type="H1" className="mb-0" text={<>RM {packages[0]?.price}/<span>Month</span></>} />
                    <Text type="PARAGRAPH" text={text?.MONTHLY_SUBSCRIPTION} className="text-center mb-0" />
                </div>
                <div className="subscribe-textimg-image">
                    <Image src={SubscribeImage} />
                </div>
            </Flex>
            <Text
                type="PARAGRAPH"
                text={text?.CHOOSE_OUR_MONTHLY_PACKAGE_TO_VIEW_EXCLUSIVE_REZKILAH_FINANCE_VIDEO_AND_EARN_AFFILIATE_INCOME}
                className="mb-30"
            />
            <Listen
                className="mb-48"
                listColTwo
                listen={[
                    'Lebih dari 100 video eksekutif yang menarik',
                    'Kemudahan tunjuk ajar',
                    'Analisa  secara mendalam dikuasakan oleh AI',
                    'Rakaman Langsung dan segerak',
                    'Tugasan Kursus',
                    'Platfom mudah alih',
                ]}
            />
            <Flex justifyContent="center" className="mb-48">
                <Button large text={text?.SUBSCRIBE_NOW} onClick={subscribeNow} isSubmitting={isSubmitting} />
            </Flex>
            <Flex
                justifyContent="center"
                className="text-center col-9 mx-auto"
            >
                <Text
                    type="PARAGRAPH"
                    text={
                    <>
                        {text?.IF_YOU_WISH_TO_PAY_LATER_YOU_WILL_HAVE_LIMITED_ACCESS_TO_OUR_100000_VIDEOS_AND_CONTENTS_WISH_TO_PROCEED}
                        &nbsp;<Button linkSecondary text={text?.YES_PAY_LATER} style={{fontSize: 'inherit'}} onClick={onClickPayLater}/>
                    </>}
                />
            </Flex>
        </>
    );
};

export default SubscribeView;

