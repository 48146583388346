import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import VideoPlayers from '../../../../components/videoPlayers';
import VideoPlayersDetails from '../../../../components/videoPlayersDetails';
import SidePanelLists from '../../../../components/sidePanelLists';
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import TopicsYouAreInterested from "../../dashboard/components/TopicsYouAreInterested";
import LikedVideos from "../../dashboard/components/LikedVideos";
import {useLocation, useNavigate} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import AccordionCourse from '../../../../components/accordionCourse';
import VideoPlayer from '../../../../components/videoplayer';

import { useSelector } from 'react-redux';
import { GURU } from '../../../../constants/app.constants';
import Modal from '../../../../components/modal';
import Section from '../../../../components/section';
import AvaliableMeeting from '../../../../components/avaliableMeeting/avaliableMeeting';
import MyMeetingSlots from '../../../../components/myMeetingSlots/myMeetingSlots';

const LearnerMainView = (props) => {
    const {isSideOpen, onOpenIsSide, videoDetails, getVideoDocId, docId, watchNextPremium, isMyLearningPage} = props;

    const avatarImage = videoDetails?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + videoDetails?.uploaded_by_pic : AvatarPic

    var modulesData = [];
    var topicsData = []    

    const [isVideoModal, setIsVideoModal] = useState(false);
const [vidoePlayUrl, setVidoePlayUrl] = useState("");

const playVideo = (val) => {
  console.log(val);
  setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
  setIsVideoModal(true);
};

const closeVideo = () => {
    setIsVideoModal(false);
};

console.log("props?.serviceData?.months",props.videoDetails.courseid);
    var breadcrumbList = []
    
    const userInfo = useSelector(state => state?.app?.userInfo);

    if(userInfo?.usertypeid === GURU){

        breadcrumbList =  [
            {
                text: 'Courses',
                link: '/instructor/course',
            },
            {
                text: videoDetails?.videocategoryname,
                link: isMyLearningPage ? '/instructor/course-video-details/' + videoDetails?.videoid : '/instructor/course-video-details/' + videoDetails?.videoid,
            },
        ]    

    }else{

            breadcrumbList =  [
                {
                    text: 'Main page',
                    link: '/trainee/dashboard',
                },
                {
                    text: videoDetails?.videocategoryname,
                    link: isMyLearningPage ? '/trainee/my-learning/video-details/' + videoDetails?.videoid : '/trainee/video-details/' + videoDetails?.videoid,
                },
            ]
    
    }

    useEffect(() => {
        if(isMyLearningPage){
        getVideoDocId(videoDetails?.videoid)
        }

    }, [])


     
    if(props?.getCourseData.length>=1){
        modulesData = [];
       // console.log("props?.getCourseData?.data.courseTimeSlot",props?.getCourseData[5].courseTimeSlot.months)

        var val = 0;
           
                        props?.getCourseData?.map(function (item, index) {
                            
                                if(item?.data?.length >=1){

                                    val = 0;    
                                    item?.data?.map(function (ldata, lindex) {

                                    var obj={"moduletitle":ldata.category_name,"srno":ldata.category_id,"videos":ldata?.videos,"thumbnail":ldata?.thumbnail,"introvideo":ldata?.introvideo,"desc":ldata?.desc,"attacheddoc":ldata?.attacheddoc}    

                                    for(var i = 0;i<modulesData.length;i++){

                                        if(modulesData[i].srno == ldata.category_id){
                                            val = 1;
                                        }
                                    }
                                    if(val == 0){
                                        if(ldata.videos.length >=1){
                                        modulesData.push(obj);
                                        }
                                    }    
                                    })
                                }
                            
                           
                        
                        })

                        modulesData.map(function (item, index) {
                            
                            item?.videos?.map(function (vdata, vindex) {
                                console.log("vdata",vdata);
                                var obj={"moduleid":item.srno,"title":vdata.title,"videoid":vdata.videoid,"thumbnail":vdata.thumbnail,"des":vdata.des,"videoduration":vdata.videoduration}    
                                topicsData.push(obj);

                            });


                        });


                        
                        console.log("ldata",modulesData)

    }


    

    return (
        <>
        
            {isMyLearningPage && (
                <>
                <Breadcrumbs
                    breadcrumbList={breadcrumbList}
                />

                <Text type="H2" text="MY LEARNING" className="mb-20"/>
                </>
            )}
            
            <div className="row">
            <div className='col-md-4'  >
                {isMyLearningPage && (
                    <div className={`video-details-main-side ${!isSideOpen ? 'video-details-main-side-active' : ''}`}>
                        <SidePanelLists
                            {...props}
                            isSideOpen={!isSideOpen}
                            onOpenIsSide={!onOpenIsSide}
                            getAllVideosWatched={props.getAllVideosWatched}
                            usertypeid = {userInfo?.usertypeid}
                            setVidoePlayUrl={setVidoePlayUrl}
                            setIsVideoModal={setIsVideoModal}
                        />
                    </div>


                )
                }
                   </div>
                <div className='col-md-8'>
                <div className={`video-details-main-right ${!isSideOpen ? 'video-details-main-right-active' : ''}`}>
                    <div className="vpd-watch-video">
                        <VideoPlayers
                            videoid={videoDetails?.videoid}
                            videoDetails={videoDetails}
                            docId={docId}
                            {...props}
                            isMyLearningPage={isMyLearningPage}
                            usertypeid = {userInfo?.usertypeid}
                            />
                    </div>
                    </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-md-12'>

                    <VideoPlayersDetails
                        category={videoDetails?.videocategoryname}
                        subcategory={videoDetails?.subCatName}
                        title={videoDetails?.title}
                        views={videoDetails?.ViewsCount}
                        time={videoDetails?.dot}
                        date={videoDetails?.dot}
                        likes={videoDetails?.likescount}
                        description={videoDetails?.des}
                        channelName={videoDetails?.uploaded_by_name}
                        favouritedVideo={videoDetails?.video_favourite_count}
                        uploadedByName={avatarImage}
                        avatarImage={avatarImage}
                        onClickFavoriteVideo={props.favoriteVideo}
                        onClickFavoriteGuru={() => {
                            props.favoriteGuru(videoDetails?.video_posted_by_id)
                        }}
                        onClickLikeVideo={props.likeVideo}
                        isGuruFavourite={videoDetails?.is_guru_favourite}
                        isFavourite={videoDetails?.is_favourite}
                        isLiked={videoDetails?.is_liked}
                        favouriteVideoCount={videoDetails?.guru_favourite_count}
                        guruFavouritedCount={videoDetails?.guruFavouritedCount}
                        videoLikesCount={props.videoLikesCount}
                        usertypeid = {userInfo?.usertypeid}
                        {...props}
                    />




                        {props?.getCourseData.length >= 1 &&(
                            <>
                            {userInfo?.usertypeid != GURU &&(
                                <>
                                            {props?.getCourseData[5].courseTimeSlot.months.length >= 1 &&(
                                                        <>
                                                        <Section className="bg-white">
                                                                <AvaliableMeeting serviceData={props?.getCourseData[5].courseTimeSlot} setChangesDone={props.setChangesDone} type={"course"}/>
                                                        </Section>
                                                        </>
                                            )
                                        }
                              </>          
                            )}

                                        
                                      
                                                <MyMeetingSlots courseId={props.videoDetails.courseid}/>
                                      
                        </>
                    )}



                     <AccordionCourse   moudleDetails={modulesData} topicsDetails={topicsData} playVideo={playVideo}/>

                     {userInfo?.usertypeid != GURU &&(
                        <TopicsYouAreInterested {...props?.text}/>
                     )}

                    </div>
                </div>

                    


         

            
            

            {isVideoModal &&

                <Modal
                    className=""
                    canClose="onClose"
                    onClose={closeVideo}
                    onOuterClose={closeVideo}
                    modalContent={
                    
                        <VideoPlayer
                            videourl={process.env.REACT_APP_IMG_URL + vidoePlayUrl}
                            width="100%"
                            height="460"
                        />

                    }
                />







}          

        </>
    );
};

export default LearnerMainView;

