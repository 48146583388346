import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import {useSelector} from "react-redux";
import Loader from "../../../components/loader";
import { io } from "socket.io-client";
import { SOCKET_URL } from "../../../constants/app.constants";
import useVideosCommonHooks from "../../instructor/createCourse/videosCommonHook";
import LayoutPanel from "../../../components/layoutPanel";
import { useParams } from "react-router-dom";
import useChatHook from "./hooks/chat.hook";

import TrainerChatContainerView from "./view/ChatContainerView";
import Modal from "../../../components/modal";
import ChatConfirmInstructor from "../../../components/chatConfirmationInstructor";
import ChatConfirmTrainer from "../../../components/chatConfirmationTrainer";

const ChatTrainerboard = props => {
    const{userId} = useParams();
    const commonHook = useVideosCommonHooks();
    const chatHook = useChatHook();
    const userInfo = useSelector(state => state.app.userInfo)
    

    useEffect(() => {
        
        commonHook.promiseService({"uri":"getInstructorDetails","user_id":userId,"trainerId":userInfo.userid}).then((data) => {
            
            chatHook.setchatDashboardData(data.data.data);
            console.log("getInstructorDetails",data.data.data,userInfo);
            chatHook.setPageNum(1);
            
            chatHook.setRoomName(data.data.data.roomName);
           
            if(data.data.data.onlineStatus == 1){
                const socket = io(SOCKET_URL)
                socket.on("connection", () => {
                console.log("connected to server");
                });
              
                socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,pic:userInfo.pic});
                socket.emit('instructions', {type:"JoinRoom",obj:{"instructorId":userId,"trainerId":userInfo.userid}});
                chatHook.setHeadingMessage({"type":"connecting"})

                socket.on("roomMessage", message =>{
                    console.log("message",message)
                    chatHook.setMessages(message);
                    
                });

                socket.on("instructions", message =>{
                    
                    
                    if(message.type == "InstructedJoinRoom"){
                        
                        const message_array = message.roomName.split('%!#@')
                        if(userId == message_array[0]){
                            chatHook.setRoomName(message_array[1]);
                            chatHook.setSessionId(message.sessionId);
                            
                            chatHook.setHeadingMessage({type:"joined"})

                            localStorage.setItem('currentRoomName',message_array[1]);
                        
                            console.log("InstructedJoinRoom--> instructions->",message,"---->",message_array)
                            setTimeout(() => {
                                chatHook.setIsModal(false)
                              
                            }, 2000);

                        }
             
                      //  chatHook.setStartTIme(1);
                       
                        
                    }

                    if(message.type == "CancelledByInstructor"){
                        chatHook.setMessages(message.message);
                        chatHook.setHeadingMessage({type:"cancelled"})
                    }



                    if(message.type == "firstMessageReply"){
                        console.log("InstructedJoinRoom-->","RooMName-> ",chatHook.roomName)
                        if(message.roomName ==  localStorage.getItem('currentRoomName')){
                            chatHook.setRoomName(localStorage.getItem('currentRoomName'));
                            chatHook.setMessageCount(1);
                            chatHook.setSessionId(message.sessionId);
                            chatHook.setStartTIme(1);
                        }
                    }

                    
                });


                chatHook.setSocket(socket);
            }
           
           
          


        });

        /*
        const socket = io(SOCKET_URL)
        socket.on("connection", () => {
        console.log("connected to server");
        });
        socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,userId:userInfo.userid});
        socket.emit('startPrivateChat', "narendarnath");
        socket.on("message", message =>{
            console.log("message",message)
            setMessages(message);
            
        });

        setSocket(socket);
        */

    }, [chatHook.setchatDashboardData])
    

    useEffect(()=>{

        chatHook.setIsModal(true);

    },[chatHook.headingMessage])
    useEffect(() => {
  
        commonHook?.promiseServiceNonLoading({"uri":"getChatRoomMessages","chatRoom":chatHook.roomName,"pageNum":chatHook.pageNum}).then((data) => {
           
              var temparr = [];
          
              for(var i=0;i<data.data.status.length;i++){
                const message_array = data.data.status[i].roomMessage.split('%!#@')
                console.log("message_array",message_array)
                temparr.push(message_array);
                // appendMessage_arr(message_array);
              }
              chatHook.setAppendMessages(temparr)
               //setPageNum(pageNum+1);
      
        });
      
      
      }, [chatHook.pageNum,chatHook.roomName])
    
 

    if (commonHook?.loading) {
  
        return (
            <>
               <LayoutGuru {...props}>
                <Loader></Loader>
                </LayoutGuru>
            </>
        )
      
      } else {

    return (
        <>
            <LayoutPanel {...props} footer>


                {chatHook.isModal &&(<Modal
                 onOuterClose={() => chatHook.setIsModal(false)}
                 modalContent={
                   <>

                    
                     <ChatConfirmTrainer  {...props} {...commonHook} userInfo={userInfo} {...chatHook}/>         
                    
                   </>
                  }
               />)}

                <TrainerChatContainerView {...props} {...commonHook} userInfo={userInfo} {...chatHook}/>
            </LayoutPanel>
        </>
    )
      }
}

export default ChatTrainerboard;
