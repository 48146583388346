import React, {useEffect, useState} from "react";
import Text from "../text";
import './learnByTopics.scss';
import Flex from "../flex";
import Button from "../button";

import useHelper from "../../hooks/helpers.hook";
import Loader from "../loader";
import topicVideoPoster from "../../assets/images/topic-video-poster.jpg";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import TopicVideoCard from "../topicVideoCard";
import TopicFlowCard from "../topic_flow_Card";
import FlowCard from "../flowCard";
import { DEFAULT_PAGE_COUNT } from "../../constants/app.constants";
import InstructorCard from "../instructorCard";



const   VideosList = (props) => {

  

    const {videosListLoading, videosList} = props
    

    if (videosListLoading) {
        return <Loader/>
    } else {
        if (videosList && videosList.length > 0) {
           
            return (videosList?.map((item, index) => {
                
                return (

                
        


                    <div className="col-12 col-md-4">
                        
                            <InstructorCard props={item} _props={props} pagetype={props.pagetype} setisShowPopUP={props.setisShowPopUP} setInstructorId={props.setInstructorId}/>
                        
                    </div>
                )
            }))
        }
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No Instructors available"/></div>
    }
}


const GetInstructors = (props) => {
  

    useEffect(() => {

        console.log("props.pagetype--->",props.pagetype);

        if(props.pagetype === 1){
        const interval = setInterval(() => {
           props.getData()
     
        }, 10000);

      return () => clearInterval(interval);
    }
    }, [props.mongoIds]);

    console.log("props------><>><>",props.getCategoryId)


 
      
    useEffect(()=>{
        console.log("props.videosList",props?.videosList.length)
       props.setFilters(props.getCategoryId, 1,"")
   
    },[props.getCategoryId])
    
    var showLoadMore = false;
    
    console.log("props?.videosList.length-->",props?.videosList.length,"  DEFAULT_PAGE_COUNT-->",DEFAULT_PAGE_COUNT)

    
    if(props?.videosList?.length < props?.videosApiData?.totalRecords){
        showLoadMore = true;
    }
    
    
    
    return (
        <>
                
                <div className="row">
                    <VideosList {...props}/>
                </div>

                {showLoadMore && (
                    <Flex justifyContent="center">
                        <Button type="button" outline large text="Load More" onClick={() => {

                            console.log("props.filterKeys.page ",props.filterKeys.page);
                            if(props.filterKeys.page == 1){
                                props.filterKeys.page = 2
                            }
                            props.setFilters(props.getCategoryId, props.filterKeys.page + 1,"")
                        }}/>
                    </Flex>
                )}
            
        </>
    )
}

export default GetInstructors;
