import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import useHelper from "../../../../hooks/helpers.hook";
import useTranslations from '../../../../hooks/translations.hook';

const useOTPVerification = () => {
    const [submittingOtp, setSubmittingOtp] = useState(false)
    const navigate = useNavigate()
    const  {updateUserMetaData} = useHelper()
    const  {post} = useHttpHandler()
    const {setDefaultLanguage} = useTranslations()
    const changeLanguage = (e) => {
        setDefaultLanguage(e.target.value)
    }
    const [oTPInput, setOTPInput] = useState('');
    const handleChangeOTP = (oTPInput) => {
        setOTPInput(oTPInput)
    }
    const [counter, setCounter] = useState(59);
    useEffect(() => {
        const timer =
        counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const goBackToRegister = () => {
        navigate('/trainee/signup')
    }
    const verifyOtp = ()=> {
        setSubmittingOtp(true)
        post('OTPValidation', {otp: oTPInput}).then((res)=>{
            setSubmittingOtp(false)
            const status = res.data.status
            const data = res.data.data
            if(status.result == '200') {
                updateUserMetaData(data[0])
                goToSubscribe()
            } else {
                toast.error(status.msg)
            }
        }).catch(err => {setSubmittingOtp(false);})
    }
    const goToSubscribe = () => {
        navigate('/trainee/dashboard')
    }
    const resendOtp = () => {
        setCounter(counter + 59)
        post('resend_otp').then((res)=>{
            toast.success(res.data.status.msg)
        })
    }

    return {
        oTPInput,
        handleChangeOTP,
        counter,
        setCounter,
        goBackToRegister,
        goToSubscribe,
        verifyOtp,
        resendOtp,
        submittingOtp
    }

}
export default useOTPVerification


