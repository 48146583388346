import React, { useEffect } from "react";
import Text from "../../../../components/text";
import Input from "../../../../components/input";
import EyeIcon from "../../../../components/svg/EyeIcon";
import Flex from "../../../../components/flex";
import Button from "../../../../components/button";
import FacebookIcon from "../../../../components/svg/facebookIcon";
import GoogleIcon from "../../../../components/svg/googleIcon";
import AppleIcon from "../../../../components/svg/appleIcon";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLogin from "../hooks/login.hook";
import { useNavigate } from "react-router-dom";
import useHttpHandler from "../../../../hooks/httphandler.hook";
import { toast } from "react-toastify";
import logoImg from "../../../../assets/userimg/login-bg2.png";
import { IoHome, IoShareSocial } from "react-icons/io5";

const LoginView = (props) => {
  const {
    redirectTo,
    setUserSessionInfo,
    signInWithGoogle,
    signInWithFacebook,
  } = props;
  const { post } = useHttpHandler();
  const { showPassword, openForgotPassword, ShowPasswordClick, onSubmitLogin } =
    useLogin();
  const navigate = useNavigate();
  const { text } = props;
  const formik = useFormik({
    initialValues: {
      uname: "",
      password: "",
    },
    validationSchema: Yup.object({
      uname: Yup.string().required("Please enter a valid email address"),
      password: Yup.string()
        .min(6, "Your password must be atleast 6 digits")
        .required("Please enter a valid password"),
    }),
    onSubmit: (values, actions) => {
      values.os = "web";
      onSubmitLogin(values, actions);
    },
  });
  useEffect(() => {
    console.log("removed");
    //localStorage.removeItem('token')
    // sessionStorage.removeItem('isConvertUserFlow')
  }, []);

  return (
    <>
      <div className="login">
        <main>
          <section class="section min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div class="container">
              <div className="row p-lg-0 py-2">
                <div className="col-12">
                  <div class="feature-box-icon">
                    <Button
                      link
                      text={<IoHome className="text-color-light fs-2 " />}
                      onClick={() => {
                        //navigate("/trainee/signup")
                        navigate("/");
                      }}
                      type="button"
                    />
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-7 col-md-6">
                  <div className="branding-logo">
                    <img src={logoImg} className="img-fluid" alt="logo" />
                  </div>
                </div>

                <div class="col-lg-5 col-md-6 ">
                  <div class="card ">
                    <div class="card-body ">
                      <div class="pt-2 pb-2">
                        <h5 class="card-title text-center pb-0 fs-4">
                          Login to Your Account
                        </h5>
                        <p class="text-center small">
                          Enter your username & password to login
                        </p>
                      </div>

                      <form onSubmit={formik.handleSubmit}>
                        <div className="">
                          <Input
                            className="form-label"
                            formControlClassName="form-control"
                            label={text?.EMAIL_ADDRESS}
                            type="text"
                            name="uname"
                            placeholder="Your Email Address"
                            autoFocus={true}
                            value={formik.values.uname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.uname && formik.errors.uname
                                ? formik.errors.uname
                                : null
                            }
                          />
                        </div>
                        <div className="formBox">
                          <Input
                            label={text?.PASSWORD}
                            type={showPassword ? "text" : "password"}
                            name="password"
                            placeholder="********"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            errormessage={
                              formik.touched.password && formik.errors.password
                                ? formik.errors.password
                                : null
                            }
                            icon={
                              <span onClick={ShowPasswordClick}>
                                <EyeIcon visible={showPassword} />
                              </span>
                            }
                          />
                        </div>
                        <Flex justifyContent="end" className="mb-40">
                          <Button
                            linkDanger
                            text={`${text?.FORGOT_PASSWORD}`}
                            onClick={openForgotPassword}
                            type="button"
                          />
                        </Flex>
                        <Flex justifyContent="center" className="mb-40">
                          <Button
                            type="submit"
                            text={text?.LOGIN}
                            large
                            isSubmitting={formik.isSubmitting}
                            disabled={formik.isSubmitting}
                          />
                        </Flex>
                      </form>

                      <Flex justifyContent="center" className="or">
                        <Text type="SPAN" text={text?.OR_CONTINUE_WITH} />
                      </Flex>
                      <Flex justifyContent="center" className="connect-social">
                        <Button
                          link
                          text={<FacebookIcon width="24" height="46" />}
                          onClick={signInWithFacebook}
                        />
                        <Button
                          link
                          text={<GoogleIcon width="50" height="51" />}
                          onClick={signInWithGoogle}
                        />
                        {/*
                    <Button
                        link
                        text={
                            <AppleIcon
                                width="48"
                                height="48"
                            />
                        }
                    /> 
                    */}
                      </Flex>
                      <Flex justifyContent="center">
                        {text?.DONT_HAVE_ACCOUNT} &nbsp;{" "}
                        <Button
                          linkDanger
                          text={text?.REGISTER_NOW}
                          onClick={() => navigate("/register")}
                        />
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default LoginView;
