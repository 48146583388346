import React, {useRef, useState} from 'react';
import HeroSlider from '../../../components/heroslider';
import 'swiper/css';
import {Pagination} from "swiper";
import "swiper/css/pagination";
import TrainerCard from '../../../components/trainerCard';
import HomeHero from '../../../components/homeHero';
import ChooseUsCard from '../../../components/chooseUsCard';
import HomePageCard from '../../../components/home_card';
import HomeBannerImg from "../../../assets/img/hompagebanner.png";

const   HomeView = (props) => {
    const {homeVideos} = props;

    return (
    
        <> 
         <section id="banner" class="courses">
        <div style={{  justifyContent: 'center'}}>
    <img src={HomeBannerImg} alt="Homepage Banner"  />
    </div>
    </section>
    <section id="popular-courses" class="bg-sect-col courses">
   
   <div class="container">  
   <div class="row">
   
       <HomeHero {...props}/>
      </div> 
      </div>
   
        </section>
    <section id="popular-courses" class="courses">
   
      <div class="container">
        <div class="section-title">
          <h2>Courses</h2>
          <p>Free Courses</p>
        </div>

        <div class="row">
          
              
              {props?.homeVideos?.freeCourses.map((item, index) => { 
                return (
                    
                    <div class="col-lg-3 col-md-6 align-items-stretch ">
                        
                        <HomePageCard
                        imgsrc={item.thumbnail}
                        title={item.title}
                        price='Stay Free'
                        desc ={item.des}
                        subtitle={item.catName}
                        trainerimg={item.instructor_pic}
                        trainername={item.instructor_name} 
                        user={item.views}
                        heart={item.likes}
                        courseId={item.courseid}
                        />
                    </div>
                   
                )
                })
               }


          
          
          
          </div>
         </div>
         </section>
    <section id="popular-courses" class="courses bg-sect-light-grey">
      <div class="container">
        <div class="section-title">
          <h2>LATEST</h2>
          <div><p>LATEST AVAILABLE COURSES</p></div>
        </div>
       
        <div class="row">
               
        {props?.homeVideos?.paidCourses.map((item, index) => { 
                return (
                    
                    <div class="col-lg-3 col-md-6 align-items-stretch">
                        <HomePageCard
                        imgsrc={item.thumbnail}
                        title={item.title}
                        price=''
                        desc ={item.des}
                        subtitle={item.catName}
                        trainerimg={item.instructor_pic}
                        trainername={item.instructor_name} 
                        user={item.views}
                        heart={item.likes}
                        courseId={item.courseid}
                        />
                    </div>
                   
                )
                })
               }


          </div>
         </div>
         </section>
               
         <section id="trainers" class="trainers">
      <div class="container"> 
     <div class="section-title">
          <h2>Faculty</h2>
          <div className='sec-head-title'> <p>Industry Best Course Creators</p></div>
          </div>
      
        <div class="row">

        {props?.homeVideos?.Instructors.map((item, index) => { 
                return (
                    
                    <div class="col-lg-4 col-md-6 col-sm-12 align-items-stretch">
                    <TrainerCard
                    trainerimg={item?.pic}
                    trainername={item.preffered_name}
                    categeory={item.categories}
                    about_yourself={item.about_yourself}
                    />
                  </div>
                   
                )
                })
               }

          

          
        </div>
      </div>
    </section>

      <ChooseUsCard />
           
        </>
    );
};

export default HomeView;

