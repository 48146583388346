import React, {useEffect, useState} from "react";
import LayoutPanel from "../../../components/layoutPanel";
import LearnerMainView from "./view/traineeMainView";
import './main.scss';
import useLearnerMain from "./hooks/traineeMain.hook";
import Loader from "../../../components/loader";
import useVideoPlayers from "../../../components/videoPlayers/hooks/videoPlayers.hook";
import useGetAllVideosWatched from "../../../hooks/getallvideoswatched.hook";
import {useLocation, useParams} from "react-router-dom";
import { useSelector } from "react-redux";
import { GURU } from "../../../constants/app.constants";
import LayoutGuru from "../../../components/layoutGuru";

const LearnerMain = props => {
    const {videoId} = useParams()
    const learnerMainHook = useLearnerMain();
    const videoPlayersHook = useVideoPlayers()
    const getAllVideosHook = useGetAllVideosWatched()
    const {pathname} = useLocation();
    var isMyLearningPage = pathname.includes('my-learning')
    const userInfo = useSelector(state => state?.app?.userInfo);
    if(userInfo?.usertypeid === GURU){
        isMyLearningPage = true;
    }
    useEffect(() => {



        
      
            learnerMainHook.getVideoDetailsById(null, isMyLearningPage)
    
        
    }, [videoId, getAllVideosHook.loading]);

    if (getAllVideosHook.loading || learnerMainHook?.loading ) {
        return (
            <>
                <LayoutPanel {...props} footer>
                    <Loader/>
                </LayoutPanel>
            </>
        )

    } else {
        return (
            <>
                {userInfo?.usertypeid === GURU &&(

                        <LayoutGuru {...props} footer>
                            <LearnerMainView {...props} {...learnerMainHook} {...videoPlayersHook} isMyLearningPage={isMyLearningPage} {...getAllVideosHook} />
                        </LayoutGuru>

                )
                }

                {userInfo?.usertypeid != GURU &&(

                <LayoutPanel {...props} footer>
                    <LearnerMainView {...props} {...learnerMainHook} {...videoPlayersHook} isMyLearningPage={isMyLearningPage} {...getAllVideosHook} />
                </LayoutPanel>

                )
                }
            </>
        )
    }


}

export default LearnerMain;
