import React, {useEffect} from "react";
import SignupLayout from "../../../components/signuplayout";
import SubscribeView from "./view/SubscribeView";
import useSubscribe from "./hooks/subscribe.hook";
import Loader from "../../../components/loader";

const Subscribe = props => {
    const subscribeHook = useSubscribe()

    useEffect(()=>{
        subscribeHook.getPackages()
    },[])

    if (subscribeHook.loading) {
        return (
            <>
                <SignupLayout {...props}>
                    <Loader/>
                </SignupLayout>
            </>
        )
    }
    return (
        <>
            <SignupLayout {...props} >
                <SubscribeView {...props} {...subscribeHook} />
            </SignupLayout>
        </>
    )
}

export default Subscribe;
