import React  from "react";
import LayoutPanel from "../../../components/layoutPanel";
import LearnByTopicsView from "./view/LearnByTopicsView";

const LearnByTopics = props => {
    return (
        <>
          
                <LearnByTopicsView {...props} />
          
        </>
    )
}

export default LearnByTopics;
