import React, {useEffect, useRef, useState} from "react";
import VideoJs from "../videoPlayers/videoJs";
import videojs from '../videoPlayers/videoJs';
import Modal from "../modal";
import Text from "../text";
import Listen from "../listen";
import PremiumAccountSubscribeNow from "../premiumAccountSubscribeNow";
import useSubscribe from "../../pages/trainee/subscribe/hooks/subscribe.hook";
import useHelper from "../../hooks/helpers.hook";
import {FREEMIUM_MINIMUM_VIDEO_PLAY_SECONDS} from "../../constants/app.constants";
import RecommendedVideosOverlay from "../../pages/trainee/main/view/RecommendedVideosOverlay";


const CourseIntroPlayer = props => {
    
    
    const {
        isModal,
        setIsModal,
        updatePresentVideoDuration,
        finishedPlayingVideo,
        isMyLearningPage,
    } = props
    
    
    const playerRef = useRef(null);


    const videoSrc = props?.videofilename ? process.env.REACT_APP_IMG_URL + props?.videofilename : ''

    console.log("videoSrc",videoSrc );

    const videoJsOptions = {
        liveui: true,
        autoplay: false,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: videoSrc,
            type: props?.videofiletype
        }],
    };

   

    return (
        <>
            <VideoJs options={videoJsOptions}  {...props}/>
           
           
        

        </>
    );
}
export default CourseIntroPlayer;
