import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Button from '../../../../components/button';
import Flex from '../../../../components/flex';
import Image from '../../../../components/image';
import RecentImage from '../../../../assets/images/video-details-img.jpg';
import Dropdown from '../../../../components/dropdown';
import useHelper from "../../../../hooks/helpers.hook";
import {useNavigate, useParams} from "react-router-dom";

import {useSelector} from "react-redux";
import Icon from '../../../../components/icon';
import PlayIcon from '../../../../components/svg/playIcon';
import CoursePage from '../../../trainee/courses';
import CourseMainBar from '../../../../components/courseMainbar';
import {Popconfirm,Result,Tag } from 'antd';
import VideoPlayer from '../../../../components/videoplayer';
import ShareVia from '../../../../components/shareVia';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import Modal from '../../../../components/modal';
import rightIcon from '../../../../assets/img/rightIcon.png'
import { DEFAULT_FILTERS, SECRETKEY, SOCKET_URL, socket } from '../../../../constants/app.constants';
import SwitchButton from '../../../../components/switchButton';
import ChatSwitchButton from '../../../../components/ChatswitchButton';
import {toast} from "react-toastify";

import Input from '../../../../components/input';
import { useFormik } from 'formik';
import ChatMsgContainer from '../../../../components/chatMsgContainer';
import { io } from "socket.io-client";
import ChatOnlineStatus from '../../../../components/chatOnlineStaus';

import CryptoJS from "crypto-js";
import InstructorCard from '../../../../components/instructorCard';
import ChatTimerCard from '../../../../components/chatTimerCard';
const ChatContainer = (props) => {
    
    const { goToLearnerSignup} = props
    const {getYearOptions,post} = useHelper()
    const viewsOption = [{id: 'views', value: 'Views'}, {id: 'likes', value: 'Likes'},]
    const [viewsSelected, setViewsSelected] = useState(viewsOption[0]);
    const yearsOption = getYearOptions()
    const [yearSelected, setYearSelected] = useState(yearsOption.at(-1));
    const {dashboardDetails} = {}
    const {kFormatter, getCurrentMonthRange} = useHelper()
    const navigate = useNavigate()
    const {text} =props
    const userInfo = useSelector(state => state.app.userInfo)
    const [isModal, setIsModal] = useState(false);
    const [isShareModal, setIsShareModal] = useState(false);
    const [showVideoEnded, setShowVideoEnded] = useState(false);
    const [videoDetails, setVideoDetails] = useState(null);
    const [isVideoModal, setIsVideoModal] = useState(false);
    const{presentRoomName} = useParams();
    const [conversationCharge,setConversationCharge] = useState(0);

    const timerCallBack = () =>{

      
        props.commonHook.promiseServiceNonLoading({"uri":"chatTimerInstructor","sessionId":props?.sessionId,"chatRoom":props?.roomName}).then((data) => {
        
              setConversationCharge(data.data.status.balance);
  
            
        });
      }



    useEffect(() => {
       
        timerCallBack();
    
    }, [props?.sessionId])

    useEffect(() => {
       
        const socket = io(SOCKET_URL)
        socket.on("connection", () => {
        console.log("connected to server");
        });
        socket.emit('setUsername', {uname:userInfo.uname,mongoId:userInfo.mongoId,pic:userInfo.pic,userId:userInfo.userid});
        socket.on(props?.roomName, message =>{
            console.log("message",message)
            props.setMessages(message);
        });
        if(presentRoomName != ""){
            props.setRoomName(presentRoomName);
           socket.emit("instructions",{type:"JoinRoomByInstructor",roomName:presentRoomName});
           
        }
        props.setSocket(socket);
    
    }, [])

    const onlineStatusChange = (statusbit) => {


        if(statusbit){

            props.commonHook.promiseServiceNonLoading({"uri":"onlineStatusChange","mongoId":dashboardDetails?.mongoId,"status":0  }).then((data) => {
            })  

        }else{
            
            props.commonHook.promiseServiceNonLoading({"uri":"onlineStatusChange","mongoId":dashboardDetails?.mongoId,"status":1}).then((data) => {
            }) 

        }
       

      


    };
  


    return (
        <>
            
         


            <Text type="H5" text={props.titlesObj.title} className=" mt-20"/>     

            <div className='row'>
                    <div className='col-md-3'>
                    <div class="member">

                    <label className="form-label mb-10">Your Conversation Charge Till Now : {conversationCharge}</label>

                    <Button type="button" style={{marginLeft:25}}  text="End Conversation"  onClick={()=>{props?.setIsModal(true)}}/>
                        <ChatTimerCard props={props}  startTime={props.startTime} timerCallBack={timerCallBack} />
                    </div>

                    </div>


                <div className='col-md-9' style={{height:'700px'}}>
                    {/*

                    {props.roomName == "" &&(
                        <ChatMsgContainer {...props} userInfo={userInfo}  commonHook={props.commonHook} ishidden={0}/> 
                    )
                    }
                    
                    {props.roomName != "" &&(
                        <ChatMsgContainer {...props} userInfo={userInfo}  commonHook={props.commonHook} ishidden={0}/> 
                    )

                    }
                */}
                   <ChatMsgContainer {...props} userInfo={userInfo}  commonHook={props.commonHook} ishidden={0}/> 
                    
                
                </div>

            </div>


           

                                 


                    
       
        </>
    );
};


export default ChatContainer;

const DashGuruStatus = props => {
    const {text} =props
    return (
        <div className="dash-guru-status-card">
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
            <Text type="PARAGRAPH" text={props.subtitle} className="dgsc-subtitle"/>
            <Text type="H2" text={props.number} className="dgsc-number"/>
            <Button type="button" className="btn-primary-dashboard" text="View Details"  onClick={props.onClick}/>
        </div>
    )
}
