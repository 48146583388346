import React, { useEffect, useState } from "react";
import VideoCard from "../videocard";
import './instructorCard.scss';
import TImage001 from '../../assets/img/logo.svg';
import Avatar from '../avatar'
import Text from "../text";
import useHelper from "../../hooks/helpers.hook";
import { NavLink, useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { AiFillLinkedin, AiOutlineHeart } from "react-icons/ai";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import Button from "../button";

const InstructorCard = props => {
  const navigate = useNavigate();
    const [status,setStatus] = useState(0);
    console.log("props--->",props.pagetype);
    useEffect(()=>{

        
        for(var i=0;i<props?._props?.onlineStatus?.length;i++){

            if(props?._props?.onlineStatus[i]?._id == props?.props?.mongoId ){
                setStatus(props?._props?.onlineStatus[i]?.onlineStatus);
            }

        }

     },[props?._props?.onlineStatus])
    

    const {kFormatter,getShortDescription} = useHelper()
    return(
        <>
        
        <div class="member">
          <div className="instru-img mb-3">
          <img src={process.env.REACT_APP_IMG_URL + props?.props?.pic} class="img-fluid" alt="" />
          </div>
              
              <div class="member-content">
                <h4>{props?.props?.preffered_name} {props?.props?.uname}</h4>
                {props.pagetype === 1 &&(
                  <>
                
                </>
                )}
                
                <span>{props?.props?.catName}</span>
                <p>
                  {props?.props?.about_yourself}
                </p>
                <div class="social d-none">
                  <a href=""><BsTwitter className="trainer-social-links" /></a>
                  <a href=""><FaFacebookF className="trainer-social-links"/></a>
                  <a href=""><BsInstagram className="trainer-social-links"/></a>
                  <a href=""><AiFillLinkedin className="trainer-social-links"/></a>
                </div>

                <div className="d-flex justify-content-evenly p-2">
                      
                       
                        {props.pagetype === 0 &&(
                  <>
                  
              <Button   type="primary" key="console" text="Check Availability"  onClick={()=>{
                  props.setInstructorId(props?.props?.userid);
                  props.setisShowPopUP(true)
                  //  navigate('/trainee/instructor/chat-instructor-details/' + props?.props?.userid)              
                                     }}/>
                                     </>
                  )
                  
                }

                {props.pagetype === 1 &&(
                  <>
                  {status == 1 &&(<>

                    <span class="online_dot mt-2 " > <span className="online_text"> Online </span></span>

                    <Button className="fs-8" type="primary" key="console" text="Start Chat"  onClick={()=>{
                     navigate('/trainee/instructor/chat-instructor-details/' + props?.props?.userid)              
                                     
                      }}/></>)}
                                     </>
                  )
                  
                }

{status == 0 &&(
                   <span class="ofline_dot" > <span className="online_text"> Offline </span></span>

                   )}
                                
                 
                     
                        
                      </div> 
          
                
                  
                
                

                
              </div>
            </div>
        
        </>
    )
}

export default InstructorCard;
