import React from 'react'
import Flex from '../../../../components/flex'
import Text from '../../../../components/text'
import { HiOutlineUsers } from "react-icons/hi";
import { NavLink } from 'react-router-dom';
import {loadStripe} from '@stripe/stripe-js';
import {
    PaymentElement,
    Elements,
    useStripe,
    useElements,
  } from '@stripe/react-stripe-js'
import CheckoutForm from '../../stripe/view/CheckoutForm';

const CategeoryPaymentView = (props) => {
  const stripePromise = loadStripe(process.env.REACT_APP_PAYMENTGATEWAYKEY);
  const {
    dropdownsData,
    subscriptionData
} = props?.guruUploadVideoHook

const options = {
  // passing the client secret obtained from the server
  clientSecret: props.subscriptionData.clientSecret,
};
/*
const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    
  },
};*/
console.log("categories",dropdownsData.categories,props.subscriptionData);

  return (
    <div>
      <div className='container'>
      <div className='row'>
            <div className='col-md-9'>
            <Text type="H2" text="Payment" className="mb-2 text-start"/>
      <Text type="PARAGRAPH" text="please Purchase any one Categeory to enjoy Learning and Earn Affiliate Income." className="mb-3 text-lightdark text-start"/>
            </div>
        
        </div>

      <div className='row'>
            <div className='col-md-12'>
            <div class="bg-primary purchase_card_payment">
    <div class="container py-2">
              <div class="row">
              <div class="col-lg-3 col-md-3 col-sm-12">
              <img src={process.env.REACT_APP_IMG_URL + dropdownsData?.categories[0]?.catPic} class="img-fluid w-100" alt="Card Image" />
            </div>
                <div class="col-md-9">
                        <Text type="H3" className="mb-1 text-light" text={dropdownsData?.categories[0]?.catName}/>
                    <p className='text-gray'>{dropdownsData?.categories[0]?.catDesc + "...."}</p>
                   
                </div>
             
        </div>
    </div>
</div>
            </div>
            
        </div> 
        <div className='row mt-5 card box-shadow-4 card-body align-items-center mt-4 px-4'>

        <h4 class="font-weight-bold">Payment</h4>

        

  
    <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
  

            <div className='offset-lg-3 col-md-4'>
              
            <div class="card box-shadow-4 mt-2">
              
                    <div class="card-body align-items-center mt-4 px-4">
                        
                        
                       
                        <div class="row mt-3">
                            <div class="col-md-6">
                                <NavLink to={'/trainee/purchase-sub-categeory'}><button class="btn-failure mt-1"> Failure </button></NavLink>
                            </div>
                            <div class="col-md-6">
                            <NavLink to={'/trainee/subscribe-information'}> <button class="btn-paymentsuccess mt-1">Success</button> </NavLink>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </div>
       
      </div>
    </div>
  )
}

export default CategeoryPaymentView
