import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {useDropzone} from 'react-dropzone';
import * as Yup from "yup";

import Breadcrumbs from '../breadcrumbs';
import Button from '../button';
import FlowCard from '../flowCard';
import Image from '../image';
import Input from '../input';

import Select from '../input/select';
import Textarea from '../input/textarea';
import ProgressBarCard from '../programBarCard';
import Text from '../text';
import { UploadComponent } from '../../pages/instructor/uploadAttachments/view/UploadAttachmentsView';

import useHttpHandler from '../../hooks/httphandler.hook';
import useHelper from "../../hooks/helpers.hook";
import axios from "axios";
import {toast} from "react-toastify";
import useGuruUploadVideo from '../../pages/instructor/uploadVideo/hooks/guruUploadVideo.hook';
import { DOC_FILE_TYPE, IMAGE_FILE_TYPE, VIDEO_FILE_TYPE } from '../../constants/app.constants';
import Flex from '../flex';
import InputFile from '../input/inputFile';
import Icon from '../icon';
import TickIcon from '../svg/tickIcon';
import CameraIcon from '../svg/cameraIcon';
import ProgramBarSecondaryCard from '../programBarCard/programBarSecondaryCard';
import Modal from '../modal'
import StaticVideoPlayer from '../videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../htmlparser';
import VideoPlayer from "../videoplayer";

import UploadVideoPreview from '../addTopicCompnent/videoUploadPreview';
import 'react-calendar/dist/Calendar.css';
import Calendar from 'react-calendar';
import useNewCommonHook from '../../hooks/newCommonHook';
import TimeButton from '../timeSlot/timeButton';
import SelectNew from '../input/selectnew';
import MonthButton from '../monthButton/monthButton';
import TimeButtonLearner from '../timeSlotLearner/timeButtonLearner';



const AvaliableMeeting = (props) => {
    //const [progress, setProgress] = useState(null);
    const navigate = useNavigate();
    const [mainData,setMainData] = useState({});
    const [loadPage,setLoadPage] = useState(false);
  const commonHook = useNewCommonHook()
    const {formatDateYYYYMMDD,formatDateHms,formatDateHH} = useHelper();
    
    const [date, setDate] = useState(new Date());
    const [orginaldefaultTimeSlot,setOrginalDefaultTimeSlot] = useState([]);
    const [timeSlot,setTimeSlot] = useState([]);
    const [dateSlot,setDateslot] = useState([]);
    const [selectedDate,setSelectedDate] = useState(formatDateYYYYMMDD(new Date()));

    const handleDateSelected = (date) => {
      console.log('Selected date:', date);
      setDate(date);
      setSelectedDate(formatDateYYYYMMDD(date))
     
      /*commonHook._promiseService({"uri":"getInstructorDateWiseTimeSlots","date":formatDateYYYYMMDD(date)}).then((data) => {
        
        if(data.data.status.result == 200){
          console.log(data.data.data);
          setSelectedTimeSlot(data.data.data);
         
  
        }
  
      });*/
      // Your custom logic here
    };
  
 

  
  
   

    useEffect(()=>{
      console.log("props.type",props.type)
       if(props.type === "course"){
        console.log("servieData",props.serviceData)
        setMainData(props.serviceData)
        setLoadPage(true);
       }else{

        commonHook._promiseService({"uri":"getInstructorTimeSlotAvaliablity","userid":props.instructorId}).then((data) => {
        
          if(data.data.status.result == 200){
            console.log("data",data.data.data)
            setMainData(data.data.data)
            setLoadPage(true);
          }
      
        });

       }
      
        handleDateSelected(new Date());
},[])


const [monthActive,setMonthActive] = useState("");
const [dateActive,setDateActive] = useState("");



const monthButtonClick = (itemdata) =>{
  const tempArray = [];
  mainData.dates.map((item,index) => {

    if(itemdata.monthName === item.monthName){
      tempArray.push(item);
    }

    setTimeSlot([]);
  });


  setMonthActive(itemdata.monthName)

  let pp = tempArray.filter( (ele, ind) => ind === tempArray.findIndex( elem => elem.slot_date === ele.slot_date))

  
  setDateslot(pp);
  console.log(pp)
 // setTimeSlot(tempArray);
 
}

const dateButtonClick = (itemdata) =>{

  setDateActive(itemdata);

  const tempArray = [];
  mainData.dates.map((item,index) => {
    if(itemdata.slot_date === item.slot_date){
      tempArray.push(item);
    } 
  });
  setTimeSlot(tempArray);

}


const bookAction = (itemdata) =>{

  console.log("itemdata",itemdata);
  
  commonHook._promiseService({"uri":"learnerSlotBooking","slotDate":formatDateYYYYMMDD(itemdata.slot_date),"slotId":itemdata.idno,"comments":''}).then((data) => {
        
    if(data.data.status.result == 200){

      toast.success("Sucessfully added meeting")
      if(props.type === "course"){
      props.setChangesDone(Math.random(100));
      }else{
        props.setisShowPopUP(false)
      }
      
      
      
        
    }

  });
  

}




return(
  
    
      <>
      {loadPage == true &&(
      
      <div>
      
      <div className='calendar-container'>
      </div>
     
        
      <div className="card border-radius-5 pad-10 ">
   
      <h5 class="text-hash-color text-center mt-10">This Course Contains meeting which can pay and use. please book appointment with instructor and sort out your doubts</h5 >
           

           

<div className='row'>
<div className="col-md-12">
{mainData.months.map((item,index) => (
                        <>
                        
                        <MonthButton item={item} index={index} monthButtonClick={monthButtonClick}  monthActive={monthActive}/> 
                        
                        </>
                       ))}
 </div>

                      <div className="col-md-12">
                              {dateSlot.map((item,index) => (
                                    <>
                                    <TimeButtonLearner item={item} index={index} type="date" buttonClick={dateButtonClick}  active={dateActive} /> 
                                    </>
                              ))}
                      </div>

                      <div className="col-md-12">

                              {timeSlot.map((item,index) => (
                                    <>
                                    <TimeButtonLearner item={item} index={index} type="time"  buttonClick={bookAction}   /> 
                                    </>
                              ))}

                      </div>
 
</div>
     </div>   

    </div>)}
      </>
    )

    }
   

  
  
  
    





export default AvaliableMeeting;

