import React, {useEffect, useState} from "react";
import LayoutPanel from '../../../../components/layoutPanel';
import MyReportView from "./view/WalletView";
import SubscribePremiumModal from "../../../../components/subscribePremiumModal";
import useSubscribePremiumModal from "../../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook";
import Loader from "../../../../components/loader";
import useGetTableList from "../../../../hooks/getTableListCommonHook";
import {DEFAULTFILTERS} from "../../../../constants/app.constants";
import WalletView from "./view/WalletView";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";

const Wallet = props => {
    const subscribePremiumModalHook = useSubscribePremiumModal()
    const commonHook = useVideosCommonHooks();
    const [walletAmount,setWalletAmount] = useState(0);
    const [reloadPage,setReloadPage] = useState(0);

    useEffect(()=>{
        commonHook.setkeys();
        DEFAULTFILTERS.uri = 'GetWalletDetails';
        DEFAULTFILTERS.type = 0;
        commonHook.getList(DEFAULTFILTERS)
    },[reloadPage]);

    useEffect(()=>{
        console.log(commonHook.tableData?.customdata?.length)
        if(commonHook.tableData?.customdata?.length >=1){
        setWalletAmount(commonHook.tableData?.customdata[0].WalletAmount)
        }
    },[commonHook.loading]);

    


    if (commonHook.loading) {
        return (
            <>
                <LayoutPanel {...props}>
                    <Loader/>
                </LayoutPanel>
            </>
        )
    }
    return (
        <>
            <LayoutPanel {...props}>
                <WalletView {...props} {...commonHook} walletAmount={walletAmount} setReloadPage={setReloadPage}/>
            </LayoutPanel>
        </>
    )
}

export default Wallet;
