import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from '../../../../components/button'
import CourseCard from '../../../../components/courseCard'
import InstructorImage from '../../../../assets/img/logo.svg';
import CourseImg from '../../../../assets/img/logo.svg';
import Table from '../../../../components/table';
import Pagination from '../../../../components/pagination';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import Image from '../../../../components/image';
import { useMediaQuery } from 'react-responsive';
import useHelper from "../../../../hooks/helpers.hook";
import Status from "../../../../components/status";
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
/*import Select from '../../../../components/input/select';*/
import Select from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FlowCard from '../../../../components/flowCard';
import CourseImage from '../../../../assets/img/logo.svg';
import { Popconfirm,Result,Tag } from 'antd';
import StaticVideoPlayer from '../../../../components/videoPlayers/staticVideoPlayer';
import TextHtmlParser from '../../../../components/htmlparser';
import VideoCard from '../../../../components/videocard';
import ImageVideoPopup from '../../../../components/imageVideoPopup';
import CourseMainBar from '../../../../components/courseMainbar';
import VideoPlayer from '../../../../components/videoplayer';
import Bin from '../../../../assets/img/Bin.png';
import { DEFAULT_FILTERS } from '../../../../constants/app.constants';
 
import cash from '../../../../assets/img/cash.png';
import privateCourse from '../../../../assets/img/PirvateCourse.png';
import free_course from '../../../../assets/img/free-course.png';
import ShareVia from '../../../../components/shareVia';
import ForgotPassword from '../../../../components/forgotpassword';
import Modal from '../../../../components/modal';
import Flex from '../../../../components/flex';
import rightIcon from '../../../../assets/img/rightIcon.png'
import LearnByTopics from '../../../../components/learnByTopics';
import TrainerCategoryBar from '../../../../components/trainerCategoryBar';
import GetInstructors from '../../../../components/getInstructors';


const ChatWithInstructorView = (props) => {
  
    const customData = props?.tableData?.customdata;
  
    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Chat With Instructor",
            link:"/trainee/chat-with-instructor",
        },
    ]

    

  

    

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
    };




  return (
    
    <>
    
       <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
    <div className='row'>
        <div className='col-md-9'>
      <Text
        type="H1"
        text="Chat With Instructor"
        className="mt-0 main_heading_color ml-3 mb-0"
    />
    </div>

  
    </div>  
       
        <div className="row top-main-boxes  course-main-box "  style={ {padding:'0px'}} >
    
        <TrainerCategoryBar
            course="Instructor"
            coursetitle="Antonio"
            module="No.of Courses"
            value={customData?.no_of_courses}
            availablevideos="Total Videos"
            valuevideo={customData?.no_of_videos}
            dropdownsData ={props?.dropdownsData}
            _props = {props}   
        />

        </div>        

            <div className="row top-main-transparent-boxes  course-main-box "  style={ {padding:'0px'}} >
                
                   <GetInstructors {...props} getCategories={props.getCategories} />
    
            </div>


    </>
  )
}

export default ChatWithInstructorView
    
CourseCard.defaultProps = {
    videoListOption: []
}
