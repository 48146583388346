import React from "react";
import { AiOutlineRightCircle } from "react-icons/ai";

import Text from "../text";

const DashboardBoxes = props => {
   
             const {text} =props
    return (
        <div className="dash-guru-status-card py-lg-4">
            <div className="">
            
            <img src={props?.img}  alt="copy" className='icon-50' />
            {/*
            <AiOutlineRightCircle className="fa-solid dash-icon-c" />            
            */}
            
            
            <Text type="H2" text={props.number} className="dgsc-number pt-1"/>
             
            <div className="chat-box mt-2">            
            <Text type="H1" text={props.title} className="dgsc-title mb-2"/>
           
            </div>
            </div>
            <div className="view-icon">
            {props.icon == 0 &&(
                <AiOutlineRightCircle  className="fa-solid dash-icon-c" size={25}  onClick={props.onClick}/>
            )}

            {props.icon != 0 &&(
                <props.icon  className="fa-solid dash-icon-c" size={25}  onClick={props.onClick}/>
            )}

           

            </div>

     
           
        </div>
    )
    
  
}

export default DashboardBoxes;