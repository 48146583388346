import React, { useEffect } from 'react'
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import useHelper from '../../../hooks/helpers.hook';
import useCourseDropDown from '../courses/hook/courseDropDown.hook';
import TodayAddedCoursesView from './views/todayAddedCoursesView'


const TodayAddedVideosPage = (props) => {
  
  const _extraParams = {"type":"get_today_added_course","from_date":"2023-03-02"};

  const commonHook = useGetTableList();
  const guruUploadVideoHook = useCourseDropDown();
  const today = new Date();
  const {formatDateYYYYMMDD} = useHelper();
  console.log("date->",formatDateYYYYMMDD(new Date()));
  useEffect(()=>{
      commonHook.setKeys();
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
      DEFAULT_FILTERS.coursetype = 1;
      DEFAULT_FILTERS.fromdate = formatDateYYYYMMDD(new Date());
      commonHook.getList(DEFAULT_FILTERS)
      guruUploadVideoHook.getDropDowns()
  },[])

  useEffect(() => {
    if (commonHook.checkCategory) {
      commonHook.setCheckCategory(false);  
      commonHook.setCategoryId(commonHook.getCategoryId)
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
      DEFAULT_FILTERS.coursetype = 1;
      DEFAULT_FILTERS.fromdate = formatDateYYYYMMDD(new Date());
      DEFAULT_FILTERS.category_id = commonHook.getCategoryId;
      DEFAULT_FILTERS.subcategory_id = commonHook.getSubCategoryId;
      commonHook.getList(DEFAULT_FILTERS)
      console.log("----->",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
    } 
  }, [commonHook.checkCategory]);


  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
}else{
  return (
    
    <div>
        <LayoutPanel {...props} footer>
      <TodayAddedCoursesView {...props} extraParams = {_extraParams} {...commonHook}  {...guruUploadVideoHook}/>
      </LayoutPanel>
    </div>
  )
}
}

export default TodayAddedVideosPage
