import React, {useState} from 'react';
import Text from '../../../../components/text';
import Button from '../../../../components/button';
import Loader from "../../../../components/loader";
import Card from "../../../../components/card";
import JoinGuru from '../../../../components/joinGuru';
import FavouriteGuruCard from '../../../../components/favouriteGuruCard';
import FavGuruImage from '../../../../assets/img/logo.svg';
import Pagination from '../../../../components/pagination';
import {DEFAULT_FILTERS} from "../../../../constants/app.constants";
import {useNavigate} from "react-router-dom";
import TopicImgInvestment from "../../../../assets/img/logo.svg";
import CategoryButtons from "../../../../components/categoryButtons";
import Section from '../../../../components/section';
import CourseCard from '../../../../components/courseCard';
import InstructorImage from '../../../../assets/img/logo.svg';
import Table from '../../../../components/table';
import useHelper from '../../../../hooks/helpers.hook';
import Status from "../../../../components/status";
import { useMediaQuery } from 'react-responsive';
import Image from '../../../../components/image';
import Icon from '../../../../components/icon';
import EditIcon from '../../../../components/svg/editIcon';
import NoVideoUploadedYet from '../../../../components/noVideoUploadedYet';
import Breadcrumbs from '../../../../components/breadcrumbs';
import LearnerCard from '../../../../components/traineeCard';

const FavouriteGurusList = (props) => {
    const navigate = useNavigate();
    const tableData = props?.tableData?.listData


    if (props?.loading) {
        return <Loader/>
    } else {


        if (tableData && tableData.length > 0) {
            return (
                <>
                <div className="row row-mrl-8 row-cols-2 row-cols-md-5 mb-5">
                    {tableData?.map((item, index) => {
                      
                        return (
                            <div className="row row-mrl-8 row-cols-2 row-cols-md-5 mb-5">
                            {tableData?.map((item, index) => {
                               
                                return (
                                    <CourseCard
                                        imgsrc={item.pic}
                                      course="Course Name"
                                      coursetitle="React"
                                      module="Added Modules"
                                      value="12"
                                      availablevideos="Available Videos"
                                      valuevideo="20"
                                      status="Status"
                                      active="Active"
                                      viewcontent="View Module"
                                      inactive="InActive"
                                      statusactive="Active"
                                      link="/instructor/modules-page"
                                        onClick={() => {
                                            navigate('/trainee/favourite-instructor/favourite-instructor-details/all-videos/' + item.guruid)
                                        }}
                                    />
                                )
                            })}
                        </div>
                        )
                    })}
                </div>
                </>
                )
                
        }


        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No favourite gurus"/></div>


    }
}

const FavouriteGuruView = (props) => {
    const [isActive, setIsActive] = useState({catId: 0, catName: 'All topics'})

    const getList = (catId) => {
        props.setKeys();
        DEFAULT_FILTERS.uri = 'user_favoutitegurus';
        DEFAULT_FILTERS.category_id = catId;
        props.getList(DEFAULT_FILTERS)
    }
    const {formatDateDDMMYY, getShortDescription} = useHelper()
    const tableData = props?.tableData?.listData
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const navigate = useNavigate ();

    const breadcrumbList = [
        {
            text: 'Dashboard',
            link: '/trainee/dashboard',
        },
        {
            text:"Favourite Instructor",
            link:"/trainee/favourite-instructor",
        },
    ]

    return (
        <>
         <Breadcrumbs
        breadcrumbList={breadcrumbList}
      />
         <Text
                type="H1"
                text="Favourite Instructor"
                className="mt-30 mb-30"
                />
 <Table
                thead={
                    <tr>
                       
                    </tr>
                }
                tbody={
                    <>
                        {tableData?.map(function (item, index) {
                            return (
                                <tr>
           <LearnerCard 
           imgsrc={item.pic}
           course="Instructor Name"
           coursetitle={item.fullname}
           module="Courses Added"
           value={item.courses}
           availablevideos="Available Videos"
           valuevideo={item.video_count}
           viewcontent="View-Courses"
           viewcontent1="View Videos"
           desc="asdasdas"
           link={"/trainee/instructor-courses/" + item.userid}
        
           />   
            <br />
    </tr>
    
)
})}  </>
}
/>
<Pagination
totalPages={props?.tableData?.totalPages}
currentPage={props?.tableData?.currentPage}
totalRecords={props?.tableData?.totalRecords}
nextPage={props.nextPage}
previousPage={props.previousPage}
perPage={props.filterKeys.perpage}
/>

        </>
    );

};

export default FavouriteGuruView;

