import React, {useEffect, useState,useRef} from "react";
import LayoutGuru from "../../../components/layoutGuru";
import './dashboard.scss';
import useHelper from "../../../hooks/helpers.hook";
import {useSelector} from "react-redux";

import useGuruDashboard from "./hooks/guruDashboard.hook";
import useVideosCommonHooks from "../createCourse/videosCommonHook";
import Loader from "../../../components/loader";
import useChatHook from "../../trainee/chat/hooks/chat.hook";
import ChatDashBoardView from "./view/ChatDashBoardView";



const ChatDashboard = props => {
    
    const commonHook = useVideosCommonHooks();
    const guruDashboardHook = useGuruDashboard();
  
    const userInfo = useSelector(state => state.app.userInfo)
    
     const chatHook = useChatHook();

     const [startTime,setStartTime] = useState(0);

    // const titlesObj = {title: "Chat Board",showDashboard:1}

     const popupEvets = (type) => {
        
      }
    
      useEffect(() => {
       
        guruDashboardHook.getChatDashboardDetails()
  
    }, [])
      


 

    if (guruDashboardHook?.loading) {
  
        return (
            <>
               <LayoutGuru {...props}>
                <Loader></Loader>
                </LayoutGuru>
            </>
        )
      
      } else {

    return (
        <>
            <LayoutGuru {...props} footer>
                
                { /*chatHook.isModal &&(<Modal  
               
                onOuterClose={() =>  chatHook.setIsModal(false)}
                
                modalContent={
                    <>
                     <ChatConfirmInstructor {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook}  />         </>
                }
            />) */}
            
           
                <ChatDashBoardView {...props} userInfo={userInfo}  {...chatHook} commonHook={commonHook}  startTime={startTime} setStartTime={setStartTime}  guruDashboardHook={guruDashboardHook}/>
            </LayoutGuru>
        </>
    )
      }
}

export default ChatDashboard;
