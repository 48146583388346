import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import GlobalInfoModal from '../../../components/globalInfoModal';
import LayoutPanel from '../../../components/layoutPanel'
import Loader from '../../../components/loader';
import useSubscribePremiumModal from '../../../components/subscribePremiumModal/hooks/subscribePremiumModal.hook';
import { DEFAULT_FILTERS } from '../../../constants/app.constants';
import useGetTableList from '../../../hooks/getTableListCommonHook';
import useCourseDropDown from './hook/courseDropDown.hook';
import CoursePageView from '../dashboard/view/coursePageView'

const CoursePage = (props) => {
  const commonHook = useGetTableList();
  const guruUploadVideoHook = useCourseDropDown();
  var coursetype = 0;
  const window_path = window.location.pathname
    const test = window_path.includes("trainee/courses");
  if(test){
    coursetype = 1;
  }
console.log(window_path,test);
  useEffect(()=>{
      commonHook.setKeys();
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
      DEFAULT_FILTERS.coursetype = coursetype;
      commonHook.getList(DEFAULT_FILTERS)
      guruUploadVideoHook.getDropDowns()
  },[])

  useEffect(() => {
    if (commonHook.checkCategory) {
      commonHook.setCheckCategory(false);  
      commonHook.setCategoryId(commonHook.getCategoryId)
      DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
      DEFAULT_FILTERS.userid = "l";
      DEFAULT_FILTERS.coursetype = coursetype;
      DEFAULT_FILTERS.category_id = commonHook.getCategoryId;
      DEFAULT_FILTERS.subcategory_id = commonHook.getSubCategoryId;
      commonHook.getList(DEFAULT_FILTERS)
      console.log("----->",commonHook.checkCategory,commonHook.getCategoryId,"--> sub",commonHook.getSubCategoryId);
    } 
  }, [commonHook.checkCategory]);


  
 
  if(commonHook.loading || commonHook.loading) {
    return <Loader/>
}else{

  return (
    <div>
  
         <LayoutPanel {...props}  footer>
      <CoursePageView {...props} {...guruUploadVideoHook} {...commonHook}/>
      </LayoutPanel>
    </div>
  )
}
}

export default CoursePage
