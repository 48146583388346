import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import VideoPlayers from '../../../../components/videoPlayers';
import VideoPlayersDetails from '../../../../components/videoPlayersDetails';
import SidePanelLists from '../../../../components/sidePanelLists';
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";
import TopicsYouAreInterested from "../../dashboard/components/TopicsYouAreInterested";
import LikedVideos from "../../dashboard/components/LikedVideos";
import {useLocation, useNavigate} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import "../css/accordion.scss";
import AccordionCourse from '../../../../components/accordionCourse';
import CourseIntroDetails from '../../../../components/courseIntroPlayer/courseIntroDetails';
import CourseIntroPlayer from '../../../../components/courseIntroPlayer';
import VideoPlayer from '../../../../components/videoplayer';

import { useSelector } from 'react-redux';
import { GURU } from '../../../../constants/app.constants';
import Modal from '../../../../components/modal';
import Section from '../../../../components/section';
import AvaliableMeeting from '../../../../components/avaliableMeeting/avaliableMeeting';
import MyMeetingSlots from '../../../../components/myMeetingSlots/myMeetingSlots';

const CourseDetailsView = (props) => {
    const {isSideOpen, onOpenIsSide, videoDetails, getVideoDocId, docId, watchNextPremium, isMyLearningPage} = props;

    const avatarImage = videoDetails?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + videoDetails?.uploaded_by_pic : AvatarPic

    const closeVideo = () => {
        setIsVideoModal(false);
    };

    const [isVideoModal, setIsVideoModal] = useState(false);
    const [vidoePlayUrl, setVidoePlayUrl] = useState("");
    
    const breadcrumbList = [
        {
            text: 'Main page',
            link: '/trainee/dashboard',
        },
        {
            text: videoDetails?.videocategoryname,
            link: isMyLearningPage ? '/trainee/my-learning/video-details/' + videoDetails?.videoid : '/trainee/video-details/' + videoDetails?.videoid,
        },
    ]

    const playVideo = (val) => {
        console.log(val);
        setVidoePlayUrl(process.env.REACT_APP_IMG_URL + val);
        setIsVideoModal(true);
    };

    const userInfo = useSelector(state => state?.app?.userInfo);
console.log("props?.serviceData?.months",props?.serviceData?.months);
    return (
        <>
            {isMyLearningPage && (
                <>
                <Breadcrumbs
                    breadcrumbList={breadcrumbList}
                />

                <Text type="H2" text="MY LEARNING" className="mb-20"/>
                </>
            )}
            <div className="video-details-main-wrapper">
                
                <div className={`video-details-main-right ${isSideOpen ? 'video-details-main-right-active' : ''}`}>
                    <div className="vpd-watch-video">
                        <CourseIntroPlayer
                          videofilename={props?.serviceData?.courseDetails?.introvideo}
                          videofiletype="video/mp4"
                         />
                    </div>
                   {/*<CourseIntroDetails
                          category={`${videoDetails?.videolevel}  . ${videoDetails?.videocategoryname}`}
                          subcategory={videoDetails?.subCatName}
                          title={videoDetails?.title}
                          views={videoDetails?.ViewsCount}
                          time={videoDetails?.dot}
                          date={videoDetails?.dot}
                          likes={videoDetails?.likescount}
                          description={videoDetails?.des}
                          channelName={videoDetails?.uploaded_by_name}
                          favouritedVideo={videoDetails?.video_favourite_count}
                          uploadedByName={avatarImage}
                          avatarImage={avatarImage}
                          onClickFavoriteVideo={props.favoriteVideo}
                          onClickFavoriteGuru={() => {
                              props.favoriteGuru(videoDetails?.video_posted_by_id)
                          }}
                          onClickLikeVideo={props.likeVideo}
                          isGuruFavourite={videoDetails?.is_guru_favourite}
                          isFavourite={videoDetails?.is_favourite}
                          isLiked={videoDetails?.is_liked}
                          favouriteVideoCount={videoDetails?.guru_favourite_count}
                          guruFavouritedCount={videoDetails?.guruFavouritedCount}
                          videoLikesCount={props.videoLikesCount}
                          {...props}
                        />*/}

                        {props?.serviceData?.months.length >= 1 &&(
                                        <>
                                        <Section className="bg-white">
                                        
                                                
                                                <AvaliableMeeting serviceData={props?.serviceData} setChangesDone={props.setChangesDone} type={"course"}/>
                                        </Section>
                                        </>
                            )
                        }

                    
                     <MyMeetingSlots courseId={props.courseId}/>

                     <AccordionCourse {...props} moudleDetails={props?.serviceData?.moduleDetails} topicsDetails={props?.serviceData?.topicsDetails}  playVideo={playVideo}  />

                     {userInfo?.usertypeid != GURU &&(
                        
                        <TopicsYouAreInterested {...props?.text}/>
                     
                     )}

                     
{isVideoModal &&
<>
<Modal
                    className=""
                    canClose="onClose"
                    onClose={closeVideo}
                    onOuterClose={closeVideo}
                    modalContent={
                    
                        <VideoPlayer
                            videourl={vidoePlayUrl}
                            width="100%"
                            height="460"
                        />

                    }
                />



</>
}           


                    {/*isVideoModal &&

                            <Modal
                                title=""
                                centered
                                open={isVideoModal}
                                onOk={() => setIsVideoModal(false)}
                                onCancel={() =>     (false)}
                                zIndex={10000}
                                width={window.innerWidth - window.innerWidth/5}
                                cancelButtonProps={{ style: { display: 'none' } }}
                                okButtonProps={{ style: { display: 'none' } }}
                            >

                                    <VideoPlayer
                                        videourl={vidoePlayUrl}
                                        width="100%"
                                        height="460"
                                    />

                            </Modal>

                     */}

                </div>
            </div>
        </>
    );
};

export default CourseDetailsView;

