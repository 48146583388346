import React, {useEffect} from 'react';
import Button from "../button";
import {useLocation, useNavigate} from "react-router-dom";
import useHttpHandler from "../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import useLogin from "../../pages/authentication/login/hooks/login.hook";

const GoBackButton = props => {
    const {setUserSessionInfo} = useLogin()
    const {post} = useHttpHandler()
    const navigate = useNavigate()
    const goBack = () => {
        const isConversionFlow = sessionStorage.getItem('isConvertUserFlow')
        /*if (isConversionFlow) {
            post('swapUser').then((res) => {
                const status = res.data.status
                const data = res.data.data
                if (status.result === '200') {
                    setUserSessionInfo(data).then(()=>{
                        sessionStorage.removeItem('isConvertUserFlow')
                        navigate(isConversionFlow)
                    })
                } else {
                    toast.error(status.msg)
                }
            })
        } else {
            navigateBack()
        }*/
        navigateBack()
    }
    const navigateBack = () => {
        navigate(-1)
    }


    const goBackPayment =()=>{
        navigate('/trainee/categeory-payment')
    }

    return (
        <Button type="button" light large outline text={props.text} onClick={goBackPayment} className="me-32"/>
    );
};
export default GoBackButton;
