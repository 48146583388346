import React from "react";
import Button from "../button";
import Flex from "../flex";
import Image from "../image";
import Listen from "../listen";
import PlayIcon from "../svg/playIcon";
import BinIcon from "../svg/binIcon";
import Text from "../text";
import RecentImage from "../../assets/images/video-details-img.jpg";
import copyImg from "../../assets/img/copyIcon.png";
import shareImg from "../../assets/img/shareIcon.png";
import viewImg from "../../assets/img/viewicon.png";

import Icon from "../icon";
import { useNavigate } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { useSelector } from "react-redux";
import useHelper from "../../hooks/helpers.hook";
import Avatar from "../avatar";

const LearnerCourseMainbar = (props) => {
  console.log("props", props);
  const index = props?.index;
  const navigate = useNavigate();
  var divclass = "mt-2 ";

  if (props?.index > 0) {
    divclass = "";
  }
  const userInfo = useSelector((state) => state.app.userInfo);
  const {getShortDescription_dot} = useHelper();
  const onCopy = () => {
    alert("Copied Course");
  };
  return (
    <>
  
      
          <div className="dash-recent-upload-card-white shadow">
            <div className="row">
                <div className="col-md-12 d-flex align-items-center border-bottom">
                    <div className="bg-opacity-10 d-flex pl-1">
                        <div className="">    
                        {/*
                        <Avatar
                            src={ process.env.REACT_APP_IMG_URL + props?.data?.catPic}
                            alt={props?.data?.catName}
                        />
                        */} 

                        <span className="fs-4"> {props?.data?.catName}</span>                   
                      
                        </div>
                        <div className="d-flex align-items-center mx-2">
                        <Avatar
                            src={ process.env.REACT_APP_IMG_URL + props?.data?.instructor_pic}
                            alt={props?.data?.instructor_name}
                        /> 
                       
                            &nbsp; By  {props?.data?.instructor_name} &nbsp; on <em> &nbsp; Feb 10, 2024 </em> </div>
                    </div>
                </div>
            </div>
            <div className="row mt-3">



              <div className="col-12 col-md-4">
                
                <div className="video-card">
                  <div className="video-poster">
                    <img
                      //src={process.env.REACT_APP_IMG_URL + dashboardDetails?.videoThumbnail }
                      src={
                        process.env.REACT_APP_IMG_URL + props?.data?.thumbnail
                      }
                      alt={props?.data?.title}
                      className="video_card ml-0 img-fluid"
                    />
                    <div className="video-play">
                      <Button
                        link
                        text={<Icon icon={<PlayIcon />} />}
                        onClick={() => {
                          props?.playVideo(props?.data?.videofilename);
                        }}
                        type="button"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-8">
                <div className="courses-right h-100">
                <div className="row">
                  <div className="col-md-12">
                    <Text type="H5" text={props?.data?.title} />
                    <Text
                      type="PARAGRAPH"
                      className="text-dark"
                      
                      text={getShortDescription_dot(props.data?.des,300)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                  <div className="top-main-boxes-primary">
                  <div className="p-2">
                    <div className="d-flex justify-content-between">
                      <div className="text-color-white d-flex justify-content-between ">
                        <div className=" d-flex justify-content-between pr-2">
                          <Text
                            type="p"
                            text={"+" + props?.data.views}
                            className={"m-0 fw-bold pr-2"}
                          />
                          <Text
                            type="p"
                            className="text-color-white pr-2"
                            text="Views"
                          />
                        </div>

                        <div className="d-flex justify-content-between">
                          <Text
                            type="p"
                            text={"+" + props?.data.likes}
                            className={"m-0 fw-bold pr-2"}
                          />

                          <Text
                            type="p"
                            className="text-color-white pr-2"
                            text="Likes"
                          />
                        </div>
                      </div>
                      <div className="">
                        <div className="text-center d-flex justify-content-between">
                          <Text
                            type="p"
                            className="text-color-white p-0"
                            text="Copy URL"
                          />
                          <CopyToClipboard
                            onCopy={onCopy}
                            
                            text={
                              URL +
                              "/course/" +
                              props?.data.courseid +
                              "-0-" +
                              userInfo?.myrefcode
                            }
                          >
                            <Button
                              toolTip="Copy Course"
                              link
                              className={"pull-left px-2 pr-3"}
                              text={
                                <img
                                  src={copyImg}
                                  alt="copy"
                                  className="dashboard-share-icon"
                                />
                              }
                              type="button"
                            />
                          </CopyToClipboard>

                          <Text
                            type="p"
                            className="text-color-white pr-2"
                            text="Share Your Course "
                          />
                          <Button
                            link
                            toolTip="Share Course"
                            className={"pull-right"}
                            text={
                              <img
                                src={shareImg}
                                alt="copy"
                                className="dashboard-share-icon "
                              />
                            }
                            onClick={() => {
                              props.setSelectedCourseId(props?.data.courseid);

                              console.log("111");
                              props.setIsShareModal(true);
                            }}
                            style={{ marginLeft: "0px" }}
                            type="button"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="input-share-height">
                      <Text
                       
                        type="PARAGRAPH"
                        text={
                            process.env.REACT_APP_URL +
                          "/course/" +
                          props?.data.courseid +
                          "-0-" +
                          userInfo?.myrefcode
                        }
                        className="text-color-white"
                      />
                    </div>
                  </div>
                </div>
            </div>
                  <div className="col-md-2">
                    <div className="course-status-box-primary pt-2 text-center h-100 ">

                    <Button
                      link
                      text={
                        <div>
                            <img src={viewImg} alt="copy" className="icon-32" />

                            <Text
                            type="PARAGRAPH"
                            className="pt-2 text-primary module-button-title-txt "
                            text="View Course"
                            />
                        </div>
                      }
                      style={{ margin: "0px", padding: "1px" }}
                      onClick={() =>
                        navigate(
                          "/trainee/course-details/" + props?.data.courseid
                        )
                      }
                      type="button"
                    />
                     
                    </div>
              </div>
                </div>
                </div>

              </div>
            </div>
          </div>
     
   
    </>
  );
};

export default LearnerCourseMainbar;
